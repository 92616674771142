import React, { Component } from "react";
import MetaTags from 'react-meta-tags'; 
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import queryString from 'query-string';
import {addParamUrl} from '../../../utils';

import {commentInvite} from "../../../actions/action_comment";
import { withTranslation } from 'react-i18next';

class RegisterFinish extends Component { 
	constructor(props) { 
	  	super(props); 
	  	this.state = {isSite: ''};
		this.gotoMypage = this.gotoMypage.bind(this);
	}

	componentDidMount() {
		let url = window.location.search;
		let params = queryString.parse(url);
		var isSite = params.isSite;

		if (isSite) {
				this.setState({
					isSite: isSite
				});
			}
		}

	gotoMypage() {
		let link = '/mypage' + this.state.isSite ? '?isSite=' + this.state.isSite : '';	
		window.history.pushState(null, null, link);
		return <Redirect to={link} />
  	}

    render() { 
		document.body.className = "auth signup";
		var isSiteParam = this.state.isSite ? {name: 'isSite', value: this.state.isSite} : {};

    	var userInfo = this.props.userInfo;
        var link = addParamUrl("/login", [isSiteParam]);
        let isLoggedIn = false; 
		if (userInfo) {
            if (userInfo.userId) {
                isLoggedIn = true;
            } 
    		if (userInfo.isLogined === false || userInfo.isGuest) { 
    			window.history.pushState(null, null, addParamUrl('/login', [isSiteParam]));
	            return <Redirect to={link} />
	        } 
    	}else{
    		window.history.pushState(null, null, addParamUrl('/login', [isSiteParam]));
    		return <Redirect to={link} />
        }
        return (
        	<div>
				<MetaTags>
					<title>登録完了｜飲食店のメニューを贈れる「ごちめし」</title>
					<meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
					<meta name="description" content="登録完了ページです。「ごちめし」は飲食店のメニューをプレゼントできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
					<meta name="twitter:card swiper-slide" content="summary_large_image" />
					<meta name="twitter:title" content="登録完了｜飲食店のメニューを贈れる「ごちめし」" />
					<meta name="twitter:description" content="登録完了ページです。「ごちめし」は飲食店のメニューをプレゼントできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
					<meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
					<meta name="twitter:site" content="@gochi_meshi" />
					<meta name="twitter:creator" content="@gochi_meshi" />
					<meta name="twitter:image:width" content="300" />
					<meta name="twitter:image:height" content="300" />
					<meta property="og:site_name" content="登録完了｜飲食店のメニューを贈れる「ごちめし」" />
					<meta property="og:title" content="登録完了｜飲食店のメニューを贈れる「ごちめし」" />
					<meta property="og:type" content="article" />
					<meta property="og:url" content={window.location.href} />
					<meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
					<meta property="og:description" content="登録完了ページです。「ごちめし」は飲食店のメニューをプレゼントできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
				</MetaTags>
				<header className="global-login-header-new forgot-password-header">
					<div className="logo-nav">
						<h1 className="logo">
							<a href="/">
								<div className="new-logo-login">
									<source
										media="(max-width: 180px)"
										srcSet="/assets/img/common/logo_gochi_header_mypage.svg"
									/>
									<img
										src="/assets/img/common/logo_gochi_header_mypage.svg "
										alt="気持ちを「ごちそう」にして贈れるアプリ ごちめし"
										loading="lazy"
									/>
								</div>
								<span className="title-page">GOCHIアカウントを新規登録</span>
							</a>
						</h1>
					</div>
				</header>
				<main>
					<div className="container">
						<div className="heading-lg">
							<div className="title">
								新規アカウント登録
							</div>
						</div>

						{process.env.REACT_APP_MFA !== 'off' && (
							<nav className="step-group signup-step-group">
								<div className="step is-success">
									<div className="step-btn">
										<div className="step-outer-circle">
											<div className="step-inner-circle"></div>
										</div>
									</div>
								</div>
								<div className="step  is-success">
									<div className="step-btn">
										<div className="step-outer-circle">
											<div className="step-inner-circle"></div>
										</div>
									</div>
								</div>
								<div className="step is-success">
									<div className="step-btn">
										<div className="step-outer-circle">
											<div className="step-inner-circle"></div>
										</div>
									</div>
								</div>
								<div className="step is-success">
									<div className="step-btn">
										<div className="step-outer-circle">
											<div className="step-inner-circle"></div>
										</div>
									</div>
								</div>
							</nav>
						)}

						<div className="signup-input">
							<div className="signup-input-group">
								<div className="signup-input-header">
									<div className="heading-md">
										<div className="title">アカウント登録完了</div>
									</div>
								</div>
								<div className="signup-input-body">
									<div className="complete-text text-bold">
										アカウントの登録が完了しました！
									</div>
									<div className="text">
										マイページにログインしてプロフィールを作成しましょう。
									</div>
									<figure className="illustration illustration-completed">
										<svg className="bg">
											<use xlinkHref="/assets/img/common/icon.svg#bg-done"></use>
										</svg>
										<svg className="img" width="49.071" height="52.325" viewBox="0 0 49.071 52.325">
											<rect id="長方形_3481" data-name="長方形 3481" width="48.641" height="38.449" transform="translate(0 13.878) rotate(-0.645)" fill="#35a99e"></rect>
											<rect id="長方形_3482" data-name="長方形 3482" width="5.094" height="38.449" transform="translate(21.772 13.633) rotate(-0.647)" fill="#f7db41"></rect>
											<path id="パス_3554" data-name="パス 3554" d="M1143.122,198l-11.989-4.707,5.587-7.65Z" transform="translate(-1119.595 -185.491)" fill="#f7db41"></path>
											<path id="パス_3555" data-name="パス 3555" d="M1140.577,198.029l11.885-4.97-5.76-7.521Z" transform="translate(-1115.492 -185.538)" fill="#f7db41"></path>
										</svg>
									</figure>
								</div>
								<div className="btn-group complete-btn-group">
									<a className="btn btn-rect btn-outline btn-fluid" href={addParamUrl(`/mypage`, [isSiteParam])}>マイページに移動する</a>
								</div>
							</div>
						</div>
					</div>
				</main>

				<footer className="global-footer footer-mypage new-footer-login">
					<section className="global-footer-nav-area">
						<div className="container">
							<div className="section-header">
								<div className="heading-xs">
									<div className="title">
										<h1>
											<img
												src="/assets/img/common/logo_gochi_footer.svg"
												alt="ごちめし"
												loading="lazy"
											/>
										</h1>
									</div>
								</div>
							</div>
							<div className="section-body footer-nav">
								<ul className="footer-link-area">
									<li>
										<a href="https://gochimeshi.com/policy/">各種規約</a>
									</li>
									<li>
										<a href="https://gochimeshi.com/policy/privacy/">
											プライバシーポリシー
										</a>
									</li>
									<li>
										<a href="https://gochimeshi.com/policy/legal/">
											特定商取引法に基づく表記
										</a>
									</li>
									<li>
										<a href="https://user-guide.gochimeshi.com/">よくある質問</a>
									</li>
									<li>
										<a href="https://gochimeshi.com/info/">お問い合わせ</a>
									</li>
									<li>
										<a href="https://www.gigi.tokyo/">運営会社（Gigi株式会社）</a>
									</li>
								</ul>
							</div>
						</div>
					</section>

					<div className="copyright">
						<small>ごちめし®︎ さきめし®︎ びずめし®︎ GOCHI®︎は、Gigi株式会社の登録商標です<br />©2020 Gigi Inc.</small>
					</div>
				</footer>
			</div>
        ); 
    }
} 

const mapStateToProps = state => ({
	...state
}); 
 
export default connect(mapStateToProps, {commentInvite})(withTranslation()(RegisterFinish)); 
