import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import {connect} from "react-redux";
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import 'firebase/auth'; 
import AuthService from "../../../api/services/AuthService";
import UserService from "../../../api/services/UserService";
import {showError} from "../../../actions/action_error_alert";
import {saveUserInfo} from "../../../actions/action_users"; 
import {showLoading} from "../../../actions/action_loading"; 
import queryString from 'query-string';
import {addParamUrl} from '../../../utils';

//Login by cognito
import { Auth } from 'aws-amplify';

// import Header from "../../layout/header/Header";

// Amplify.configure({
//     Auth: {
//     	identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
//     	region: process.env.REACT_APP_REGION,
//     	userPoolId: process.env.REACT_APP_USER_POOL_ID,
//         userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
// 	}
// });

class RegisterConfirmSMS extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectTo: false,
			errorMessage: null,
      menuId: null,
      confirmCodeSMS: '',
      phoneNo: '',
      countryCode: process.env.REACT_APP_MODE_DEVELOP === "true" ? '+84' : '+81',
      src: '',
      from: '',
      isSite: ''
		};
		this.onConfirmCodeSMSChange = this.onConfirmCodeSMSChange.bind(this); 
		this.submitConfirm = this.submitConfirm.bind(this);  
		this.onPhoneNoChange = this.onPhoneNoChange.bind(this);  
		this.sendSMSCode = this.sendSMSCode.bind(this);  
  }  

	onConfirmCodeSMSChange(e) {
		this.setState({confirmCodeSMS: e.target.value});
  }

	onPhoneNoChange(e) {
		this.setState({phoneNo: e.target.value});
  } 
  
  async sendSMSCode(e) {
    e.preventDefault();
    var pattern = new RegExp(/[^0-9]+/);
    var isPhoneNumberValid = !pattern.test(this.state.phoneNo);

    if (isPhoneNumberValid) {
      this.props.showLoading(true);
      try {
        const international =
          this.state.countryCode === ""
            ? this.state.phoneNo.trim().replace(/[^+0-9]/g, "")
            : this.state.countryCode +
            this.state.phoneNo
                .trim()
                .replace(/\D/g, "")
                .substr(1);
        const user = await Auth.currentAuthenticatedUser();
        await Auth.setPreferredMFA(user, "NOMFA");
        await Auth.updateUserAttributes(user, {
          phone_number: international,
        });
        await Auth.verifyUserAttribute(user, "phone_number"); 
        this.props.showError(true, this.props.t('Send authen code success'), '');
      } catch (error) {
        console.log(error); 
        this.props.showError(true, this.props.t('pages.register.Invalid phone number format'), '');
      } 
      this.props.showLoading(false);
    } else {
      alert("電話番号の形式が正しくありません");
    }
    
    
  }

	async submitConfirm(e){
		e.preventDefault();
		this.props.showLoading(true);
		try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.verifyUserAttributeSubmit(user, "phone_number", this.state.confirmCodeSMS);
      await Auth.setPreferredMFA(user, "SMS");

      const resAuth = await AuthService.authenticateByCognitoToken(user.signInUserSession.idToken.jwtToken);
      if (resAuth.status !== 200) {
        // eslint-disable-next-line no-throw-literal
        throw "API error";
      }

      const { data } = await UserService.getUserList({
        cognitoUserId: user.username,
        sort: "createdAtDesc",
        page: 1,
        limit: 1,
      });

      this.props.saveUserInfo(true, false, data.list[0]); 

      var userInfo = {
        isLogined: true,
        isGuest: false,
        userId: data.list[0].userId,
        accountId: data.list[0].accountId,
        displayName: data.list[0].displayName,
        email: data.list[0].email,
        userImage: data.list[0].userImage && data.list[0].userImage.length > 0 && data.list[0].userImage[0].image
      };

			localStorage.setItem('userInfo', JSON.stringify(userInfo));
      this.setState({
        redirectTo: true
      });
    } catch (error) {
      console.log(error);
      if (error.code === "CodeMismatchException") { 
        this.props.showError(true, this.props.t('pages.register.Invalid verification code provided'), '');
      } else if (error.code === "ExpiredCodeException") { 
        this.props.showError(true, this.props.t('pages.register.Authorization code has expired'), '');
      } else { 
        this.props.showError(true, this.props.t('pages.register.An error has occurred'), '');
      }
    } 
		this.props.showLoading(false);
	}

	UNSAFE_componentWillMount() {
		sessionStorage.removeItem("fullInviteContent");
		sessionStorage.removeItem("selectFriend");
		sessionStorage.removeItem("inviteContent");
		sessionStorage.removeItem("paymentMethod");
		sessionStorage.removeItem("menuInfo");
		sessionStorage.removeItem("comment");
		sessionStorage.removeItem("guestInfo");
		sessionStorage.removeItem("messageShop");
		var userInfo = this.props.userInfo;
		if (userInfo) {
			if(userInfo.isGuest === true) {
				localStorage.removeItem("userInfo");
				this.props.saveUserInfo(false, userInfo.isGuest, []);
			}
		}
	}

	componentDidMount() {
		let url = window.location.search;
		let params = queryString.parse(url);
    var menuId = params.id;
    var src = params.src;
    var from = params.from;
    var isSite = params.isSite;

		if (src) {
			this.setState({
				src: src
			});
		}

    if (isSite) {
			this.setState({
				isSite: isSite
			});
		}

    if (from) {
			this.setState({
				from: from
			});
		}

		this.setState({
			menuId: menuId
		});
	}

	render() {
    const { t } = this.props;
    document.body.className = "auth signup";

    var isSiteParam = this.state.isSite ? {name: 'isSite', value: this.state.isSite} : {};

		const redirectTo = this.state.redirectTo;
    if (redirectTo === true) { 
      let link = addParamUrl('/signup/complete', [isSiteParam]);
      const urlParams = [];
      if (this.state.menuId) {
        link = '/invite';
        urlParams.push('id=' + this.state.menuId);
        if (this.state.src) {
          urlParams.push('src=' + this.state.src);
        }
        link += '?' + urlParams.join('&');
      }
      window.history.pushState(null, null, addParamUrl('/signup/confirm-sms' + (urlParams.length ? '?' + urlParams.join('&') : ''), [isSiteParam]));
      return <Redirect to={link} />
    } 

      return (
        <div>
          <MetaTags>
            <title>新規登録｜飲食店のメニューを贈れる「ごちめし」</title>
            <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
            <meta name="description" content="新規登録ページです。「ごちめし」は飲食店のメニューをプレゼントできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
            <meta name="twitter:card swiper-slide" content="summary_large_image" />
            <meta name="twitter:title" content="新規登録｜飲食店のメニューを贈れる「ごちめし」" />
            <meta name="twitter:description" content="新規登録ページです。「ごちめし」は飲食店のメニューをプレゼントできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
            <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
            <meta name="twitter:site" content="@gochi_meshi" />
            <meta name="twitter:creator" content="@gochi_meshi" />
            <meta name="twitter:image:width" content="300" />
            <meta name="twitter:image:height" content="300" />
            <meta property="og:site_name" content="新規登録｜飲食店のメニューを贈れる「ごちめし」" />
            <meta property="og:title" content="新規登録｜飲食店のメニューを贈れる「ごちめし」" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
            <meta property="og:description" content="新規登録ページです。「ごちめし」は飲食店のメニューをプレゼントできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
          </MetaTags>
          <header className="global-login-header-new forgot-password-header">
            <div className="logo-nav">
              <h1 className="logo">
                <a href="/">
                  <div className="new-logo-login">
                    <source
                      media="(max-width: 180px)"
                      srcSet="/assets/img/common/logo_gochi_header_mypage.svg"
                    />
                    <img
                      src="/assets/img/common/logo_gochi_header_mypage.svg "
                      alt="気持ちを「ごちそう」にして贈れるアプリ ごちめし"
                      loading="lazy"
                    />
                  </div>
                  <span className="title-page">GOCHIアカウントを新規登録</span>
                </a>
              </h1>
            </div>
          </header>

          <main>
              <div className="container">
                  <div className="heading-lg">
                      <div className="title">
                          新規アカウント登録
                      </div>
                  </div>

                  {process.env.REACT_APP_MFA !== 'off' && (
                    <nav className="step-group signup-step-group">
                        <div className="step is-success">
                            <div className="step-btn">
                                <div className="step-outer-circle">
                                    <div className="step-inner-circle"></div>
                                </div>
                            </div>
                        </div>
                        <div className="step  is-success">
                            <div className="step-btn">
                                <div className="step-outer-circle">
                                    <div className="step-inner-circle"></div>
                                </div>
                            </div>
                        </div>
                        <div className="step is-success">
                            <div className="step-btn">
                                <div className="step-outer-circle">
                                    <div className="step-inner-circle"></div>
                                </div>
                            </div>
                        </div>
                        <div className="step is-disabled">
                            <div className="step-btn">
                                <div className="step-outer-circle">
                                    <div className="step-inner-circle"></div>
                                </div>
                            </div>
                        </div>
                    </nav>
                  )}

                  <div className="signup-input">
                      <div className="signup-input-group">
                          <div className="signup-input-header">
                              <div className="heading-md">
                                  <div className="title">二段階認証</div>
                              </div>
                          </div>
                          <div className="signup-input-body">
                              {this.state.confirmCodeSMSError &&
                                <div className="text-danger fs-14 mb-2 fs-12">{this.state.confirmCodeSMSError}</div>
                              }
                              {this.state.from === 'login' && (
                                <div className="box box-sm text-red bg-red-light-2 text-red">
                                    アカウント登録が完了していないメールアドレスがあります。認証コードを入力してください。
                                </div>
                              )}
                              <div className="text">
                                  お使いの携帯電話端末にSMS（ショートメッセージサービス）を利用して認証コードを発行します。<br />
                                  発行された「SMS認証コード」を入力して端末認証をしてください。
                              </div>
                              <div className="fieldset">
                                  <div className="fieldset-header">
                                      <div className="title">電話番号</div>
                                  </div>
                                  <div className="fieldset-body">
                                      <div className="field-group field-group-column">
                                          <div className="field field-fluid">
                                                <input className="form-control"
                                                  placeholder="09012345678"
                                                  type="tel"
                                                  onChange={this.onPhoneNoChange}
                                                  value={this.state.phoneNo}
                                                  name="phoneNo"
                                                />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="btn-group signup-submit-sms-btn-group">
                                  <button className="btn btn-rect btn-fluid signup-submit-sms-btn" onClick={this.sendSMSCode}><div>認証コード送信</div></button>
                              </div>
                          </div>
                          <div className="signup-input-footer">
                              <form className="mt-50">
                                      <div className="fieldset field-column">
                                          <div className="fieldset-header">
                                              <div className="title">認証コードの入力</div>
                                          </div>
                                          <div className="fieldset-body">
                                              <div className="field-group field-group-column">
                                                  <div className="field field-fluid">
                                                      <input className="form-control"
                                                        placeholder="認証コードを入力"
                                                        type="text"
                                                        onChange={this.onConfirmCodeSMSChange}
                                                        value={this.state.confirmCodeSMS}
                                                        name="confirmCodeSMS"
                                                      />
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  <div className="btn-group signup-input-btn-group">
                                      <button className="btn btn-rect signup-input-btn button-reset-pass" onClick={this.submitConfirm}><div>次へ</div></button>
                                  </div>
                                  {this.state.errorMessage &&
                                    <div className="text-danger mb-2 fs-14 font-weight-bold">{t('pages.register.'+this.state.errorMessage)}</div>
                                  }
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </main>

          <footer className="global-footer footer-mypage new-footer-login">
            <section className="global-footer-nav-area">
              <div className="container">
                <div className="section-header">
                  <div className="heading-xs">
                    <div className="title">
                      <h1>
                        <img
                          src="/assets/img/common/logo_gochi_footer.svg"
                          alt="ごちめし"
                          loading="lazy"
                        />
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="section-body footer-nav">
                  <ul className="footer-link-area">
                    <li>
                      <a href="https://gochimeshi.com/policy/">各種規約</a>
                    </li>
                    <li>
                      <a href="https://gochimeshi.com/policy/privacy/">
                        プライバシーポリシー
                      </a>
                    </li>
                    <li>
                      <a href="https://gochimeshi.com/policy/legal/">
                        特定商取引法に基づく表記
                      </a>
                    </li>
                    <li>
                      <a href="https://user-guide.gochimeshi.com/">よくある質問</a>
                    </li>
                    <li>
                      <a href="https://gochimeshi.com/info/">お問い合わせ</a>
                    </li>
                    <li>
                      <a href="https://www.gigi.tokyo/">運営会社（Gigi株式会社）</a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

              <div className="copyright">
                  <small>ごちめし®︎ さきめし®︎ びずめし®︎ GOCHI®︎は、Gigi株式会社の登録商標です<br />©2020 Gigi Inc.</small>
              </div>
          </footer>
        </div>
      );
    }
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({ 
  showError: (showing, title, content) =>dispatch(showError(showing, title, content)),
	saveUserInfo: (isLogined, isGuest, userInfo) => dispatch(saveUserInfo(isLogined, isGuest, userInfo)), 
  showLoading: (showing) => dispatch(showLoading(showing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RegisterConfirmSMS));
