import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import queryString from 'query-string';
import {
  toggleFavoriteShop,
  toggleWishListShop,
  useRecentlyViewedMenus,
  useScript,
} from "../../../../utils";
import GiftService from "../../../../api/services/GiftService";
import { useHistory } from "react-router-dom";
import { ListMenus } from "../../common/ListMenus";
import { useDispatch, useSelector } from "react-redux";
import { showNotLoggedModal } from "../../../../actions/action_show_not_logged_modal";
import { Swiper, SwiperSlide } from "swiper/react";
import { swiperConfigs } from "../../common/SwiperConfigs";
import { SliderPagination } from "../../common/SliderPagination";
import { showWishListModal } from "../../../../actions/action_show_wish_list_modal";
import { ShopDetailsGochiKid } from "../../common/ShopDetailsGochiKid";
import LoadingOverlay from "../../common/LoadingOverlay";
import ConfirmModal from "../../common/ConfirmModal";

const SHOP_NO_IMAGE = require("../../../../assets/img/icons/shop01_blank.png");

export function GochiKidSelectMenu(props) {
  const history = useHistory();
  const shopId = history.location.pathname.split('/').length > 0 ? history.location.pathname.split('/')[4] : ''; 
  const [shopDetails, setShopDetails] = useState({});
  const [listShopSameCity, setListShopSameCity] = useState([]);
  const [listMenuSameCity, setListMenuSameCity] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.userInfo);
  const userInfo = queryString.parse(window.location.search).accessToken ? {isGuest: false, isLogined: true, userId: JSON.parse(atob(queryString.parse(window.location.search).accessToken.split(".")[1])).uid} : user;
  const isLoggedIn = userInfo && userInfo.userId;
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const dispatch = useDispatch();
  const [tempFavorite, setTempFavorite] = useState(false);
  const [tempWishList, setTempWishList] = useState(false);
  const [recentlyViewedMenus] = useRecentlyViewedMenus(null, null, null, props.accessToken, props.checkTokenExpired, 1);
  const [errorMessage, setErrorMessage] = useState("");
  const [openErrorPopup, setOpenErrorPopup] = useState(false);

  useEffect(() => {
    document.body.className = "shopdetails bgwhite";
    window.scrollTo(0, 0);
    getShopDetailData();
    if (props.accessToken) {
      sessionStorage.clear();
      localStorage.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId]);

  function getTitle() {
    return (shopDetails.shopName || "") + "｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス";
  }

  function getMetaDescription() {
    return `${shopDetails.shopName}のページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。`;
  }

  function noIndex() {
    return (shopDetails.status !== 'AVAILABLE' && shopDetails.status !== undefined ? true : false);
  }

  function getListShopSameCity(city, prefectures) {
    GiftService.getShops({
      page: 1,
      perPage: 25,
      city,
      excludeShopId: shopId,
      flowGochiKidFlag: 1
    })
      .then((response) => {
        if (response.status === 200) {
          setListShopSameCity(response.data.list);
        } else if (response.status === 404) {
          GiftService.getShops({
            page: 1,
            perPage: 25,
            prefectures,
            excludeShopId: shopId,
            flowGochiKidFlag: 1
          }).then((response) => {
            if (response.status === 200) {
              setListShopSameCity(response.data.list);
            } else if (response.status === 404) {
              // No shop
            }
          });
        }
      })
      .catch((e) => {
        console.error("GET LIST SHOPS ERROR: ", e);
      });
  }

  function getListMenuSameCity(city, prefectures) {
    GiftService.getMenus({
      page: 1,
      perPage: 25,
      city,
      orderBy: 1,
      excludeShopId: shopId,
      flowGochiKidFlag: 1
    })
      .then((response) => {
        if (response.status === 200) {
          setListMenuSameCity(response.data.list);
        } else if (response.status === 404) {
          GiftService.getMenus({
            page: 1,
            perPage: 25,
            prefectures,
            excludeShopId: shopId,
            orderBy: 1,
            flowGochiKidFlag: 1
          }).then((response) => {
            if (response.status === 200) {
              setListMenuSameCity(response.data.list);
            } else if (response.status === 404) {
              // No menu
            }
          });
        }
      })
      .catch((e) => {
        console.error("GET LIST SHOPS ERROR: ", e);
      });
  }

  async function getShopDetailData() {
    setLoading(true);
    let userIdForGochiKid = null;
    if (props.accessCode) {
      userIdForGochiKid = Number(atob(props.accessCode).slice(0, -2));
    } else {
      const user = JSON.parse(localStorage.getItem('userInfo'));
      if (user) {
        userIdForGochiKid = user.userId;
      }
    }
    const response = await props.getShopDetails(shopId, userIdForGochiKid, props.gochiKidGroupId);
    setLoading(false);
    if (response.status === 200) {
      setShopDetails(response.data);

      // set current status isFavourite and isWishList
      if (response.data && response.data.isFavourite) {
        setTempFavorite(true);
      }
      if (response.data && response.data.isWishList) {
        setTempWishList(true);
      }

      const city = response.data.city;
      const prefecture = response.data.prefectures;
      //getListMenuSameCity(city, prefecture);
      getListShopSameCity(city, prefecture);
    } else if (response.status === 404 || (response.status === 409 && response.data.code === 'gochi.kid.cant.use.menu')) {
      history.push("/404");
    } else if (response.status === 409 && response.data.code === 'gochi.kid.only.poor.use') {
      setOpenErrorPopup(true);
      setErrorMessage("こちらの店舗は特定の方のみ対象となっているため、ご利用いただけません。");
    }
  }

  function handleClosePopupAndRedirect() {
    window.location.href = props.gochiKidGroupId ? `/mypage/kodomo_use/search_shop?gochiKidGroupId=${props.gochiKidGroupId}` : "/mypage/kodomo_use/search_shop";
  }

  const [expandDescription, setExpandDescription] = useState(false);

  const displayDescription =
    !expandDescription && shopDetails.introduction?.length >= 95
      ? shopDetails.introduction?.slice(0, 94) + "…"
      : shopDetails.introduction;

  function onToggleShopWishList() {
    if (!isLoggedIn) {
      dispatch(showNotLoggedModal(true));
      return;
    }
    // const current =
    //   tempWishList !== null ? tempWishList : shopDetails.isWishList;
    // console.log("TOGGLE: ", current, tempWishList, shopDetails.isWishList);
    toggleWishListShop(shopDetails.shopId, tempWishList, () => {
      setTempWishList(!tempWishList);
      if (!tempWishList) dispatch(showWishListModal(true));
    });
  }

  function onToggleShopFavorite() {
    if (!isLoggedIn) {
      dispatch(showNotLoggedModal(true));
      return;
    }
    // const current =
    //   tempFavorite !== null ? tempFavorite : shopDetails.isFavourite;
    // console.log("TOGGLE: ", current, tempFavorite, shopDetails.isFavourite);
    toggleFavoriteShop(shopDetails.shopId, tempFavorite, () => {
      setTempFavorite(!tempFavorite);
      if (!tempFavorite) dispatch(showWishListModal(true, true));
    });
  }

  let numberSlotCanUse = shopDetails?.numberSlotCanUse || 0;
  if (numberSlotCanUse === null) {
    numberSlotCanUse = 0;
  } else if (numberSlotCanUse > 100) {
    numberSlotCanUse = '100+';
  }

  return (
    <>
      <MetaTags>
        <title>{getTitle()}</title>
        {noIndex() && (
          <meta name="robots" content="noindex" />
        )}
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content={getMetaDescription()} />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content={getTitle()} />
        <meta name="twitter:description" content={getMetaDescription()} />
        <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content={getTitle()} />
        <meta property="og:title" content={getTitle()} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta property="og:description" content={getMetaDescription()} />
      </MetaTags>

      <main className="shop-detail-gochi-kid">
        <div className="container shop-info-gochi-kid">
          <header className={loading ? "loading shop-digest" : "shop-digest"}>
            <div className="title-shop">{shopDetails.shopName}のメニューを利用する</div>
            <div className="header-shop">
              <div className="shop-digest-header">
                <div className="shop-digest-header-aside">
                  <div className="action-group action-group-lg">
                    <div
                      className={
                        tempWishList
                          ? "action action-list is-active"
                          : "action action-list is-inactive"
                      }
                    >
                      <button
                        type="button"
                        aria-label="記事を保存"
                        onClick={onToggleShopWishList}
                      >
                        <svg className="icon">
                          <use xlinkHref="/assets/img/common/icon.svg#list" />
                        </svg>
                      </button>
                    </div>
                    <div
                      className={
                        tempFavorite
                          ? "action action-like is-active"
                          : "action action-like is-inactive"
                      }
                    >
                      <button
                        type="button"
                        aria-label="記事を保存"
                        onClick={onToggleShopFavorite}
                      >
                        <svg className="icon">
                          <use xlinkHref="/assets/img/common/icon.svg#heart" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="shop-digest-header-body">
                  <div className="heading-lg">
                    <h1 className="shop-name">{shopDetails.shopName}</h1>
                  </div>
                  <ul className="list-icon text-gray text-sm address-shop">
                    <li>
                      <svg className="icon">
                        <title>住所</title>
                        <use xlinkHref="/assets/img/common/icon.svg#map-marker" />
                      </svg>
                      <span className="text">
                        {`${shopDetails.prefectures || ""} ${
                          shopDetails.city || ""
                        } ${shopDetails.address || ""} ${
                          shopDetails.building || ""
                        }`}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {
              <div className="shop-digest-img">
                <div className="slider-gallery">
                  <div className="slider-gallery-main">
                    <Swiper
                      thumbs={{ swiper: thumbsSwiper }}
                      {...swiperConfigs.gallery}
                    >
                      <div className="swiper-wrapper">
                        {shopDetails.shopImage && (
                          <SwiperSlide className="swiper-slide">
                            <img
                              src={
                                shopDetails.shopImage &&
                                shopDetails.shopImage[0]
                                  ? shopDetails.shopImage[0] &&
                                    shopDetails.shopImage[0].image
                                  : SHOP_NO_IMAGE
                              }
                              loading="lazy"
                              alt=""
                            />
                          </SwiperSlide>
                        )}
                        {shopDetails.shopImage && shopDetails.shopImage[1] && (
                          <SwiperSlide className="swiper-slide">
                            <img
                              src={
                                shopDetails.shopImage &&
                                shopDetails.shopImage[1]
                                  ? shopDetails.shopImage[1] &&
                                    shopDetails.shopImage[1].image
                                  : SHOP_NO_IMAGE
                              }
                              loading="lazy"
                              alt=""
                            />
                          </SwiperSlide>
                        )}
                        {shopDetails.shopImage && shopDetails.shopImage[2] && (
                          <SwiperSlide className="swiper-slide">
                            <img
                              src={
                                shopDetails.shopImage &&
                                shopDetails.shopImage[2]
                                  ? shopDetails.shopImage[2] &&
                                    shopDetails.shopImage[2].image
                                  : SHOP_NO_IMAGE
                              }
                              loading="lazy"
                              alt=""
                            />
                          </SwiperSlide>
                        )}
                        {shopDetails.shopImage && shopDetails.shopImage[3] && (
                          <SwiperSlide className="swiper-slide">
                            <img
                              src={
                                shopDetails.shopImage &&
                                shopDetails.shopImage[3]
                                  ? shopDetails.shopImage[3] &&
                                    shopDetails.shopImage[3].image
                                  : SHOP_NO_IMAGE
                              }
                              loading="lazy"
                              alt=""
                            />
                          </SwiperSlide>
                        )}
                        {shopDetails.shopImage && shopDetails.shopImage[4] && (
                          <SwiperSlide className="swiper-slide">
                            <img
                              src={
                                shopDetails.shopImage &&
                                shopDetails.shopImage[4]
                                  ? shopDetails.shopImage[4] &&
                                    shopDetails.shopImage[4].image
                                  : SHOP_NO_IMAGE
                              }
                              loading="lazy"
                              alt=""
                            />
                          </SwiperSlide>
                        )}
                      </div>
                      <div className="swiper-pagination"></div>
                    </Swiper>
                    <SliderPagination />
                  </div>
                  <Swiper
                    className="slider-gallery-thumbnail"
                    watchSlidesVisibility
                    watchSlidesProgress
                    onSwiper={setThumbsSwiper}
                    {...swiperConfigs.thumbnails}
                  >
                    <div className="swiper-wrapper">
                      {shopDetails.shopImage && (
                        <SwiperSlide className="swiper-slide">
                          <img
                            src={
                              shopDetails.shopImage && shopDetails.shopImage[0]
                                ? shopDetails.shopImage[0] &&
                                  shopDetails.shopImage[0].image
                                : SHOP_NO_IMAGE
                            }
                            loading="lazy"
                            alt=""
                          />
                        </SwiperSlide>
                      )}
                      {shopDetails.shopImage && shopDetails.shopImage[1] && (
                        <SwiperSlide className="swiper-slide">
                          <img
                            src={
                              shopDetails.shopImage && shopDetails.shopImage[1]
                                ? shopDetails.shopImage[1] &&
                                  shopDetails.shopImage[1].image
                                : SHOP_NO_IMAGE
                            }
                            loading="lazy"
                            alt=""
                          />
                        </SwiperSlide>
                      )}
                      {shopDetails.shopImage && shopDetails.shopImage[2] && (
                        <SwiperSlide className="swiper-slide">
                          <img
                            src={
                              shopDetails.shopImage && shopDetails.shopImage[2]
                                ? shopDetails.shopImage[2] &&
                                  shopDetails.shopImage[2].image
                                : SHOP_NO_IMAGE
                            }
                            loading="lazy"
                            alt=""
                          />
                        </SwiperSlide>
                      )}
                      {shopDetails.shopImage && shopDetails.shopImage[3] && (
                        <SwiperSlide className="swiper-slide">
                          <img
                            src={
                              shopDetails.shopImage && shopDetails.shopImage[3]
                                ? shopDetails.shopImage[3] &&
                                  shopDetails.shopImage[3].image
                                : SHOP_NO_IMAGE
                            }
                            loading="lazy"
                            alt=""
                          />
                        </SwiperSlide>
                      )}
                      {shopDetails.shopImage && shopDetails.shopImage[4] && (
                        <SwiperSlide className="swiper-slide">
                          <img
                            src={
                              shopDetails.shopImage && shopDetails.shopImage[4]
                                ? shopDetails.shopImage[4] &&
                                  shopDetails.shopImage[4].image
                                : SHOP_NO_IMAGE
                            }
                            loading="lazy"
                            alt=""
                          />
                        </SwiperSlide>
                      )}
                    </div>
                  </Swiper>
                </div>
              </div>
            }
            <div className="gochi-kid-status-box">
              <div className={`gochi-kid-status ${numberSlotCanUse === 0 ? 'gochi-kid-status-disable' : ''}`}>
                <span>利用可能数：{numberSlotCanUse}人/日</span>
              </div>
              {shopDetails.hasMenuFreeFlag === 1 && (
                <div className={`gochi-kid-status gochi-kid-menu-free-status`}>
                  <span>店舗提供無料メニュー有り</span>
                </div>
              )}
              <div className={`gochi-kid-status ${shopDetails.gochiKidQrFlag === 0 ? 'gochi-kid-status-disable' : ''}`}>
                <span>{shopDetails.gochiKidQrFlag === 0 ? 'カード型会員証対応不可' : 'カード型会員証対応可'}</span>
              </div>
            </div>
            <div className="shop-digest-body">
              <p className="text-bold pre-line">{displayDescription}</p>
              {!expandDescription && shopDetails.introduction?.length >= 95 && (
                <div className="text-sm text-right">
                  <a
                    className="link text-underline"
                    onClick={() => setExpandDescription(true)}
                  >
                    もっと見る
                  </a>
                </div>
              )}
            </div>
          </header>
          <hr className="over-pc" />
          <div className="shop-menu-gochi-kid">
            <ShopDetailsGochiKid
              showListMenu
              showGochiBillShopMessage
              shopDetails={shopDetails}
              shopId={shopId}
              isShopDetailScreen
              choiceShopId={shopId}
              handleOpenInputGochiKidPriceModal={props.handleOpenInputGochiKidPriceModal}
              handleOpenSelectUserKidModal={props.handleOpenSelectUserKidModal}
              accessToken={props.accessToken}
              accessCode={props.accessCode}
              checkTokenExpired={props.checkTokenExpired}
              numUserKidUse={props.numUserKidUse}
              choiceUserKidId={props.choiceUserKidId}
              handShowErrorUseGochiKidPopup={props.handShowErrorUseGochiKidPopup}
              gochiKidGroupId={props.gochiKidGroupId}
              numberSlotCanUse={numberSlotCanUse}
            />
          </div>
        </div>
        {!props.accessToken && (
          <aside>
            {/*同じエリアの店舗*/}
            <ListMenus data={listShopSameCity} isShopDetailScreen title="同じエリアの店舗" />
            {/*同じエリアの店舗*/}
            {/*同じエリアのギフト商品*/}
            {/*<ListMenus
              swiperId="list-menu-same-city-container"
              title="同じエリアのメニュー"
              data={listMenuSameCity.map((menu) => ({
                ...menu,
                price: menu.lastPrice,
              }))}
              isShopDetailScreen
            />*/}
            {/*同じエリアのギフト商品*/}

            {/*<ListMenus
              swiperId="list-recently-view-menu-container"
              title="ごちめしで最近見た商品・お食事券"
              data={recentlyViewedMenus.map((menu) => ({
                ...menu,
                price: menu.lastPrice,
              }))}
              isShopDetailScreen
            />*/}
          </aside>
        )}
      </main>
      <LoadingOverlay loading={loading}/>
      <ConfirmModal
        showing={openErrorPopup}
        content={errorMessage}
        handleClose={handleClosePopupAndRedirect}
      />
    </>
  );
}

