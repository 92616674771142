import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import 'firebase/auth'; 
import {showError} from "../../../actions/action_error_alert";
import {saveRegisterInput} from "../../../actions/action_register";
import {showLoading} from "../../../actions/action_loading"; 
import queryString from 'query-string';
import {addParamUrl} from '../../../utils';

//Login by cognito
import { Auth } from 'aws-amplify';

import UserService from "../../../api/services/UserService";

// Amplify.configure({
//     Auth: {
//     	identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
//     	region: process.env.REACT_APP_REGION,
//     	userPoolId: process.env.REACT_APP_USER_POOL_ID,
//         userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
// 	}
// });

class RegisterForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectTo: false,
			errorMessage: null,
			menuId: null, 
      goLogin: false,
      username: "",
      email: "",
      password: "",
      rePassword: "",
      showPassword: false,
      showRePassword: false,
      goRegister: false,
      isValid: false,
      emailError: "",
      usernameError: "",
      passwordError: "",
      rePasswordError: "",
      src: "",
      isSite: ''
		};
		this.onEmailChange = this.onEmailChange.bind(this);
		this.onUsernameChange = this.onUsernameChange.bind(this);
		this.onPasswordChange = this.onPasswordChange.bind(this);
		this.onRePasswordChange = this.onRePasswordChange.bind(this);
		this.onShowHidePass = this.onShowHidePass.bind(this);
		this.onShowHideRePass = this.onShowHideRePass.bind(this);
    this.submitRegister = this.submitRegister.bind(this);  
    this.changeGoRegister = this.changeGoRegister.bind(this);
    this.valid = this.valid.bind(this);
	}  

	async submitRegister(e){
    e.preventDefault();
    const validForm = this.valid();
    this.setState({
      isValid: validForm
    });

    if (validForm) {
      this.props.showLoading(true);
      try {
        // Check if email already exists in database
        const email = this.state.email.trim();
        const { data } = await UserService.getUserList({
          email,
          sort: "createdAtDesc",
          page: 1,
          limit: 1,
        });
        if (data.count > 0) {
          const e = new Error();
          e.code = "UsernameExistsException";
          throw e;
        }

        const result = await Auth.signUp({
          username: this.state.email,
          password:  this.state.password,
          attributes: {
            email: this.state.email,
          },
        });

        this.props.saveRegisterInput(this.state.username, this.state.email, this.state.password, result); 
        this.setState({
          redirectTo: true
        });
      } catch (error) {
        console.log(error);
        if (error.code === "UsernameExistsException") { 
          this.props.showError(true, this.props.t("pages.register.user not confirmed exception signup"), '');
        } else if (error.code === "TooManyRequestsException") { 
          this.props.showError(true, this.props.t("pages.register.too many requests exception"), '');
        } else { 
          this.props.showError(true, this.props.t("There is something wrong"), '');
        }  
      }
      this.props.showLoading(false);
    }
  }

  validEmail(email) {
    var pattern = new RegExp(
      /^(("[\w+-\s]+")|([\w+-]+(?:\.[\w+-]+)*)|("[\w+-\s]+")([\w+-]+(?:\.[\w+-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return pattern.test(email);
  }
  
  valid() {
    this.setState({
      emailError: "",
      usernameError: "",
      passwordError: "",
      rePasswordError: ""
    });
    let hasEmailError = false;
    let hasUsernameError = false;
    let hasPasswordError = false;
    let hasRePasswordError = false;
    const valueEmail = this.state.email.trim();
    if (valueEmail.length <= 0) {
      this.setState({
        emailError: this.props.t("pages.register.message please enter email")
      });
      hasEmailError = true;
    } else if (valueEmail.length > 200) {
      this.setState({
        emailError: this.props.t("pages.register.message email within 200")
      });
      hasEmailError = true;
    } else if (!this.validEmail(valueEmail)) {
      this.setState({
        emailError: this.props.t("pages.register.message please enter email")
      });
      hasEmailError = true;
    }

    const valueUserName = this.state.username.trim();
    if (valueUserName.length <= 0) {
      this.setState({
        usernameError: this.props.t("pages.register.message sure enter username")
      });
      hasUsernameError = true;
    } else if (valueUserName.length > 200) {
      this.setState({
        usernameError: this.props.t("pages.register.message username up to 200")
      });
      hasUsernameError = true;
    }

    const valuePassword = this.state.password.trim();
    if (valuePassword.length <= 0) {
      this.setState({
        passwordError: this.props.t("pages.register.message please sure your password")
      });
      hasPasswordError = true;
    } else if (valuePassword.length < 8 || valuePassword.length > 99) {
      this.setState({
        passwordError: this.props.t("pages.register.message password 8 99")
      });
      hasPasswordError = true;
    } else if (
      !/[A-Z]/.test(valuePassword) ||
      !/[a-z]/.test(valuePassword) ||
      !/[0-9]/.test(valuePassword)
    ) {
      this.setState({
        passwordError: this.props.t("pages.register.message suggest password")
      });
      hasPasswordError = true;
    }

    const valueRePassword = this.state.rePassword.trim();
    if (valueRePassword.length <= 0) {
      this.setState({
        rePasswordError: this.props.t("pages.register.message please sure your password")
      });
      hasRePasswordError = true;
    } else if (valueRePassword.length < 8 || valueRePassword.length > 99) {
      this.setState({
        rePasswordError: this.props.t("pages.register.message password 8 99")
      });
    } else if (
      !/[A-Z]/.test(valueRePassword) ||
      !/[a-z]/.test(valueRePassword) ||
      !/[0-9]/.test(valueRePassword)
    ) {
      this.setState({
        rePasswordError: this.props.t("pages.register.message suggest password")
      });
      hasRePasswordError = true;
    }
    
    if (this.state.password !== this.state.rePassword) {
      this.setState({
        rePasswordError: this.props.t("pages.register.message password not match")
      });
      return false
    }
    if (hasEmailError || hasUsernameError || hasPasswordError || hasRePasswordError) {
      return false
    }
    return true
  }

	onEmailChange(e) {
    this.setState({email: e.target.value});
    
  }
  
  onUsernameChange(e) {
		this.setState({username: e.target.value});
	}

	onPasswordChange(e) {
		this.setState({password: e.target.value});
  }
  
  onRePasswordChange(e) {
		this.setState({rePassword: e.target.value});
	}

	onShowHidePass(e){
		this.setState({
			showPassword: !this.state.showPassword
		})
  }
  
  onShowHideRePass(e){
		this.setState({
			showRePassword: !this.state.showRePassword
		})
	}

	UNSAFE_componentWillMount() {
		sessionStorage.removeItem("fullInviteContent");
		sessionStorage.removeItem("selectFriend");
		sessionStorage.removeItem("inviteContent");
		sessionStorage.removeItem("paymentMethod");
		sessionStorage.removeItem("menuInfo");
		sessionStorage.removeItem("comment");
		sessionStorage.removeItem("guestInfo");
		sessionStorage.removeItem("messageShop");
		var userInfo = this.props.userInfo;
		if (userInfo) {
			if(userInfo.isGuest === true) {
        localStorage.removeItem("userInfo");
        if(this.props.saveUserInfo){
          this.props.saveUserInfo(false, userInfo.isGuest, []);
        }
			}
		}
	}

	componentDidMount() {
		let url = window.location.search;
		let params = queryString.parse(url);
    var menuId = params.id;
    var src = params.src;
    var isSite = params.isSite;

		if (src) {
			this.setState({
				src: src
			});
		}

    if (isSite) {
			this.setState({
				isSite: isSite
			});
		}

		this.setState({
			menuId: menuId
		});
  }
  
  changeGoRegister(e) {
		this.setState({
			goRegister: true
		})
  }

	render() {
    const { t } = this.props;
    document.body.className = "auth signup";
    var srcParam = this.state.src ? '&src=' + this.state.src : '';
    var isSiteParam = this.state.isSite ? {name: 'isSite', value: this.state.isSite} : {};

    let link = '';
    
    if (this.state.goRegister) {
      if(this.state.menuId){
        window.history.pushState(null, null, addParamUrl('/signup?id=' + this.state.menuId + srcParam, [isSiteParam]));
        link = addParamUrl("/signup?id=" + this.state.menuId + srcParam, [isSiteParam]);
        return <Redirect to={link} />
      }else{
        window.history.pushState(null, null, addParamUrl('/signup', [isSiteParam]));
        link = addParamUrl("/signup", [isSiteParam]);
        return <Redirect to={link} />
      }
		}

		const redirectTo = this.state.redirectTo;
    if (redirectTo === true) {
      if(this.state.menuId){
        window.history.pushState(null, null, addParamUrl('/signup/form?id=' + this.state.menuId + srcParam, [isSiteParam]));
        link = addParamUrl("/signup/confirm?id=" + this.state.menuId + srcParam, [isSiteParam]);
        return <Redirect to={link} />
      }else{
        window.history.pushState(null, null, addParamUrl('/signup/form', [isSiteParam]));
        link = addParamUrl("/signup/confirm", [isSiteParam]);
        return <Redirect to={link} />
      }
    
    }

    return (
        <div>
          <MetaTags>
            <title>新規登録｜飲食店のメニューを贈れる「ごちめし」</title>
            <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
            <meta name="description" content="新規登録ページです。「ごちめし」は飲食店のメニューをプレゼントできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
            <meta name="twitter:card swiper-slide" content="summary_large_image" />
            <meta name="twitter:title" content="新規登録｜飲食店のメニューを贈れる「ごちめし」" />
            <meta name="twitter:description" content="新規登録ページです。「ごちめし」は飲食店のメニューをプレゼントできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
            <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
            <meta name="twitter:site" content="@gochi_meshi" />
            <meta name="twitter:creator" content="@gochi_meshi" />
            <meta name="twitter:image:width" content="300" />
            <meta name="twitter:image:height" content="300" />
            <meta property="og:site_name" content="新規登録｜飲食店のメニューを贈れる「ごちめし」" />
            <meta property="og:title" content="新規登録｜飲食店のメニューを贈れる「ごちめし」" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
            <meta property="og:description" content="新規登録ページです。「ごちめし」は飲食店のメニューをプレゼントできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
          </MetaTags>
          <header className="global-login-header-new forgot-password-header">
            <div className="logo-nav">
              <h1 className="logo">
                <a href="/">
                  <div className="new-logo-login">
                    <source
                      media="(max-width: 180px)"
                      srcSet="/assets/img/common/logo_gochi_header_mypage.svg"
                    />
                    <img
                      src="/assets/img/common/logo_gochi_header_mypage.svg "
                      alt="気持ちを「ごちそう」にして贈れるアプリ ごちめし"
                      loading="lazy"
                    />
                  </div>
                  <span className="title-page">GOCHIアカウントを新規登録</span>
                </a>
              </h1>
            </div>
          </header>

          <main>
            <div className="container">
                <div className="heading-lg">
                    <div className="title">
                        新規アカウント登録
                    </div>
                </div>

                {process.env.REACT_APP_MFA !== 'off' && (
                  <nav className="step-group signup-step-group">
                      <div className="step is-success">
                          <div className="step-btn">
                              <div className="step-outer-circle">
                                  <div className="step-inner-circle"></div>
                              </div>
                          </div>
                      </div>
                      <div className="step">
                          <div className="step-btn">
                              <div className="step-outer-circle">
                                  <div className="step-inner-circle"></div>
                              </div>
                          </div>
                      </div>
                      <div className="step is-disabled">
                          <div className="step-btn">
                              <div className="step-outer-circle">
                                  <div className="step-inner-circle"></div>
                              </div>
                          </div>
                      </div>
                      <div className="step is-disabled">
                          <div className="step-btn">
                              <div className="step-outer-circle">
                                  <div className="step-inner-circle"></div>
                              </div>
                          </div>
                      </div>
                  </nav>
                )}

                <form onSubmit={this.handleSubmitForm}>
                    <div className="signup-input">
                        <div className="signup-input-group">
                            <div className="signup-input-header">
                                <div className="heading-md">
                                    <div className="title">登録情報の入力</div>
                                </div>
                            </div>
                            <div className="signup-input-body">
                                <div className="fieldset">
                                    <div className="fieldset-header">
                                        <div className="title">お名前</div>
                                        <em className="fieldset-mark">必須</em>
                                    </div>
                                    <div className="fieldset-body">
                                        <div className="field-group field-group-column">
                                            <div className="field field-fluid">
                                                <input className={"form-control " + (!this.state.isValid && this.state.usernameError ? 'input-error' : '')}
                                                  placeholder="例) ごち めし子"
                                                  type="text"
                                                  onChange={this.onUsernameChange}
                                                  value={this.state.username}
                                                  name="username"
                                                />
                                            </div>
                                            {!this.state.isValid && this.state.usernameError &&
                                              <div className="text-danger fs-14 mb-2 fs-12">{this.state.usernameError}</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="fieldset">
                                    <div className="fieldset-header">
                                        <div className="title">メールアドレス</div>
                                        <em className="fieldset-mark">必須</em>
                                    </div>
                                    <div className="fieldset-body">
                                        <div className="field-group field-group-column">
                                            <div className="field field-fluid">
                                                <input className={"form-control " + (!this.state.isValid && this.state.emailError ? 'input-error' : '')}
                                                  placeholder="例) mail@gochi.online"
                                                  type="text"
                                                  onChange={this.onEmailChange}
                                                  value={this.state.email}
                                                  name="email"
                                                />
                                            </div>
                                          {!this.state.isValid && this.state.emailError &&
                                            <div className="text-danger fs-14 mb-2 fs-12">{this.state.emailError}</div>
                                          }
                                        </div>
                                    </div>
                                </div>
                                <div className="fieldset">
                                    <div className="fieldset-header">
                                        <div className="title">パスワード</div>
                                        <em className="fieldset-mark">必須</em>
                                    </div>
                                    <div className="fieldset-body">
                                        <div className="field-group field-group-column">
                                            <div className="field field-fluid field-password">
                                                <input
                                                  id="password"
                                                  type={this.state.showPassword ? "text" : "password"}
                                                  name="password"
                                                  placeholder={t('pages.register.placeholder password')}
                                                  className={"form-control " + (!this.state.isValid && this.state.passwordError ? 'input-error' : '')}
                                                  onChange={this.onPasswordChange}
                                                  defaultValue={this.state.password}
                                                />
                                                <button className="btn" type="button" onClick={this.onShowHidePass}>
                                                    <span className={this.state.showPassword ? "icon icon-eye open" : "icon icon-eye close"}></span>
                                                </button>
                                            </div>
                                            {!this.state.isValid && this.state.passwordError &&
                                              <div className="text-danger fs-14 mb-2 fs-12">{this.state.passwordError}</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="fieldset">
                                    <div className="fieldset-header">
                                        <div className="title">パスワード確認用</div>
                                        <em className="fieldset-mark">必須</em>
                                    </div>
                                    <div className="fieldset-body">
                                        <div className="field-group field-group-column">
                                            <div className="field field-fluid field-password">
                                                <input
                                                  id="rePassword"
                                                  type={this.state.showRePassword ? "text" : "password"}
                                                  name="rePassword"
                                                  placeholder={t('pages.register.placeholder confirm password')}
                                                  className={"form-control " + (!this.state.isValid && this.state.rePasswordError ? 'input-error' : '')}
                                                  onChange={this.onRePasswordChange}
                                                  defaultValue={this.state.rePassword}
                                                />
                                                <button className="btn" type="button" onClick={this.onShowHideRePass}>
                                                    <span className={this.state.showRePassword ? "icon icon-eye open" : "icon icon-eye close"}></span>
                                                </button>
                                            </div>
                                            {!this.state.isValid && this.state.rePasswordError &&
                                              <div className="text-danger fs-14 mb-2 fs-12">{this.state.rePasswordError}</div>
                                            }
                                            <div className="invalid-feedback">
                                                ※英大文字・小文字・数字の３種類全てを使ってください。<br />
                                                ※８桁以上でご登録ください。
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="signup-input-footer">
                                <div className="text">ご入力いただいたメールアドレスに認証コードをお送りします。</div>
                                <div className="policy-check-container">
                                  <div className="mt-3 gochi-policy-check">
                                    <label class="checkbox-custom">
                                        <input checked={this.state.policyCheck} onChange={e => this.setState({policyCheck: e.target.checked})} id="policy-check" type="checkbox" class="" name="question"/>
                                        <span class="checkmark"></span>
                                        <a href='https://gochimeshi.com/policy/terms/' target='_blank' rel='noopener noreferrer'>
                                            {this.props.t('pages.login.terms')}
                                          </a>
                                          {this.props.t('pages.login.and')}
                                          <a href='https://gochimeshi.com/policy/privacy/' target='_blank' rel='noopener noreferrer'>
                                            {this.props.t('pages.login.Gochi privacy policy')}
                                          </a>
                                          {this.props.t('pages.login.Agree to')} 
                                    </label> 
                                  </div>
                                </div>
                                <div className="btn-group signup-input-btn-group">
                                    {
                                      this.state.menuId ? (
                                        <a href={addParamUrl(`/signup?id=` + this.state.menuId + srcParam, [isSiteParam])} className="btn btn-rect btn-cancel signup-input-btn"><div>戻る</div></a>
                                      ) : (
                                        <a href={addParamUrl(`/signup`, [isSiteParam])} className="btn btn-rect btn-cancel signup-input-btn"><div>戻る</div></a>
                                      )
                                    }
                                    <button disabled={!this.state.policyCheck} className="btn btn-rect signup-input-btn" onClick={this.submitRegister}><div>次へ</div></button>
                                </div>
                                {this.state.errorMessage &&
                                  <div className="text-danger mb-2 fs-14 font-weight-bold">{t(this.state.errorMessage)}</div>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </div>

          </main>


          <footer className="global-footer footer-mypage new-footer-login">
            <section className="global-footer-nav-area">
              <div className="container">
                <div className="section-header">
                  <div className="heading-xs">
                    <div className="title">
                      <h1>
                        <img
                          src="/assets/img/common/logo_gochi_footer.svg"
                          alt="ごちめし"
                          loading="lazy"
                        />
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="section-body footer-nav">
                  <ul className="footer-link-area">
                    <li>
                      <a href="https://gochimeshi.com/policy/">各種規約</a>
                    </li>
                    <li>
                      <a href="https://gochimeshi.com/policy/privacy/">
                        プライバシーポリシー
                      </a>
                    </li>
                    <li>
                      <a href="https://gochimeshi.com/policy/legal/">
                        特定商取引法に基づく表記
                      </a>
                    </li>
                    <li>
                      <a href="https://user-guide.gochimeshi.com/">よくある質問</a>
                    </li>
                    <li>
                      <a href="https://gochimeshi.com/info/">お問い合わせ</a>
                    </li>
                    <li>
                      <a href="https://www.gigi.tokyo/">運営会社（Gigi株式会社）</a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

              <div className="copyright">
                  <small>ごちめし®︎ さきめし®︎ びずめし®︎ GOCHI®︎は、Gigi株式会社の登録商標です<br />©2020 Gigi Inc.</small>
              </div>
          </footer>
        </div>
      );
    }
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({ 
  showError: (showing, title, content) =>dispatch(showError(showing, title, content)),
	saveRegisterInput: (username, email, password, signUpResult) => dispatch(saveRegisterInput(username, email, password, signUpResult)),
  showLoading: (showing) => dispatch(showLoading(showing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RegisterForm));
