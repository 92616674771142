import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { showError } from "../../../actions/action_error_alert";
import { showLoading } from "../../../actions/action_loading";
import queryString from "query-string";
import Payment from "../../pages/Invite/Payment";
import { useHistory } from "react-router-dom";

const re = /^[0-9\b]+$/;
function GochiUseInput(props) {
  const { gochiInfo } = props;
  const history = useHistory();
  const { inputAmount, setInputAmount, taxType, setTaxType, remainAmount } =
    props;
  const feeRate =
    (props.shopInfo && props.shopInfo.feeRate) ||
    (props.menuInfo && props.menuInfo.feeRate);
  const taxRate = props.shopInfo && props.shopInfo.taxRate;
  const useAmount =
    taxType === "INCLUDED"
      ? Number(inputAmount)
      : Math.round(Number(inputAmount) * (1 + Number(taxRate)));
  const added =
    remainAmount >= useAmount
      ? 0
      : Math.round((useAmount - remainAmount) * (1 + Number(feeRate)));

  const { t } = props;
  function onSubmit() {
    props.onSubmitInput(added, useAmount);
  }
  function onCancel() {
    history.goBack();
  }
  useEffect(() => {
    const params = queryString.parse(window.location.search);
    if (params.inputAmount) {
      setInputAmount(params.inputAmount);
    }
  }, []);
  console.log("added amount: ", added, useAmount, remainAmount, inputAmount);
  let searchParams = queryString.parse(window.location.search);
  searchParams.inputAmount = inputAmount;
  console.log('searchParams:', queryString.stringify(searchParams));
  const isEducateSystem = props.siteType === "fukuoka" || props.siteType === "suita" || props.siteType === "nishiwaki";
  return (
    <div className="gochi-use-input">
      {/* <div className="remain-amount-container mt-3">
        <div className="font-weight-bold fs-18">使える金額</div>
        <div className="remain-amount-text text-center">
          <span className="fs-18">計</span>
          <span className="fs-35 mx-1 fw-bolder">
            {gochiInfo?.remainAmount?.toLocaleString() || 0}
          </span>
          <span className="fs-18">円</span>
        </div>
      </div> */}
      <div className="border-top p-2">
        <div className="required-dot">
          <div className="dot-red"></div>
          {/* <div className="dot-text-label fs-18">{props.t('pages.gochiuse.amount to use')}</div> */}
          <div className="dot-text-label fs-18">{isEducateSystem ? "利用金額（税込）" : props.t('pages.gochiuse.amount to use tax included')}</div>
        </div>
      </div>
      
      {/* <div className="d-flex p-2">
        <label className="radio-custom fs-14">
          <input
            type="radio"
            name="taxType"
            value="INCLUDED"
            checked={taxType === "INCLUDED"}
            onChange={(e) => setTaxType(e.target.value)}
          />
          <span className="checkmark"></span>
          <span>{props.t('pages.invite.Tax included amount')}</span>
        </label>
        <label className="radio-custom fs-14 ml-2">
          <input
            type="radio"
            name="taxType"
            value="EXCLUDED"
            onChange={(e) => setTaxType(e.target.value)}
            checked={taxType === "EXCLUDED"}
          />
          <span className="checkmark"></span>
          <span>{props.t('pages.invite.Tax excluded amount')}</span>
        </label>
      </div> */}

      <div className="d-flex w-100 align-items-center mt-3">
        <input
          onChange={(e) => {
            if (e.target.value === "" || re.test(e.target.value)) {
              setInputAmount(e.target.value);
            }
          }}
          // min={1}
          type="number"
          min="0"
          inputmode="numeric"
          pattern="[0-9]*"
          className="amount-text-input form-control mr-2 bg-white"
          value={inputAmount}
        />
        <span className="fs-18">円</span>
      </div>

      {props.inputErrorMessage && (
        <div className="text-red fs-14 mt-2">{props.inputErrorMessage}</div>
      )}

      {added > 0 &&
        process.env.REACT_APP_ENABLED_EXCEEDED_AMOUNT_PAYMENT === "true" && (
          <div className="d-flex flex-column justify-content-center align-items-center mt-3">
            <div className="arrow-down"></div>
            <div>
              <span className="fs-15 text-dark">{props.t('pages.gochiuse.difference')}</span>
              <span className="fs-44 font-weight-bold clr-CC1624 mx-2">{(useAmount - remainAmount)?.toLocaleString()}</span>
              <span className="fs-20 font-weight-bolder clr-CC1624">円</span>
            </div>
          </div>
        )}

      {added > 0 &&
        process.env.REACT_APP_ENABLED_EXCEEDED_AMOUNT_PAYMENT === "true" && (
          <div className="content-dark">
            <div
              className="d-flex justify-content-between fs-18 clr-000000 align-items-end"
              style={{
                borderBottom: "1px solid #000000",
                margin: "0 20px 20px",
              }}
            >
              <div className="mr-2" style={{ lineHeight: "36px" }}>
                {t("pages.invite.Bill amount")}
              </div>
              <div className="fs-30 font-weight-bold">
                {added.toLocaleString()}
                <span className="fs-16">円</span>
              </div>
            </div>

            <Payment
              referer={window.location.pathname + '?' + queryString.stringify(searchParams)}
            />
          </div>
        )}

      <div className="mt-4 text-center">
        <button onClick={onSubmit} className="btn btn-green-38">
          {isEducateSystem ? "確認画面に進む" : props.t('pages.gochiuse.Proceed to order')}
        </button>
      </div>
      <div className="mt-4 text-center">
        <div className="cursor-pointer" onClick={onCancel}>
          <u>{props.t('pages.mypage.title button cancel')}</u>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(withTranslation()(GochiUseInput));
