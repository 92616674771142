import React, { Component } from "react";   
import {Link, Redirect} from 'react-router-dom'; 
import {connect} from 'react-redux'; 
import { withTranslation } from 'react-i18next';
import {log} from '../../../utils'; 
import PaymentService from "../../../api/services/PaymentService";
import {savePaymentMethod} from "../../../actions/action_payment";
import {showLoading} from "../../../actions/action_loading";
import {showError} from "../../../actions/action_error_alert";
import queryString from 'query-string'
import PaymentCardBrands from "../../common/PaymentCardBrands";
var stripe = require('stripe-client')(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

class RegisterCardModal extends Component {
	constructor(){
	    super();
	    let newDate = new Date() 
		let curMonth = newDate.getMonth() + 1;
		let curYear = newDate.getFullYear(); 
	    this.state = { 
            headerTitle: "カード情報登録",
            cardHolder: "",
            ccnum: "", 
            expiredMonth: curMonth,
            expiredYear: curYear,
            cvc: "",
            redirectToReferrer: false,
            errorMessage: "",
            menuId: null,
            currentMonth: "",
			currentYear: "",
        } 

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this); 
        this.getCurrentMonYear = this.getCurrentMonYear.bind(this);
        this.handleBack = this.handleBack.bind(this);
	}

	static contextTypes = {
		router: () => true, // replace with PropTypes.object if you use them
	}

	handleChange(e){
	    this.setState({
	      	[e.target.name] : e.target.value
	    }) 
	} 

	async onCreateToken(cardElement) {  
		const { t } = this.props; 
		this.props.showLoading(true);
	    try {
			var token = null;
			if (process.env.REACT_APP_VERITRANS_ENABLE === 'true') {
				// Get token from Veritrans
				const response = await fetch('https://api.veritrans.co.jp/4gtoken', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json; charset=utf-8'
					},
					body: JSON.stringify({
						token_api_key: process.env.REACT_APP_VERITRANS_TOKEN_API_KEY,
						card_number: cardElement.card.number,
						card_expire: String(cardElement.card.exp_month).padStart(2, '0') + '/' + String(cardElement.card.exp_year).substring(2),
						security_code: cardElement.card.cvc,
						cardholder_name: cardElement.card.name,
						lang: 'en'
					})
				});

				if (response.status !== 200) {
					this.props.showLoading(false);
					return this.setState({
						errorMessage: t('pages.invite.Card currently unavailable Failed to register')
					});
				}

				const data = await response.json();

				token = data.token;
			} else {
				// Get token from Stripe
				const response = await stripe.createToken(cardElement).catch(async () => { 
					this.props.showLoading(false);
					return this.setState({
						errorMessage: t('pages.invite.Card currently unavailable Failed to register')
					});
				});

				var data = await response.text();
				data = JSON.parse(data);

				if (data.error) { 
					this.props.showLoading(false);
					return this.setState({
						errorMessage: t('pages.invite.Card currently unavailable Failed to register')
					});
				}

				if (data.id) {
					token = data.id;
				}
			}

	      	var userId = null;

	      	if (this.props.userInfo.userId) {
	      		userId = this.props.userInfo.userId;
	      	}
	      	if (token && userId) {
		        const result = await PaymentService.registerCardtoken(
		          	userId,
		          	token
		        );

		        log(result);
		        if (result.status === 200) {
					console.log('loc 1');
			        this.setState({
				  		errorMessage: "",
				  	});
					this.props.fetchPaymentList(this.props.userInfo.userId);
					this.props.onHide();
				} else {
					this.setState({
						errorMessage: t('pages.invite.Failed to register card')
					});
				}
	      	} else { 
	        	this.setState({
			  		errorMessage: t('pages.invite.User information not be obtained Failed to register')
			  	})
	      	} 

			this.props.showLoading(false);
	    } catch (error) {
	      	log(error); 
	      	this.setState({
		  		errorMessage: t('pages.invite.Failed to register card')
		  	});

			this.props.showLoading(false);
	    }  
	    this.props.showLoading(false);
	}

	async handleSubmit(e) { 
		e.preventDefault();

		var cardElement = {
			card: {
				name: e.target.elements.cardHolder.value,
				number: e.target.elements.ccnum.value,
				exp_month: e.target.elements.expiredMonth.value,
				exp_year: e.target.elements.expiredYear.value,
				cvc: e.target.elements.cccsc.value
			}
		};

     	await this.onCreateToken(cardElement);
	} 

	getCurrentMonYear(){
		let newDate = new Date() 
		let month = newDate.getMonth() + 1;
		let year = newDate.getFullYear(); 
		this.setState({
			currentMonth: month,
			currentYear: year
		})
	}
 

	UNSAFE_componentWillMount(){   
		let url = window.location.search;
		let params = queryString.parse(url);
		var menuId = params.menuId; 

		if (menuId) {
			this.setState({
				menuId: menuId,
			});
		}

		this.getCurrentMonYear();
	}

  componentDidUpdate() {
    if (this.props.flagResetRegisterCard) {
      document.getElementById("formLogin").reset();
      let newDate = new Date() 
      let curMonth = newDate.getMonth() + 1;
      let curYear = newDate.getFullYear();
      this.setState({ 
        cardHolder: "",
        ccnum: "", 
        expiredMonth: curMonth,
        expiredYear: curYear,
        cvc: "",
        redirectToReferrer: false,
        errorMessage: "",
        menuId: null,
        currentMonth: "",
        currentYear: "",
      });
      this.props.setFlagResetRegisterCard(false);
    }
  }

	handleBack(){
		window.history.back();
	}
	
    render() {
    	var userInfo = this.props.userInfo;
    	if (userInfo && userInfo.isLogined === false || !userInfo) { 
            return <Redirect to='/login' />
		}

    	let newDate = new Date();
		let curYear = newDate.getFullYear(); 
    	let months = [];
    	for(var m = 1; m < 13; m ++) { 
    		months.push(<option key={m} value={m} >{("0" + m).slice(-2)}</option>);
    	}

    	let years = [];
    	for(var y = curYear; y < curYear + 10; y ++) { 
    		years.push(<option key={y} value={y} >{y}</option>);
    	}

    	const { t } = this.props; 

        return (
        	<div className={
                this.props.isShow
                ? "custom-modal-container show-modal"
                : "custom-modal-container"
            }
            size="sm">
                <div className={
                    this.props.isShow
                        ? "modal modal-action modal-dialog border-radius-4 register-card-modal is-show"
                        : "modal modal-action modal-dialog border-radius-4 register-card-modal"
                    }>
                    <button
                        className="modal-btn modal-btn-close"
                        // id="modalClose"
                        onClick={this.props.onHide}
                        type="button"
                        >
                        <svg className="icon">
                            <title>閉じる</title>
                            <use xlinkHref="/assets/img/common/icon.svg#times" />
                        </svg>
                    </button>
                    <div className="modal-body p-2 px-10">
                        <div className="form-group lst-payments mt-2">
							<div className="title-lst-shop align-center">
								{t('pages.invite.Register card information')}
							</div>

							<div className="form-block mt-4 register-form">
								<form 
									id="formLogin" 
									action="#" 
									method="post"  
									onSubmit={this.handleSubmit}
								> 
									<div className="add-card-credit-text text-guide text-left">
										<p>
											クレジットカードまたはデビットカードを追加
										</p>
										<p className="enter-credit-text clr-000000 font-weight-bold">
											<b>クレジットカード情報を入力</b>
										</p>
									</div>

									{this.state.errorMessage &&
										<div className="fs-14 font-weight-bold text-danger space-pre-line box-warning-message text-left">{this.state.errorMessage}</div>
									}

									<div className="form-group text-left">
										<label className="infor-card-label font-weight-bold form-label fs-16 clr-000000">カード情報</label>
										<div className="">
											<input className="form-control fs-14" 
													name="cardHolder" 
													placeholder={t('pages.invite.Card holder')} 
													value={this.state.cardHolder} 
													onChange={this.handleChange}
													x-autocompletetype="cc-name"
											/> 
										</div> 
										<div className="mt-11px">
											<input className="form-control fs-14" 
													name="ccnum" 
													placeholder={t('pages.invite.Card number')} 
													value={this.state.cardNumber} 
													onChange={this.handleChange} 
													x-autocompletetype="cc-number"
											/> 
										</div> 
										<div className="mt-2">
											<PaymentCardBrands />
										</div>
									</div>

									<div className="form-group date-payment-box text-left">
										<label className="form-label infor-card-label font-weight-bold clr-000000">{t('pages.invite.expiredDate')}</label>
										<div className="row no-gutters d-flex">
											<div className="col-5 date-payment">
												<div className="select-custom">
													<select className="form-control date-input" value={this.state.expiredMonth} onChange={this.handleChange} name="expiredMonth" style={{height:"35px"}}>
														{months}
													</select>
												</div>
											</div>
											<div className="col-1 align-center date-time-label font-weight-bold date-time-label-1">{t('other.month')}</div>

											<div className="col-5 ml-auto date-payment">
												<div className="select-custom">
													<select className="form-control date-input" value={this.state.expiredYear} onChange={this.handleChange} name="expiredYear" style={{height:"35px"}}>
														{years}
													</select>
												</div> 
											</div>
											<div className="col-1 align-center date-time-label font-weight-bold">{t('other.year')}</div>
										</div>
									</div> 

									<div className="form-group text-left">
										<label className="form-label infor-card-label clr-000000 font-weight-bold">{t('pages.invite.CVC')}</label>
										<div className="">
											<input className="form-control" 
												placeholder={t('pages.invite.CVC placeholder')} 
												onChange={this.handleChange}
												x-autocompletetype="cc-csc"
												name="cccsc"
												maxLength={4}
											/> 
										</div>  
									</div> 

									<div className="form-group text-center submit-box">
										<button type="submit" className="btn btn-green-38 border-radius-4 submit-btn" >
											{t('pages.invite.Add credit card')}
										</button>
									</div>
								</form>
							</div>
						</div>
                    </div>
                </div>
                <div onClick={this.props.onHide} className="back-drop" />
			</div>
        ); 
    }
}
 
const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({  
	savePaymentMethod: (paymentMethod) => dispatch(savePaymentMethod(paymentMethod)),
    showLoading: (showing) => dispatch(showLoading(showing)),
    showError: (showing, title, content) => dispatch(showError(showing, title, content)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RegisterCardModal)); 