import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import {connect} from "react-redux";
import {Redirect} from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {log, addParamUrl} from '../../../utils'; 

import firebase from 'firebase/app';
import 'firebase/auth';

import AuthService from "../../../api/services/AuthService";
import UserService from "../../../api/services/UserService";
import {showError} from "../../../actions/action_error_alert";
import {saveUserInfo} from "../../../actions/action_users";
import {showLoading} from "../../../actions/action_loading";
import PopupRegisConfirm from "../../../components/common/PopupRegisConfirm";
import queryString from 'query-string';

//Login by cognito
import { Auth } from 'aws-amplify';
import LogService from "../../../api/services/LogService";

class Register extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectTo: false,
			errorMessage: null,
			menuId: null,
			showPassword: false,
			goLogin: true,
			goBackRegister: false,
			popupShowing: false,
			popupTitle: this.props.t('pages.register.title popup apple'),
			popupContent: this.props.t('pages.register.content popup apple'),
			src: "",
			isSite: '',
			showNotificationPolicyPopup: false
		};
		this.onEmailChange = this.onEmailChange.bind(this);
		this.onPasswordChange = this.onPasswordChange.bind(this);
		this.onShowHidePass = this.onShowHidePass.bind(this);
		this.loginByGochiAccount = this.loginByGochiAccount.bind(this);
		this.loginBySNSAccount = this.loginBySNSAccount.bind(this);
		this.loginByFacebook = this.loginByFacebook.bind(this);
		this.loginByApple = this.loginByApple.bind(this);
		this.loginByGoogle = this.loginByGoogle.bind(this);
		this.loginAsGuestAccount = this.loginAsGuestAccount.bind(this);
		this.getUserAttributes = this.getUserAttributes.bind(this);
		this.requestAuthentication = this.requestAuthentication.bind(this);
		this.changeUserLogin = this.changeUserLogin.bind(this);
		this.changeGuestLogin = this.changeGuestLogin.bind(this);
		this.handlePopupConfirm = this.handlePopupConfirm.bind(this);
		this.showAppleConfirm = this.showAppleConfirm.bind(this);
		this.handlePopupClose = this.handlePopupClose.bind(this);
		this.handleLogin = this.handleLogin.bind(this);
	}

	// TODO: Remove this function if not use Cognito Social Login anymore
	async loginBySNSAccount(provider) {
		try {
			sessionStorage.removeItem("isRegister");
			await Auth.signOut();
			const customState = btoa(JSON.stringify({ menuId: this.state.menuId }));
			Auth.federatedSignIn({ provider, customState });
		} catch (e) {
			log(e);
			this.setState({
				errorMessage: "Login has been canceled"
			});
		}
	}

	// TODO: Remove this function if not use Cognito Social Login anymore
	async loginByGoogleCognito() {
		await this.loginBySNSAccount('Google');
	}

	// TODO: Remove this function if not use Cognito Social Login anymore
	async loginByAppleCognito() {
		await this.loginBySNSAccount('SignInWithApple');
	}

	// TODO: Remove this function if not use Cognito Social Login anymore
	async loginByFacebookCognito() {
		await this.loginBySNSAccount('Facebook');
	}

	async loginByGoogle() {
		if (!this.state.policyCheck) {
			return;
		}
		var provider = new firebase.auth.GoogleAuthProvider();
		firebase.auth().useDeviceLanguage();

		try {
			var result = await firebase.auth().signInWithPopup(provider);
			var userGoogle = result.user;
			log(['LOGIN USER GOOGLE: ', userGoogle]);
			const googleUserProvider = userGoogle.providerData.find( googleProvider => googleProvider.providerId === 'google.com' );
			var cognitoUserId = 'Google_' + googleUserProvider.uid;

			//update user to gochimeshi server
			await this.getUserAttributes(cognitoUserId, "GOOGLE", {
				username: googleUserProvider.displayName || (userGoogle.displayName || ""),
				email: googleUserProvider.email || (userGoogle.email || ""),
			});
		} catch (e) {
			log(e);
			if (e.code === "auth/account-exists-with-different-credential")
			{
				this.setState({
					errorMessage: "An account already exists with the same email address"
				})
			}else {
				this.setState({
					errorMessage: "Login has been canceled"
				})
			}
			LogService.sendLog({ loginType: 'loginByGoogle', 'code': e.code });
		}
	}

	async loginByApple() {
		this.setState({
			popupShowing: false,
		})
		var provider = new firebase.auth.OAuthProvider('apple.com');
		firebase.auth().useDeviceLanguage();

		try {
			var result = await firebase.auth().signInWithPopup(provider);
			var userApple = result.user;
			log(['LOGIN USER APPLE: ', userApple]);
			const appleUserProvider = userApple.providerData.find( appleProvider => appleProvider.providerId === 'apple.com' );
			var cognitoUserId = 'SignInWithApple_' + appleUserProvider.uid;

			//update user to gochimeshi server
			await this.getUserAttributes(cognitoUserId, "APPLE", {
		      username: appleUserProvider.displayName || (userApple.displayName || ""),
		      email: appleUserProvider.email || (userApple.email || ""),
		    });
	  	} catch (e) {
	  		log(e);
			if (e.code === "auth/account-exists-with-different-credential")
			{
				this.setState({
					errorMessage: "An account already exists with the same email address"
				})
			}else {
				this.setState({
					errorMessage: "Login has been canceled"
				})
			}
			LogService.sendLog({ loginType: 'loginByApple', 'code': e.code });
	  	}
	}

	async loginByFacebook() {
		if (!this.state.policyCheck) {
			return;
		}
		var provider = new firebase.auth.FacebookAuthProvider();
		// provider.addScope('user_birthday');

		firebase.auth().useDeviceLanguage();

		try {
			var result = await firebase.auth().signInWithPopup(provider);
			var userFacebook = result.user;
			const fbUserProvider = userFacebook.providerData.find( fbProvider => fbProvider.providerId === 'facebook.com' );
			var facebookUserId = 'Facebook_' + fbUserProvider.uid;

			log(['userFacebook', userFacebook]); 
			if (!(fbUserProvider.email || userFacebook.email || "")) {
				LogService.sendLog({ loginType: 'loginByFacebook', 'code': 'no_email' });
			}
			//update user to gochimeshi server
			await this.getUserAttributes(facebookUserId, "FACEBOOK", {
		      username: fbUserProvider.displayName || (userFacebook.displayName || ""),
		      email: fbUserProvider.email || (userFacebook.email || ""),
		    });
		} catch (e) {
			log(e);
			if (e.code === "auth/account-exists-with-different-credential")
			{
				this.setState({
					errorMessage: "An account already exists with the same email address"
				})
			}else {
				this.setState({
					errorMessage: "Login has been canceled"
				})
			}
			LogService.sendLog({ loginType: 'loginByFacebook', 'code': e.code });
		}
	}

	getUserAttributes = async (
		cognitoUserId: string,
		providerName: SignInProvider,
		metadata?: { username: string; email: string },
		) => {
			var props = this.props;
			var thisView = this;

			let idToken = '';
			if (providerName === 'GOOGLE' || providerName === 'APPLE' || providerName === 'FACEBOOK') {
				idToken = await firebase.auth().currentUser.getIdToken(true);
			}

			try {
			  const { data } = await UserService.getUserList({
			    cognitoUserId,
			    sort: "createdAtDesc",
			    page: 1,
			    limit: 1,
			  });

			  if (data.count <= 0) {
			    if (metadata) {
			      // データがなければ作る
			      const response = await UserService.createUser(
			        metadata.email,
			        metadata.username,
			        cognitoUserId
			      );
					log(["log login ", response]);
			      if (response.status !== 200) {
			      	if (response.data.code === "validation.errors.Duplicate"){
						return thisView.setState({
							errorMessage: "Duplicate value is set_Item name_Email address"
						})
					}else {
						return thisView.setState({
							errorMessage: "Login has been canceled"
						})
					}
			      }else{
				      data.list.push(response.data);
				      data.count = 1;
				  }
			    } else {
			    	return thisView.setState({
						errorMessage: "Login has been canceled"
					})
			    }
			  }

				const resAuth = await this.requestAuthentication(providerName, idToken);
				if (resAuth.status !== 200) {
					thisView.props.showLoading(false);
					return thisView.props.showError(true, "There is something wrong", "");
				}

				props.saveUserInfo(true, false, data.list[0]);

				var userInfo = {
					isLogined: true,
					isGuest: false,
					userId: data.list[0].userId,
					accountId: data.list[0].accountId,
					displayName: data.list[0].displayName,
					email: data.list[0].email,
					userImage: data.list[0].userImage && data.list[0].userImage.length > 0 && data.list[0].userImage[0].image
				};

				localStorage.setItem('userInfo', JSON.stringify(userInfo));

				thisView.setState({
					redirectTo: true
				});
			} catch (error) {
			  	log('getUserAttributes error');

				return thisView.setState({
					errorMessage: "Login has been canceled"
				})
			}
	};

	async requestAuthentication(providerName, idToken) {
		switch (providerName) {
			case "GOOGLE":
			case "APPLE":
			case "FACEBOOK":
				// const idToken = await firebase.auth().currentUser.getIdToken(true);
				return await AuthService.authenticateByFirebaseToken(idToken);
			// case "COGNITO":
			default:
				const cognitoSession = await Auth.currentSession();
				return await AuthService.authenticateByCognitoToken(cognitoSession.idToken.jwtToken);
		}
	}

	async loginByGochiAccount(e){
		e.preventDefault();
		if (!this.state.policyCheck) {
			return;
		}
		//login by cognito provider
		log("login by cognito: " + this.state.email);
		log("config = " + Auth.configure());
		this.props.showLoading(true);
		if (!this.state.email) {
			this.props.showLoading(false);
			return this.setState({
				errorMessage: "Login has been canceled"
			})
		}

		if (!this.state.password) {
			this.props.showLoading(false);
			return this.setState({
				errorMessage: "Login has been canceled"
			})
		}
		try {
			const conitoUser = await Auth.signIn(this.state.email, this.state.password);
			log('successful sign in: ' + conitoUser.username);
			//update user to gochimeshi server
			await this.getUserAttributes(conitoUser.username, "COGNITO");
		} catch (e) {
			log(e);
			this.setState({
				errorMessage: "Login has been canceled"
			})
		}
		this.props.showLoading(false);
	}

	onEmailChange(e) {
		this.setState({email: e.target.value});
	}

	onPasswordChange(e) {
		this.setState({password: e.target.value});
	}

	onShowHidePass(e){
		this.setState({
			showPassword: !this.state.showPassword
		})
	}

	loginAsGuestAccount(e){
	    e.preventDefault();
	    var user = {};
	    this.props.saveUserInfo(true, true, user);
	    this.setState({
            redirectTo: true
        });

        var userInfo = {
			isLogined: true,
			isGuest: true
		};

		localStorage.setItem('userInfo', JSON.stringify(userInfo));
	}

	UNSAFE_componentWillMount() {
		sessionStorage.removeItem("fullInviteContent");
		sessionStorage.removeItem("selectFriend");
		sessionStorage.removeItem("inviteContent");
		sessionStorage.removeItem("paymentMethod");
		sessionStorage.removeItem("menuInfo");
		sessionStorage.removeItem("comment");
		sessionStorage.removeItem("guestInfo");
		sessionStorage.removeItem("messageShop");
		var userInfo = this.props.userInfo;
		if (userInfo) {
			if(userInfo.isGuest === true) {
				localStorage.removeItem("userInfo");
				this.props.saveUserInfo(false, userInfo.isGuest, []);
			}
		}
	}

	componentDidMount() {
		let url = window.location.search;
		let params = queryString.parse(url);
		var menuId = params.id;
		var src = params.src;
		var isSite = params.isSite;

		if (src) {
			this.setState({
				src: src
			});
		}

		if (isSite) {
			this.setState({
				isSite: isSite
			});
		}

		this.setState({
			menuId: menuId
		});

		if(menuId === undefined){
			sessionStorage.removeItem("isRegister");
			sessionStorage.setItem("isMypage", true);
		}else{
			sessionStorage.removeItem("isMypage");
		}
	}

	showAppleConfirm() {
		if (!this.state.policyCheck) {
			return;
		}
		this.setState({
			popupShowing: true,
		})
	}

	handlePopupClose() {
		this.setState({
			popupShowing: false,
		})
	}

	handlePopupConfirm() {
		this.loginByApple();
	}

	changeUserLogin(e) {
		this.setState({
			goLogin: true,
			goBackRegister: false
		})
	}
	changeGuestLogin(e) {
		var isRegister = sessionStorage.getItem('isRegister');
		if(isRegister){
			this.setState({
				goBackRegister: true
			})
		}else{
			this.setState({
				goLogin: false,
				goBackRegister: false
			})
		}
	}

	handleLogin(methodLogin) {
		if (this.state.policyCheck) {
			if (methodLogin === "APPLE") {
				this.showAppleConfirm();
			} else if (methodLogin === "FACEBOOK") {
				this.loginByFacebook();
			} else if (methodLogin === "GOOGLE") {
				this.loginByGoogle();
			} else if (methodLogin === "FORM") {
				var srcParam = this.state.src ? '&src=' + this.state.src : '';
				var isSiteParam = this.state.isSite ? {name: 'isSite', value: this.state.isSite} : {};
				if (this.state.menuId) {
					window.location.href = addParamUrl(`/signup/form?id=` + this.state.menuId + srcParam, [isSiteParam]);
				} else {
					window.location.href = addParamUrl(`/signup/form`, [isSiteParam]);
				}
			}
		} else {
			this.setState({
				showNotificationPolicyPopup: true
			});
		}
	}

	render() {
		const { t } = this.props; 
		document.body.className = "auth signup";

		// window.history.pushState(null, null, '/signup'); 
		var srcParam = this.state.src ? '&src=' + this.state.src : '';
		var isSiteParam = this.state.isSite ? {name: 'isSite', value: this.state.isSite} : {};

		if (this.state.goBackRegister && this.state.menuId) {
			window.history.pushState(null, null, addParamUrl('/signup?id=' + this.state.menuId + srcParam, [isSiteParam]));
			let link = addParamUrl("/?id=" + this.state.menuId + srcParam, [isSiteParam]);
			return <Redirect to={link} />
		}

		if (!this.state.goLogin) {
			window.history.pushState(null, null, addParamUrl('/login', [isSiteParam]));
			let link = addParamUrl("/login", [isSiteParam]);
			return <Redirect to={link} />
		}

		var isRegister = sessionStorage.getItem('isRegister');
		if (this.state.redirectTo === true) {
			if(isRegister && this.state.menuId){
				window.history.pushState(null, null, addParamUrl('/signup?id=' + this.state.menuId + srcParam, [isSiteParam]));
				let link = addParamUrl("/invite?id=" + this.state.menuId + srcParam, [isSiteParam]);
        		return <Redirect to={link} />
			}else{
				window.history.pushState(null, null, addParamUrl('/mypage', [isSiteParam]));
				var link = addParamUrl("/mypage", [isSiteParam]);
				return <Redirect to={link} />
			}
		  }

        return (
			<div>
				<MetaTags>
					<title>新規登録｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
					<meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
					<meta name="description" content="新規登録ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
					<meta name="twitter:card swiper-slide" content="summary_large_image" />
					<meta name="twitter:title" content="新規登録｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
					<meta name="twitter:description" content="新規登録ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
					<meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
					<meta name="twitter:site" content="@gochi_meshi" />
					<meta name="twitter:creator" content="@gochi_meshi" />
					<meta name="twitter:image:width" content="300" />
					<meta name="twitter:image:height" content="300" />
					<meta property="og:site_name" content="新規登録｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
					<meta property="og:title" content="新規登録｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
					<meta property="og:type" content="article" />
					<meta property="og:url" content={window.location.href} />
					<meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
					<meta property="og:description" content={"新規登録ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" } />
				</MetaTags>
				<header className="global-login-header-new forgot-password-header">
          <div className="logo-nav">
            <h1 className="logo">
              <a href="/">
                <div className="new-logo-login">
                  <source
                    media="(max-width: 180px)"
                    srcSet="/assets/img/common/logo_gochi_header_mypage.svg"
                  />
                  <img
                    src="/assets/img/common/logo_gochi_header_mypage.svg "
                    alt="気持ちを「ごちそう」にして贈れるアプリ ごちめし"
                    loading="lazy"
                  />
                </div>
                <span className="title-page">GOCHIアカウントを新規登録</span>
              </a>
            </h1>
          </div>
				</header>

				<main>
					<div className="container signup-new-body">
						<div className="heading-lg">
							<div className="title">
								初めてご利用の方へ
							</div>
						</div>
						<div className="box box-sm">
							<p>
								GOCHIアカウントは、さまざまなオンラインサービスで利用できる<em className="text-bold">共通アカウント</em>です。本サービスでは、
								<em className="text-bold">ごちめし、さきめし、こどもごちめし、地域通貨など</em>でご利用いただけます。<a>GOCHIアカウントとは</a>
								<br />
								<br />
								また、GOCHIアカウントを登録されますと、マイページで<em className="text-bold">欲しいものリスト・購入履歴・フォロー・領収書発行・利用可能チケットの表示</em>
								など便利な機能をご利用いただけます。 年会費、登録料、更新料は一切かからず、無料でご利用いただけます。
							</p>
						</div>
						<div className="signup">
							<div className="title-signup">GOCHIアカウントを新規登録</div>
							<div className="policy-check-container max-w-100">
								<div className="gochi-policy-check">
									<div className="policy-text">
										<a href='https://gochimeshi.com/policy/terms/' target='_blank' rel='noopener noreferrer'>
											GOCHIプラットフォーム利用規約
										</a>
										<span>{this.props.t('pages.login.and')}</span>
										<a href='https://gochimeshi.com/policy/privacy/' target='_blank' rel='noopener noreferrer'>
											プライバシーポリシー
										</a>
									</div>
									<div className="policy-checkbox checkbox-custom">
										<label class="checkbox-custom">
											<input checked={this.state.policyCheck} onChange={e => this.setState({policyCheck: e.target.checked})} id="policy-check" type="checkbox" class="" name="question"/>
											<span class="checkmark"></span>
										</label>
										<span className="text-policy-checkbox">上記規約に同意する</span>
									</div>
								</div>
							</div>
							
							<div className="btn-group sns-btn-group">
								<a className={`btn btn-rect btn-sm btn-apple sns-btn ${!this.state.policyCheck ? 'opacity-70' : ''}`} onClick={() => this.handleLogin("APPLE")}>
									<img src="/assets/img/common/icon-apple.svg" className="sns-icon" loading="lazy" alt="" />
									<p className="text">Apple IDで新規登録する</p>
								</a>
								<a className={`btn btn-rect btn-sm btn-facebook sns-btn ${!this.state.policyCheck ? 'opacity-70' : ''}`} onClick={() => this.handleLogin("FACEBOOK")}>
									<img src="/assets/img/common/icon-facebook-white.svg" className="sns-icon" loading="lazy" alt="" />
									<p className="text">Facebook IDで新規登録する</p>
								</a>
								<a className={`btn btn-rect btn-sm btn-google sns-btn ${!this.state.policyCheck ? 'opacity-70' : ''}`} onClick={() => this.handleLogin("GOOGLE")}>
									<img src="/assets/img/common/icon-google.svg" className="sns-icon" loading="lazy" alt="" />
									<p className="text">Googleで新規登録する</p>
								</a>
								{
									this.state.menuId ? (
										<a className={`btn btn-rect btn-sm sns-btn ${!this.state.policyCheck ? 'opacity-70' : ''}`} onClick={() => this.handleLogin("FORM")}>
											<img src="/assets/img/common/icon-circle-user.svg" className="sns-icon" loading="lazy" alt="" />
											<p className="text">メールアドレスで新規登録する</p>
										</a>
									) : (
										<a className={`btn btn-rect btn-sm sns-btn ${!this.state.policyCheck ? 'opacity-70' : ''}`} onClick={() => this.handleLogin("FORM")}>
											<img src="/assets/img/common/icon-circle-user.svg" className="sns-icon" loading="lazy" alt="" />
											<p className="text">メールアドレスで新規登録する</p>
										</a>
									)
								}
							</div>
							{this.state.errorMessage &&
								<div className="text-danger mb-2 fs-14 font-weight-bold">{t('pages.login.'+this.state.errorMessage)}</div>
							}
							<div className="signup-link-group">
								<a className="link text-underline back-button" onClick={this.changeGuestLogin}>会員登録がお済みの方はこちら</a>
							</div>
						</div>
					</div>
				</main>


				<footer className="global-footer footer-mypage new-footer-login">
					<section className="global-footer-nav-area">
						<div className="container">
							<div className="section-header">
								<div className="heading-xs">
									<div className="title">
										<h1>
											<img
												src="/assets/img/common/logo_gochi_footer.svg"
												alt="ごちめし"
												loading="lazy"
											/>
										</h1>
									</div>
								</div>
							</div>
							<div className="section-body footer-nav">
								<ul className="footer-link-area">
									<li>
										<a href="https://gochimeshi.com/policy/">各種規約</a>
									</li>
									<li>
										<a href="https://gochimeshi.com/policy/privacy/">
											プライバシーポリシー
										</a>
									</li>
									<li>
										<a href="https://gochimeshi.com/policy/legal/">
											特定商取引法に基づく表記
										</a>
									</li>
									<li>
										<a href="https://user-guide.gochimeshi.com/">よくある質問</a>
									</li>
									<li>
										<a href="https://gochimeshi.com/info/">お問い合わせ</a>
									</li>
									<li>
										<a href="https://www.gigi.tokyo/">運営会社（Gigi株式会社）</a>
									</li>
								</ul>
							</div>
						</div>
					</section>

					<div className="copyright">
						<small>ごちめし®︎ さきめし®︎ びずめし®︎ GOCHI®︎は、Gigi株式会社の登録商標です<br className="only-sp" />©2020 Gigi Inc.</small>
					</div>
				</footer>
				<PopupRegisConfirm title={this.state.popupTitle} className="modal-group" showing={this.state.popupShowing} content={this.state.popupContent} handleConfirm={this.handlePopupConfirm} handleClose={this.handlePopupClose}/>

				{this.state.showNotificationPolicyPopup && (
					<div className="modal-group modal-policy" style={{visibility: "visible", opacity: 1, transition: "opacity .15s"}}>
						<div id="modalMask" className="modal-mask"></div>
						<div id="appleAccountCaution" className="modal modal-01 is-show modal-policy-content">
							<div className="modal-body">
								<div className="title-popup">GOCHIプラットフォーム利用規約・プライバシーポリシーにご同意ください　</div>
								<div className="content-popup">「上記規約に同意する」のチェックを入れた後で、任意の新規会員登録ボタンを押してください</div>
							</div>
							<div className="modal-footer">
								<button className="btn btn-rect" onClick={() => {this.setState({showNotificationPolicyPopup: false})}}>この画面を閉じる</button>
							</div>
						</div>
					</div>
				)}
			</div>
				);
		}
}

const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({
	showError: (title, content) => dispatch(showError(true, title, content)),
	saveUserInfo: (isLogined, isGuest, userInfo) => dispatch(saveUserInfo(isLogined, isGuest, userInfo)),
  showLoading: (showing) => dispatch(showLoading(showing)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Register));
