import React from 'react';
import { Modal } from 'react-bootstrap';
import Moment from 'react-moment';

function GochiUseConfirmModal(props) {
  const isShowPrice = props.showPrice !== 0;
  const isEducateSystem = props.siteType === "fukuoka" || props.siteType === "suita" || props.siteType === "nishiwaki";

  return (
    <Modal
      show={props.show}
      centered
      onHide={props.onCancel}
      className="modal-use clr-000000"
    >
      {/* <Modal.Header className="abc">
        <div className="header-cnt w-100">
          <Modal.Title className="fs-22 font-weight-bold">
            お支払いの確定
          </Modal.Title>
        </div>
      </Modal.Header> */}

      <Modal.Body className="font-gothic">
        <div className="fs-22 font-weight-bold align-center mt-4 mb-4">
          {isEducateSystem ? "利用金額の確定" : "お支払いの確定"}
        </div>

        <div className="fs-26 font-weight-bold text-break">
          {props.shopName}
        </div>

        <div className="mt-4 d-flex flex-column align-item-center text-center">
          <div className='mt-3 mb-2'>
            {isShowPrice ? (
              <>
                <span
                  className={
                    props.totalUseAmount < 1000000
                    ? 'fs-72 line-height-72 font-weight-bold'
                    : 'fs-48 line-height-48 font-weight-bold'
                  }
                >
                  {props.totalUseAmount && props.totalUseAmount.toLocaleString()}
                </span>
                <span className="fs-20 line-height-20">円</span>
              </>
            ) : (
              <div className="fs-20 fw-700">
                {props.menuName}
              </div>
            )}
          </div>
          <Moment
            className="fs-24 font-weight-medium"
            interval={1000}
            format="YYYY.MM.DD HH:mm:ss"
            local
          />
        </div>

        <button
          className="btn btn-use-55 bg-red mt-4 mb-4"
          onClick={props.onSubmit}
          disabled={props.disabledSubmitButton}
        >
          {isEducateSystem ? "確定する" : "お支払いを確定"}
        </button>
      </Modal.Body>
    </Modal>
  );
}

export default GochiUseConfirmModal;