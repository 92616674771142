import React, { useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { showError } from "../../../actions/action_error_alert";
import { showLoading } from "../../../actions/action_loading";
import queryString from "query-string";
import {addParamUrl} from '../../../utils';

function ForgotPasswordSuccess(props) {
  const history = useHistory();

  let url = window.location.search;
  let params = queryString.parse(url);
  var isSiteParam = params.isSite ? {name: 'isSite', value: params.isSite} : {};

  const onSubmit = () => {
    history.push(addParamUrl("/mypage", [isSiteParam]));
  };

  useEffect(() => {
    document.body.className = "auth signup";
  }, []);

  return (
    <div>
      <MetaTags>
        <title>完了｜パスワードを忘れた方｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="パスワードを忘れた方完了ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content="完了｜パスワードを忘れた方｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta name="twitter:description" content="パスワードを忘れた方完了ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:image" content={window.location.origin + "/assets/img/common/ogp.png"}  />
        <meta name="twitter:site" content="@gochi_meshi" />
        <meta name="twitter:creator" content="@gochi_meshi" />
        <meta name="twitter:image:width" content="300" />
        <meta name="twitter:image:height" content="300" />
        <meta property="og:site_name" content="完了｜パスワードを忘れた方｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:title" content="完了｜パスワードを忘れた方｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={window.location.origin + "/assets/img/common/ogp.png"} />
        <meta property="og:description" content="パスワードを忘れた方完了ページです。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>
      <header className="global-login-header">
        <div className="logo-nav">
          <h1 className="logo">
            <img
              src="/assets/img/common/logo-login.png"
              alt="さきめし ごちめし"
              loading="lazy"
            />
          </h1>
        </div>
      </header>
      <main>
        <div className="container">
          <div className="heading-lg">
            <div className="title">パスワードを忘れた方</div>
          </div>

          <div className="signup-input">
            <div className="signup-input-group">
              <div className="signup-input-body">
                <div className="complete-text text-bold">
                  パスワードの再設定が完了しました！
                </div>
                <figure className="illustration illustration-completed is-password">
                  <svg className="bg">
                    <use xlinkHref="/assets/img/common/icon.svg#bg-done"></use>
                  </svg>
                  <svg
                    className="img"
                    height="46.17"
                    viewBox="0 0 44.672 46.17"
                  >
                    <path
                      id="パス_3556"
                      data-name="パス 3556"
                      d="M1450.5,366.628l-.105-5.772a.863.863,0,0,0-.778-.828l-2.892-.231-.258-2.627a.971.971,0,0,0-.837-.838l-2.627-.257-.258-2.627a.955.955,0,0,0-.838-.827l-2.569-.212-.251-3.969a1.176,1.176,0,0,0-.294-.653L1436,344.994a16,16,0,1,0-6.811,6.744l15.721,15.72a.927.927,0,0,0,.652.236l4.009-.365a2.379,2.379,0,0,1,.427-.032.327.327,0,0,0,.263-.093C1450.495,366.967,1450.574,366.774,1450.5,366.628Zm-32.232-32.687a3.291,3.291,0,1,1,0-4.655A3.291,3.291,0,0,1,1418.265,333.941Z"
                      transform="translate(-1405.854 -321.527)"
                      fill="#f7db41"
                    ></path>
                  </svg>
                </figure>
              </div>
            </div>
            <div className="btn-group complete-btn-group">
              <a
                className="btn btn-rect btn-outline btn-fluid"
                href
                onClick={() => onSubmit()}
              >
                マイページに移動する
              </a>
            </div>
          </div>
        </div>
      </main>

      <footer className="global-footer">
        <section className="global-footer-nav-area">
          <div className="container">
            <div className="section-body footer-nav">
              <ul className="footer-link-area">
                <li>
                  <a href="https://gochimeshi.com/policy/">各種規約</a>
                </li>
                <li>
                  <a href="https://gochimeshi.com/policy/privacy/">
                    プライバシーポリシー
                  </a>
                </li>
                <li>
                  <a href="https://gochimeshi.com/policy/legal/">
                    特定商取引法に基づく表記
                  </a>
                </li>
                <li>
                  <a href="https://user-guide.gochimeshi.com/">よくある質問</a>
                </li>
                <li>
                  <a href="https://gochimeshi.com/info/">お問い合わせ</a>
                </li>
                <li>
                  <a href="https://www.gigi.tokyo/">運営会社（Gigi株式会社）</a>
                </li>
              </ul>
              <ul className="footer-share-area">
                <li>
                  <a
                    href="https://twitter.com/gochi_meshi"
                    rel="nofollow"
                    target="_blank"
                  >
                    <img
                      src="/assets/img/common/icon-twitter-white.svg"
                      loading="lazy"
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/gochimeshi.official/"
                    rel="nofollow"
                    target="_blank"
                  >
                    <img
                      src="/assets/img/common/icon-facebook-white.svg"
                      loading="lazy"
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/gochimeshi_staff/?hl=ja">
                    <img
                      src="/assets/img/common/icon-instagram-white.svg"
                      loading="lazy"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <div className="copyright">
          <small>
            ごちめし®︎ さきめし®︎ びずめし®︎ GOCHI®︎は、Gigi株式会社の登録商標です
            <br />
            ©2020 Gigi Inc.
          </small>
        </div>
      </footer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content) =>
    dispatch(showError(showing, title, content)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ForgotPasswordSuccess));
