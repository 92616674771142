import React, { Component } from "react";   
import {Redirect} from 'react-router-dom';  
import {connect} from 'react-redux'; 
import { withTranslation } from 'react-i18next'; 
import {log, addParamUrl} from '../../../utils'; 
import PaymentService from "../../../api/services/PaymentService";
import {showLoading} from "../../../actions/action_loading";
import queryString from 'query-string'
// import Header from "../../layout/header/Header";
import { Modal } from "react-bootstrap";
import PaymentCardBrands from "../../common/PaymentCardBrands";

var stripe = require('stripe-client')(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
class PaymentInput extends Component {
	constructor(){
	    super();
	    let newDate = new Date() 
		let curMonth = newDate.getMonth() + 1;
		let curYear = newDate.getFullYear(); 
	    this.state = { 
            headerTitle: "カード情報登録",
            cardHolder: "",
            ccnum: "", 
            expiredMonth: curMonth,
            expiredYear: curYear,
            cvc: "",
            redirectToReferrer: false,
            errorMessage: "",
            menuId: null,
            currentMonth: "",
			currentYear: "",
			src: "",
            paramShashoku: '',
			shopId: null,
			isSite: ''
        } 

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this); 
        this.getCurrentMonYear = this.getCurrentMonYear.bind(this);
		this.handleBack = this.handleBack.bind(this);
	}

	static contextTypes = {
		router: () => true, // replace with PropTypes.object if you use them
	}

	handleChange(e){
	    this.setState({
	      	[e.target.name] : e.target.value
	    }) 
	} 

	async onCreateToken(cardElement) {  
		const { t } = this.props; 
		this.props.showLoading(true);
	    try {
			var token = null;
			if (process.env.REACT_APP_VERITRANS_ENABLE === 'true') {
				// Get token from Veritrans
				const response = await fetch('https://api.veritrans.co.jp/4gtoken', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json; charset=utf-8'
					},
					body: JSON.stringify({
						token_api_key: process.env.REACT_APP_VERITRANS_TOKEN_API_KEY,
						card_number: cardElement.card.number,
						card_expire: String(cardElement.card.exp_month).padStart(2, '0') + '/' + String(cardElement.card.exp_year).substring(2),
						security_code: cardElement.card.cvc,
						cardholder_name: cardElement.card.name,
						lang: 'en'
					})
				});

				if (response.status !== 200) {
					this.props.showLoading(false);
					return this.setState({
						errorMessage: t('pages.invite.Card currently unavailable Failed to register')
					});
				}

				const data = await response.json();

				token = data.token;
			} else {
				// Get token from Stripe
				const response = await stripe.createToken(cardElement).catch(async () => { 
					this.props.showLoading(false);
					return this.setState({
						errorMessage: t('pages.invite.Card currently unavailable Failed to register')
					});
				});

				var data = await response.text();
				data = JSON.parse(data);

				if (data.error) { 
					this.props.showLoading(false);
					return this.setState({
						errorMessage: t('pages.invite.Card currently unavailable Failed to register')
					});
				}

				if (data.id) {
					token = data.id;
				}
			}

	      	var userId = null;

	      	if (this.props.userInfo.userId) {
	      		userId = this.props.userInfo.userId;
	      	}
	      	if (token && userId) {
		        const result = await PaymentService.registerCardtoken(
		          	userId,
		          	token
		        );

		        log(result);
		        if (result.status === 200) {
			        this.setState({
				  		errorMessage: "",
				  		redirectToReferrer: true,
				  	})
				} else {
					this.setState({
						errorMessage: t('pages.invite.Failed to register card')
					});
				}
	      	} else { 
	        	this.setState({
			  		errorMessage: t('pages.invite.User information not be obtained Failed to register')
			  	})
	      	} 

			this.props.showLoading(false);
	    } catch (error) {
	      	log(error); 
	      	this.setState({
		  		errorMessage: t('pages.invite.Failed to register card')
		  	});

			this.props.showLoading(false);
	    }  
	    this.props.showLoading(false);
	}

	handleSubmit(e) { 
		e.preventDefault();

		var cardElement = {
			card: {
				name: e.target.elements.cardHolder.value,
				number: e.target.elements.ccnum.value,
				exp_month: e.target.elements.expiredMonth.value,
				exp_year: e.target.elements.expiredYear.value,
				cvc: e.target.elements.cccsc.value
			}
		};

     	this.onCreateToken(cardElement); 
	} 

	getCurrentMonYear(){
		let newDate = new Date() 
		let month = newDate.getMonth() + 1;
		let year = newDate.getFullYear(); 
		this.setState({
			currentMonth: month,
			currentYear: year
		})
	}
 

	UNSAFE_componentWillMount(){   
		let url = window.location.search;
		let params = queryString.parse(url);
		var menuId = params.id; 
		var shopId = params.shopId;
        var src = params.src;
        const fromShashoku = params.fromShashoku;
		const referer = params.referer;
		const isSite = params.isSite;

		if (src) {
			this.setState({
				src: src
			});
		}

		if (isSite) {
			this.setState({
				isSite: isSite
			});
		}

		this.setState({
			menuId: menuId,
			referer,
        });

		if (shopId) {
			this.setState({
				shopId: shopId
			});
		}
        
        if (fromShashoku) {
            this.setState({
				paramShashoku: `&fromShashoku=${encodeURIComponent(fromShashoku)}`
			});
        }

		this.getCurrentMonYear();
	}

	handleBack(){
		window.history.back();
	}
	
    render() {
    	var userInfo = this.props.userInfo;
		var isSite = this.state.isSite;
		var isSiteParam = isSite ? {name: 'isSite', value: isSite} : {};

    	var linkLogin = addParamUrl("/menu?id=" + this.state.menuId, [isSiteParam]);
        var srcParam = this.state.src ? '&src=' + this.state.src : '';
		const refererParam = this.state.referer ? `&referer=${encodeURIComponent(this.state.referer)}` : '';
		srcParam += refererParam;

        let isLoggedIn = false;
    	if (userInfo) { 
            if (userInfo.userId) {
                isLoggedIn = true;
            }
    		if (userInfo.isLogined === false) {
				if (!this.state.menuId && this.state.shopId) {
					window.history.pushState(null, null, addParamUrl('/payment-input?shopId=' + this.state.shopId, [isSiteParam]));
	            	return <Redirect to={addParamUrl('/login', [isSiteParam])} />
				} else {
					window.history.pushState(null, null, addParamUrl('/payment-input?id=' + this.state.menuId+ srcParam + this.state.paramShashoku, [isSiteParam]));
	            	return <Redirect to={linkLogin} />
				}
    			
	        }
			if (userInfo.isGuest) {
				if (!this.state.menuId && this.state.shopId) {
					window.history.pushState(null, null, addParamUrl('/payment-input?shopId=' + this.state.shopId, [isSiteParam]));
	            	return <Redirect to={addParamUrl('/login', [isSiteParam])} />
				} else {
					window.history.pushState(null, null, addParamUrl('/payment-input?id=' + this.state.menuId+ srcParam + this.state.paramShashoku, [isSiteParam]));
					return <Redirect to={addParamUrl("/invite?id=" + this.state.menuId, [isSiteParam])} />
				}
			}
		}else{
			if (!this.state.menuId && this.state.shopId) {
				window.history.pushState(null, null, addParamUrl('/payment-input?shopId=' + this.state.shopId, [isSiteParam]));
	            return <Redirect to={addParamUrl('/login', [isSiteParam])} />
			} else {
				window.history.pushState(null, null, addParamUrl('/payment-input?id=' + this.state.menuId+ srcParam + this.state.paramShashoku, [isSiteParam]));
				return <Redirect to={linkLogin} />
			}
    	}

    	let newDate = new Date();
		let curYear = newDate.getFullYear(); 
    	let months = [];
    	for(var m = 1; m < 13; m ++) { 
    		months.push(<option key={m} value={m} >{("0" + m).slice(-2)}</option>);
    	}

    	let years = [];
    	// Test Veritrans
    	// years.push(<option key="1998" value="1998" >1998</option>);
    	// years.push(<option key="1999" value="1999" >1999</option>);
    	for(var y = curYear; y < curYear + 10; y ++) { 
    		years.push(<option key={y} value={y} >{y}</option>);
    	}

    	var link = addParamUrl("/payment-list?id=" + this.state.menuId+ srcParam + this.state.paramShashoku, [isSiteParam]); 
		const redirectToReferrer = this.state.redirectToReferrer;
        if (redirectToReferrer === true) {
			if (!this.state.menuId && this.state.shopId) {
				window.history.pushState(null, null, addParamUrl('/payment-input?shopId=' + this.state.shopId, [isSiteParam]));
				let linkPaymentList = addParamUrl('/payment-list?shopId=' +  this.state.shopId + srcParam, [isSiteParam]);
	            return <Redirect to={linkPaymentList} />
			} else {
				window.history.pushState(null, null, addParamUrl('/payment-input?id=' + this.state.menuId+ srcParam + this.state.paramShashoku, [isSiteParam]));
				return <Redirect to={link} />
			}
        }
    	const { t } = this.props;  
        return (  
        	<div className="content">
				<Modal
					className="modal-list-payments"
					size="sm"
					show
					onHide={this.handleBack}
				>
					<Modal.Body>
						{/* <Header title=
							{t('pages.invite.Register card information')}
							isLoggedIn={isLoggedIn}
							t={t} /> */}
						<div className="form-group lst-payments mt-2">
							<div className="title-lst-shop align-center">
								<button type="button" className="btn icon-btn-back pl-0" onClick={this.handleBack}><i className="arrow left width-15"></i></button>
								{t('pages.invite.Register card information')}
							</div>

							<div className="form-block mt-4">
								<form 
									id="formLogin" 
									action="#" 
									method="post"  
									onSubmit={this.handleSubmit}
								> 
									<div className="text-guide">
										<p>
											{t('pages.invite.Add credit or debit card')}
										</p>
										<p className="fs-16 clr-000000">
											<b>{t('pages.invite.Enter credit card information')}</b>
										</p>
									</div>

									{this.state.errorMessage &&
										<div className="fs-14 font-weight-bold text-danger space-pre-line box-warning-message">{this.state.errorMessage}</div>
									}

									<div className="form-group">
										<label className="form-label fs-16 clr-000000">{t('pages.invite.Card information')}</label>
										<div className="">
											<input className="form-control fs-14" 
													name="cardHolder" 
													placeholder={t('pages.invite.Card holder')} 
													value={this.state.cardHolder} 
													onChange={this.handleChange}
													x-autocompletetype="cc-name"
											/> 
										</div> 
										<div className="mt-2">
											<input className="form-control fs-14" 
													name="ccnum" 
													placeholder={t('pages.invite.Card number')} 
													value={this.state.cardNumber} 
													onChange={this.handleChange} 
													x-autocompletetype="cc-number"
											/> 
										</div> 
										<div className="mt-2">
											<PaymentCardBrands />
										</div>
									</div>

									<div className="form-group">
										<label className="form-label fs-16 clr-000000">{t('pages.invite.expiredDate')}</label>
										<div className="row no-gutters d-flex">
											<div className="col-5">
												<div className="select-custom">
													<select className="form-control" value={this.state.expiredMonth} onChange={this.handleChange} name="expiredMonth" style={{height:"35px"}}>
														{months}
													</select>
												</div>
											</div>
											<div className="col-1 align-center">{t('other.month')}</div>

											<div className="col-5 ml-auto">
												<div className="select-custom">
													<select className="form-control" value={this.state.expiredYear} onChange={this.handleChange} name="expiredYear" style={{height:"35px"}}>
														{years}
													</select>
												</div> 
											</div>
											<div className="col-1 align-center">{t('other.year')}</div>
										</div>
									</div> 

									<div className="form-group">
										<label className="form-label fs-16 clr-000000">{t('pages.invite.CVC')}</label>
										<div className="">
											<input className="form-control" 
													placeholder={t('pages.invite.CVC placeholder')} 
													onChange={this.handleChange}
													x-autocompletetype="cc-csc"
													name="cccsc"
													maxLength={4}
											/> 
										</div>  
									</div> 

									<div className="form-group text-center mt-3">
										<button type="submit" className="btn btn-green-38" >
											{t('pages.invite.Add credit card')}
										</button>
									</div>
								</form>
							</div>
						</div>
					</Modal.Body>
				</Modal> 
			</div>  
        ); 	
    }
}
 
const mapStateToProps = state => ({
	...state
});

const mapDispatchToProps = dispatch => ({   
    showLoading: (showing) => dispatch(showLoading(showing)),
});

export default connect(mapStateToProps, mapDispatchToProps) (withTranslation()(PaymentInput)); 