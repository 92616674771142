import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { showError } from "../../../actions/action_error_alert";
import { showLoading } from "../../../actions/action_loading";
import PaymentService from "../../../api/services/PaymentService";
import { log } from "../../../utils";
import PaymentCardBrands from "../../common/PaymentCardBrands";
import ModalCloseButton from "./ModalCloseButton";

const stripe = require('stripe-client')(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

let newDate = new Date();
const currentYear = newDate.getFullYear();
const currentMonth = newDate.getMonth() + 1;
const years = [];
for (let y = currentYear; y < currentYear + 10; y++) {
  years.push(y);
}
const months = [];
for (let m = 1; m <= 12; m++) {
  months.push(m);
}

function AddPaymentCardModal({
  show,
  handleRegisterSuccess,
  handleClose,
  showLoading,
  showError,
  userInfo
}) {
  const [cardHolder, setCardHolder] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiredMonth, setExpiredMonth] = useState(currentMonth);
  const [expiredYear, setExpiredYear] = useState(currentYear);
  const [securityCode, setSecurityCode] = useState('');

  const onChangeCardNumber = (event) => {
    // Remove all non-digit characters
    setCardNumber(event.target.value.replace(/\D/g, ''));
  };

  const onChangeSecurityCode = (event) => {
    // Remove all non-digit characters
    setSecurityCode(event.target.value.replace(/\D/g, ''));
  }

  const createCardToken = async () => {
    let token = null;
    if (process.env.REACT_APP_VERITRANS_ENABLE === 'true') {
      // Get token from Veritrans
      try {
        const response = await fetch('https://api.veritrans.co.jp/4gtoken', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({
            token_api_key: process.env.REACT_APP_VERITRANS_TOKEN_API_KEY,
            card_number: cardNumber,
            card_expire: String(expiredMonth).padStart(2, '0') + '/' + String(expiredYear).substring(2),
            security_code: securityCode,
            cardholder_name: cardHolder,
            lang: 'en'
          })
        });

        if (response.status !== 200) {
          return null;
        }

        const data = await response.json();
        token = data.token;
      } catch (e) {
        log('Create Veritrans token failed');
        log(e);
      }
    } else {
      // Get token from Stripe
      const cardElement = {
        card: {
          name: cardHolder,
          number: cardNumber,
          exp_month: expiredMonth,
          exp_year: expiredYear,
          cvc: securityCode
        }
      };
      try {
        const response = await stripe.createToken(cardElement);

        let data = await response.text();
        data = JSON.parse(data);

        if (data.error) {
          return null;
        }

        if (data.id) {
          token = data.id;
        }
      } catch (e) {
        log('Create Stripe token failed');
        log(e);
      }
    }
    return token;
  }

  const registerCard = async () => {
    showLoading(true);
    const token = await createCardToken();
    if (token) {
      const response = await PaymentService.registerCardtoken(userInfo.userId, token);
      if (response.status === 200) {
        // await reload();
        handleRegisterSuccess();
        showLoading(false);
        handleClose();
        return;
      } else {
        showError(true, 'カード情報の登録に失敗しました。', '');
      }
    } else {
      showError(true, '登録に失敗しました。\nカード情報をご確認ください。または、別のカードでお試しください。', '');
    }
    showLoading(false);
  };

  const onShow = () => {
    log('AddPaymentCardModal onShow');
    setCardHolder('');
    setCardNumber('');
    setExpiredMonth(currentMonth);
    setExpiredYear(currentYear);
    setSecurityCode('');
  }

  return (
    <Modal
      size="sm"
      className="no-header-modal donate-payment"
      scrollable={false}
      animation={false}
      show={show}
      onShow={onShow}
      onHide={() => {}}
    >
      <Modal.Body className="pb-5">
        <ModalCloseButton onClick={handleClose} />

        <div className="block-title-lg font-family-rounded">
          クレジットカード登録 <span className="color-ED808C">*</span>
        </div>
        <div className="fs-14px fw-400 color-636363 mt-2">クレジットカードまたはデビットカードを追加</div>

        <div className="fs-20px fw-800 font-family-rounded color-000000 mt-4">クレジットカード情報を入力</div>
        <div className="fs-18px fw-800 font-family-rounded color-000000 mt-3">カード情報</div>
        <div className="fs-14px fw-700 mt-2">カード名義人（半角ローマ字）</div>
        <div className="mt-1">
          <input
            className="form-control"
            placeholder="例）MESHIKO GOCHI"
            type="text"
            autoComplete="cc-name"
            autoCorrect="off"
            spellCheck="false"
            value={cardHolder}
            onChange={(event) => setCardHolder(event.target.value)}
          />
        </div>
        <div className="fs-14px fw-700 mt-3">カード番号（半角数字）</div>
        <div className="mt-1">
          <input
            className="form-control"
            placeholder="例）0000 1111 2222 3333"
            type="tel"
            inputMode="numeric"
            autoComplete="cc-number"
            autoCorrect="off"
            spellCheck="false"
            value={cardNumber}
            onChange={onChangeCardNumber}
          />
        </div>
        <PaymentCardBrands className="mt-2" />

        <div className="fs-18px fw-800 font-family-rounded color-000000 mt-4">有効期限</div>
        <div className="d-flex mt-2">
          <div>
            <div>月</div>
            <div className="mt-1 mr-5">
              <select
                className="select-date"
                value={expiredMonth}
                onChange={e => setExpiredMonth(parseInt(e.target.value))}
              >
                {months.map(m => (
                  <option key={m} value={m}>{('0' + m).slice(-2)}</option>
                ))}
              </select>
            </div>
          </div>
          <div>
            <div>年</div>
            <div className="mt-1">
              <select
                className="select-date"
                value={expiredYear}
                onChange={e => setExpiredYear(parseInt(e.target.value))}
              >{years.map(y => (
                <option key={y} value={y}>{y}</option>
              ))}</select>
            </div>
          </div>
        </div>

        <div className="fs-18px fw-800 font-family-rounded mt-4">セキュリティコード</div>
        <div className="fs-14px fw-700 mt-2">カード背面4桁もしくは3桁の番号</div>
        <div className="mt-1">
          <input
            className="form-control input-security-code"
            placeholder="例）0123"
            type="tel"
            inputMode="numeric"
            autoComplete="cc-csc"
            autoCorrect="off"
            spellCheck="false"
            value={securityCode}
            onChange={onChangeSecurityCode}
          />
        </div>

        <div className="mt-4">
          <button className="btn-primary btn-size-md w-100" onClick={() => registerCard()}>
            登録する
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  paymentMethod: state.paymentMethod
});

const mapDispatchToProps = dispatch => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content, buttonName, gotoUrl) => dispatch(showError(showing, title, content, buttonName, gotoUrl))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentCardModal);
