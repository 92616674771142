import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import queryString from 'query-string';
import * as constants from "../../../constants";
import { showLoading } from "../../../actions/action_loading";
import { confirmDonate } from "../../../actions/GochiKid/action_donate";
import { GOCHI_KID_PATH, PAYMENT_TYPE_STRIPE, PAYMENT_TYPE_VERITRANS } from "../../../constants";
import Payment from "../common/Payment";
import RadioInput from "../common/RadioInput";
import Header from "../layout/Header";
import DonateService from "../../../api/services/GochiKid/DonateService";
import { log } from "../../../utils";
import ErrorAlert from "../common/ErrorAlert";
import ShopService from "../../../api/services/ShopService";
import { showError } from "../../../actions/action_error_alert";
import ActivityInfo from "../common/ActivityInfo";
import PaypayAd from "../../common/PaypayAd";
import imageHeader from "../../../assets/img/icons/image-header-campaign-donate.png";
import image1PC from "../../../assets/img/icons/image-campaign-donate-1-pc.png";
import image1SP from "../../../assets/img/icons/image-campaign-donate-1-sp.png";
import imageHeart from "../../../assets/img/icons/image-campaign-donate-heart.png";
import imageYen from "../../../assets/img/icons/image-campaign-donate-yen.png";
import imageHouse from "../../../assets/img/icons/image-campaign-donate-house.png";
import imageOpinion1 from "../../../assets/img/icons/image-opinion-campaign-donate-1.png";
import imageOpinion2 from "../../../assets/img/icons/image-opinion-campaign-donate-2.png";
import imageOpinion3 from "../../../assets/img/icons/image-opinion-campaign-donate-3.png";
import imageDiagramPC from "../../../assets/img/icons/image-diagram-campaign-donate-pc.png";
import imageDiagramSP from "../../../assets/img/icons/image-diagram-campaign-donate-sp.png";

const destinationChoicePath = `${constants.GOCHI_KID_PATH}/support/choice`;

function CampaignDonateInput({
  userInfo,
  paymentDefaultCard,
  showLoading,
  showError,
  confirmDonate,
  oldInput
}) {
  const history = useHistory();
  const match = useRouteMatch();
  // const destination = match.params.destination;
  const destination = 'national';

  const [loadingPayment, setLoadingPayment] = useState(false);
  const [loadingAvailableBillingGroup, setLoadingAvailableBillingGroup] = useState(false);
  const [loadingActivityInfo, setLoadingActivityInfo] = useState(false);
  const [activityInfo, setActivityInfo] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [paymentType, setPaymentType] = useState(null);
  const [isSubscription, setIsSubscription] = useState(false);
  const [billingGroupId, setBillingGroupId] = useState(null);
  const [donateAmount, setDonateAmount] = useState(null);
  const [donateMessage, setDonateMessage] = useState('');
  const [userEmailError, setUserEmailError] = useState('');
  const [userNameError, setUserNameError] = useState('');
  const [paymentTypeError, setPaymentTypeError] = useState('');
  const [donateAmountError, setDonateAmountError] = useState('');
  const [donateMessageError, setDonateMessageError] = useState('');

  const formTitleRef = useRef(null);
  const paymentTypeRef = useRef(null);
  const donateAmountRef = useRef(null);
  const userInfoRef = useRef(null);

  // const isSubscriptionDisabled = paymentType !== PAYMENT_TYPE_VERITRANS || !userInfo?.userId || (paymentDefaultCard && !billingGroupId);
  const isSubscriptionDisabled = true;

  const getDestinationText = () => {
    switch (destination) {
      case 'region':
        return '都道府県';
      case 'activity':
        return '自治体/活動';
      default:
        return '全国';
    }
  };

  const onChangeDonateAmount = (event) => {
    // Remove all non-digit characters
    setDonateAmount(parseInt(event.target.value.replace(/\D/g, '')) || null);
    // let pattern = /^[0-9]+$/;
    // if (pattern.test(event.target.value)) {
    //   setDonateAmount(parseInt(event.target.value));
    // } else {
    //   setDonateAmount(null);
    // }
  };

  const onChangeDonateMessage = (event) => {
    setDonateMessage(event.target.value);
    setDonateMessageError(event.target.value?.length >= 200 ? '200文字以内ご入力ください。' : '');
  };

  const validateEmail = (email) => {
    return constants.EMAIL_REGEX.test(String(email).toLowerCase());
  };

  const validate = () => {
    let isValid = true;
    let firstErrorRef = null;

    if (donateAmount && donateAmount > 0) {
      if (donateAmount <= 100000000) {
        setDonateAmountError('');
      } else {
        setDonateAmountError('一億円までご入力ください。');
        isValid = false;
      }
    } else {
      setDonateAmountError('数字を入力してください');
      isValid = false;
    }
    if (!isValid && !firstErrorRef) {
      firstErrorRef = donateAmountRef;
    }

    if (userInfo?.userId) {
      setPaymentTypeError('');
      if (paymentType === PAYMENT_TYPE_STRIPE || paymentType === PAYMENT_TYPE_VERITRANS) {
        if (!paymentDefaultCard) {
          setPaymentTypeError('お支払い方法を選択してください');
          isValid = false;
        }
      }
      if (!isValid && !firstErrorRef) {
        firstErrorRef = paymentTypeRef;
      }
    } else {
      if (userEmail?.trim?.()) {
        if (validateEmail(userEmail) && userEmail.length <= 255) {
          setUserEmailError('');
        } else {
          setUserEmailError('無効なメール形式');
          isValid = false;
        }
      } else {
        setUserEmailError('メールアドレスを入力してください。');
        isValid = false;
      }

      if (userName?.trim?.()) {
        if (userName.length <= 50) {
          setUserNameError('');
        } else {
          setUserNameError('お名前を50文字以内で入力してください');
          isValid = false;
        }
      } else {
        setUserNameError('お名前を入力してください。')
        isValid = false;
      }
      if (!isValid && !firstErrorRef) {
        firstErrorRef = userInfoRef;
      }
    }

    if (firstErrorRef) {
      // firstErrorRef.current.scrollIntoView();
      // window.scrollTo(0, 0);
      formTitleRef.current.scrollIntoView();
    }

    return isValid;
  };

  const gotoConfirm = () => {
    if (!validate()) {
      return;
    }

    let data = {
      activityInfo,
      userEmail: userEmail?.trim() || '',
      userName: userName?.trim() || '',
      paymentType,
      isSubscription: !isSubscriptionDisabled && isSubscription,
      billingGroupId,
      donateAmount,
      donateMessage: donateMessage?.trim() || ''
    };

    confirmDonate(data);
    history.push(
      `${GOCHI_KID_PATH}/support/choice/${destination}/confirm${window.location.search}`,
      { previousPath: window.location.pathname + window.location.search }
    );
  };

  const loadAvailableBillingGroup = async () => {
    if (!userInfo?.userId || !paymentDefaultCard || loadingAvailableBillingGroup || billingGroupId) {
      return;
    }
    setLoadingAvailableBillingGroup(true);
    const response = await DonateService.getAvailableBillingGroup();
    if (response.status === 200) {
      setBillingGroupId(response.data[0]);
    }
    setLoadingAvailableBillingGroup(false);
  }

  const loadShopInfo = async (shopId) => {
    setLoadingActivityInfo(true);
    const response = await ShopService.getDetailShop(shopId);
    if (response.status === 200) {
      setActivityInfo(response.data);
    } else {
      // TODO: error message
      showError(true, "インターネット接続には問題が発生しました。後でもう一度試してください。", "");
    }
    setLoadingActivityInfo(false);
  };

  const loadGroupInfo = async (groupId) => {
    setLoadingActivityInfo(true);
    const response = await ShopService.getDetailGroup(groupId);
    if (response.status === 200) {
      setActivityInfo(response.data);
    } else {
      // TODO: error message
      showError(true, "インターネット接続には問題が発生しました。後でもう一度試してください。", "");
    }
    setLoadingActivityInfo(false);
  };

  useEffect(() => {
    loadAvailableBillingGroup();
  }, [paymentDefaultCard]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    showLoading(loadingPayment || loadingAvailableBillingGroup || loadingActivityInfo);
  }, [loadingPayment, loadingAvailableBillingGroup, loadingActivityInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    log('CampaignDonateInput componentDidMount');

    let needScrollToForm = false;
    if (oldInput.donateAmount) {
      setDonateAmount(oldInput.donateAmount);
      setPaymentType(oldInput.paymentType);
      setIsSubscription(oldInput.isSubscription);
      setUserEmail(oldInput.userEmail);
      setUserName(oldInput.userName);
      setDonateMessage(oldInput.donateMessage);
      needScrollToForm = true;
    }

    // get shop/group ID from url params and validate
    const params = queryString.parse(window.location.search);
    switch (destination) {
      case 'national':
        // Donate national do not require shopId/groupId
        confirmDonate({
          destination
        });
        break;
      case 'region':
        if (params.donateGroupId && parseInt(params.donateGroupId, 10) > 0) {
          confirmDonate({
            destination,
            groupId: parseInt(params.donateGroupId, 10)
          });
          loadGroupInfo(parseInt(params.donateGroupId, 10));
        } else {
          history.replace(destinationChoicePath + '?skipPolicyCheck=true');
          return;
        }
        break;
      case 'activity':
        if (params.donateShopId && parseInt(params.donateShopId, 10) > 0) {
          confirmDonate({
            destination,
            shopId: parseInt(params.donateShopId, 10)
          });
          loadShopInfo(parseInt(params.donateShopId, 10));
        } else if (params.donateGroupId && parseInt(params.donateGroupId, 10) > 0) {
          confirmDonate({
            destination,
            groupId: parseInt(params.donateGroupId, 10)
          });
          loadGroupInfo(parseInt(params.donateGroupId, 10));
        } else {
          history.replace(destinationChoicePath + '?skipPolicyCheck=true');
          return;
        }
        break;
      default:
        history.replace(destinationChoicePath + '?skipPolicyCheck=true');
        return;
    }

    // window.scrollTo(0, 0);
    if (needScrollToForm) {
      formTitleRef.current.scrollIntoView();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header />

      <main className="page-wrapper">
        <div className="campaign-donate-payment">
          <div><img src={imageHeader} alt="image-header" className="image-header" /></div>

          <div className="mx-3">
            <div className="campaign-donate-title-block">
              <div className="campaign-donate-title">
                <div className="campaign-donate-title-content">
                  <div className="title-1">
                    <span className="text-pink">夏休み</span>
                    <span>の</span>
                    <span className="text-pink">子ども</span>
                    <span>たちに起こる</span>
                    <span className="text-pink">給食問題</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="campaign-donate-content-box-1">
              <div className="campaign-donate-header-1">
                <img src={image1PC} alt="image-campaign-donate-1" className="image-campaign-donate-1-pc" />
                <img src={image1SP} alt="image-campaign-donate-1" className="image-campaign-donate-1-sp" />
              </div>

              <div className="campaign-donate-content-1">
                <div className="image-box image-box-sp">
                  <img src={imageHeart} alt="image-heart" />
                </div>

                <div className="text-box">
                  <div className="text-title">
                    <span className="parenthesis">「</span>
                    <span>栄養面</span>
                    <span className="parenthesis">」</span>
                  </div>

                  <div className="fs-18 fw-700">
                    学校給食では、バランスの取れた食事が提供されるので、子どもたちが栄養面で心配することは普段は少ないです。
                    <br />
                    ですが、夏休みに入ると、家庭での食事が中心となり、食事の偏りや栄養不足が懸念されます。特に、共働き家庭やひとり親家庭では、忙しさや時間の制約から簡単な食事に頼りがちになり、必要な栄養素が十分に摂取できない場合が多いです。
                    <br />
                    これにより、子供たちの健康や成長に悪影響を及ぼす可能性があります。
                  </div>
                </div>

                <div className="image-box image-box-pc">
                  <img src={imageHeart} alt="image-heart" />
                </div>

                <div className="image-box">
                  <img src={imageYen} alt="image-yen" />
                </div>

                <div className="text-box">
                  <div className="text-title">
                    <span className="parenthesis">「</span>
                    <span>金銭面</span>
                    <span className="parenthesis">」</span>
                  </div>

                  <div className="fs-18 fw-700">
                    生活がぎりぎりの家庭では、夏休み期間中の食費が大きな負担となります。特に近年の物価高騰は、この負担をさらに増大させています。
                    <br />
                    給食が提供されないことで、家庭での食事準備が必要となり、その分の食費が家庭の経済状況に重くのしかかります。このような状況は、家庭の財政状況をさらに圧迫し、子どもたちの生活の質に影響を与えることになります。
                  </div>
                </div>

                <div className="image-box image-box-sp">
                  <img src={imageHouse} alt="image-house" />
                </div>

                <div className="text-box">
                  <div className="text-title">
                    <span className="parenthesis">「</span>
                    <span>居場所の確保</span>
                    <span className="parenthesis">」</span>
                  </div>

                  <div className="fs-18 fw-700">
                    夏休み期間中、子どもたちは自宅で過ごす時間が増えますが、特に暑い日にはエアコンを使用しないと熱中症のリスクが高まります。しかし、エアコンの使用には電気代がかかり、経済的に余裕のない家庭では十分に使えない場合もあります。このため、涼しい場所で過ごせる環境の確保が難しくなり、子どもたちの健康や安全が脅かされる可能性があります。
                  </div>
                </div>

                <div className="image-box image-box-pc">
                  <img src={imageHouse} alt="image-house" />
                </div>
              </div>

              <div className="campaign-donate-footer-1 font-family-rounded">
                <span>これらの問題に対して、</span>
                <span className="green-text">地域や社会全体が協力</span>
                <span>して、</span>
                <br />
                <span className="green-text">夏休み期間中</span>
                <span>の子どもたちの</span>
                <span className="green-text">栄養</span>
                <span>、</span>
                <span className="green-text">経済的負担</span>
                <span>、</span>
                <span className="green-text">居場所の確保</span>
                <span>を</span>
                <span className="green-text">サポート</span>
                <span>する</span>
                <br />
                取り組みが求められています。
              </div>
            </div>

            <div className="campaign-donate-title-block">
              <div className="campaign-donate-title">
                <div className="campaign-donate-title-content">
                  <div className="title-2">
                    <span className="text-pink">給食問題</span>
                    <span>に関する世間の声</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="campaign-donate-content-box-2">
              <div className="opinion-box border-dashed-box">
                <div className="image-box"><img src={imageOpinion1} alt="image-opinion-1" className="image-opinion"/></div>
                <div className="fs-18 fw-700">
                  夏休みは給食がないので、子どもの食事に困っています。毎日の食費が増えて家計が厳しいです。食材の価格も上がっているので、かなり無理して節約しないといけない状況です。
                </div>
              </div>

              <div className="opinion-box border-dashed-box my-4">
                <div className="image-box"><img src={imageOpinion2} alt="image-opinion-2" className="image-opinion"/></div>
                <div className="fs-18 fw-700">
                  学校が休みになると、子どもたちに栄養バランスの取れた食事を提供するのが難しいです。毎食手作りするのは負担が大きく、どうしても簡単なものばかりになってしまいます。
                </div>
              </div>

              <div className="opinion-box border-dashed-box">
                <div className="image-box"><img src={imageOpinion3} alt="image-opinion-3" className="image-opinion"/></div>
                <div className="fs-18 fw-700">
                  夏休みの食事をどうするか悩んでいます。食事の提供をいただけると本当に助かります。サポートがなければ、食事の量と質が下がってしまい、子どもたちの健康を守るのが難しくなってしまいます。
                </div>
              </div>
            </div>

            <div className="campaign-donate-title-block">
              <div className="campaign-donate-title">
                <div className="campaign-donate-title-content">
                  <div className="title-3">
                    <span>こどもごちめし</span>
                    <br />
                    <span className="text-pink">
                      <span>夏休み家庭応援</span>
                      <span>キャンペーン概要</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="campaign-donate-content-box-3 border-dashed-box font-family-rounded">
              <div className="text-box">
                <span>夏休み期間中、こどもごちめしを活用し</span>
                <span className="text-pink">1日1,000円 月1回までの食事をこどもたちに提供</span>
                <span>します。生活保護家庭のお子様は毎日ご利用が可能です。</span>
              </div>
            </div>

            <div className="campaign-donate-title-block">
              <div className="campaign-donate-title">
                <div className="campaign-donate-title-content">
                  <div className="title-4">
                    <span className="text-pink">こどもごちめし</span> 
                    <span>について</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="campaign-donate-content-box-4">
              <div className="campaign-donate-header-4">
                <div className="image-box"><img src="/assets/img/gochi-kid/logo_gochi_kid.png" /></div>

                <div className="text-box">
                  <div>
                    <span className="text-pink">地域の飲食店をこども食堂化</span>
                    <span className="text-small">し、</span></div>
                  <div>こどもの居場所とまちの未来を育むサービスです。</div>
                </div>
              </div>

              <div className="campaign-donate-content-4">
                皆さまからの支援金は、こどもたちの飲食店でのお食事代となります。
                <br />
                <span>飲食店、利用者とも、</span>
                <span className="text-green">手数料は不要</span>
                <span>です。</span>
                <br />
                <br />
                仕組みがデジタル化されているので、運営コストを安く抑え、
                <br />
                <span>寄付金の</span>
                <span className="text-green">90%以上</span>
                <span>をお食事代金に充てることができます。</span>
              </div>
            </div>

            <div className="campaign-donate-title-block">
              <div className="campaign-donate-title">
                <div className="campaign-donate-title-content">
                  <div className="title-5">
                    <span className="text-pink">こどもごちめし</span> 
                    <span>の仕組み</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="campaign-donate-content-box-5">
              <img src={imageDiagramPC} alt="image-diagram-pc" className="image-diagram-pc" />
              <img src={imageDiagramSP} alt="image-diagram-sp" className="image-diagram-sp" />
            </div>

            <div className="campaign-donate-title-block">
              <div className="campaign-donate-title">
                <div className="campaign-donate-title-content">
                  <span>利用イメージ</span>
                </div>
              </div>
            </div>

            <div className="campaign-donate-content-box-6 border-dashed-box">
              <iframe
                src="https://www.youtube.com/embed/WS6aVhRCg3c?si=Bi6wFgvpL4PnYbhp" 
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              />
            </div>

            <div ref={formTitleRef} className="campaign-donate-title-block">
              <div className="campaign-donate-title">
                <div className="campaign-donate-title-content">
                  <div className="title-7">
                    <span>キャンペーンへの</span>
                    <span className="text-pink">寄付</span> 
                    <span>はこちらから</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="page-content donate-payment">
            <ul className="payment-progress-bar align-center">
              <li className="active">入力</li>
              <li>確認</li>
              <li>完了</li>
            </ul>

            <div className="block-title-lg fs-14px fw-400">：必須入力ですので、必ずご入力お願い致します</div>

            {(donateAmountError || paymentTypeError || userEmailError || userNameError) && <ErrorAlert className="mt-3">
              <div>
                以下の入力項目に誤りがあります。エラーメッセージをご確認頂き、再度ご入力をお願い致します。
              </div>
              <ul>
                {donateAmountError && <li>金額（税込）</li>}
                {paymentTypeError && <li>お支払い方法</li>}
                {(userEmailError || userNameError) && <li>支援者情報</li>}
              </ul>
            </ErrorAlert>}

            {/* <div className="block-title-lg font-family-rounded mt-5">
              支援先 <span className="color-ED808C">*</span>：{getDestinationText()}
            </div> */}

            {activityInfo && <div className="mt-3">
              <ActivityInfo
                className="activity-info-confirm"
                info={activityInfo}
                hideContactInfo={destination === 'region'}
              />
            </div>}

            <div ref={donateAmountRef} className="block-title-lg font-family-rounded mt-5">
              金額（税込） <span className="color-ED808C">*</span>
            </div>
            <div className="fs-14px fw-700 mt-3">金額選択</div>
            <div className="mt-2">
              <button
                className={'btn-outline btn-donate-amount-preset' + (donateAmount === 20000 ? ' background-FBEBEC' : '')}
                onClick={() => setDonateAmount(20000)}
              >{Number(20000).toLocaleString()}<span className="fs-16px">円</span></button>
              <button
                className={'btn-outline btn-donate-amount-preset ml-3' + (donateAmount === 10000 ? ' background-FBEBEC' : '')}
                onClick={() => setDonateAmount(10000)}
              >{Number(10000).toLocaleString()}<span className="fs-16px">円</span></button>
              <button
                className={'btn-outline btn-donate-amount-preset ml-3' + (donateAmount === 5000 ? ' background-FBEBEC' : '')}
                onClick={() => setDonateAmount(5000)}
              >{Number(5000).toLocaleString()}<span className="fs-16px">円</span></button>
            </div>
            <div className="fs-14px fw-700 mt-4">指定金額</div>
            <div className="d-flex align-items-center mt-1">
              <input
                className="form-control text-right outline-none"
                placeholder="金額をご入力ください"
                type="tel"
                inputMode="numeric"
                value={donateAmount || ''}
                onChange={onChangeDonateAmount}
              />
              <span className="ml-3 fw-700">円</span>
            </div>
            {donateAmountError && <ErrorAlert className="mt-2">{donateAmountError}</ErrorAlert>}

            {/* <div className="block-title-lg font-family-rounded mt-5">
              回数 <span className="color-ED808C">*</span>
            </div>
            <div className="d-flex mt-3">
              <RadioInput
                disabled={isSubscriptionDisabled}
                className="mr-2"
                inputName="isSubscription"
                inputValue="true"
                checked={!isSubscriptionDisabled && isSubscription}
                onChange={(e) => setIsSubscription(e.target.value === 'true')}
              >継続（1ヶ月1回）</RadioInput>
              <RadioInput
                className="ml-4"
                inputName="isSubscription"
                inputValue="false"
                checked={isSubscriptionDisabled || !isSubscription}
                onChange={(e) => setIsSubscription(e.target.value === 'true')}
              >一度だけ</RadioInput>
            </div> */}

            {/*<PaypayAd className="logo-paypay-ad-donate" />*/}

            {/*{!userInfo?.userId && <div className="payment-note mt-3">
              <div className="payment-note-icon">
                <svg width="5" height="18" viewBox="0 0 5 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.56366 18C2.05762 18 1.62574 17.8292 1.26802 17.4875C0.910304 17.1458 0.731445 16.7333 0.731445 16.25C0.731445 15.7667 0.910304 15.3542 1.26802 15.0125C1.62574 14.6708 2.05762 14.5 2.56366 14.5C3.0697 14.5 3.50158 14.6708 3.8593 15.0125C4.21702 15.3542 4.39587 15.7667 4.39587 16.25C4.39587 16.7333 4.21702 17.1458 3.8593 17.4875C3.50158 17.8292 3.0697 18 2.56366 18ZM2.56366 12C2.05762 12 1.62574 11.8292 1.26802 11.4875C0.910304 11.1458 0.731445 10.7333 0.731445 10.25V1.75C0.731445 1.26667 0.910304 0.854167 1.26802 0.5125C1.62574 0.170833 2.05762 0 2.56366 0C3.0697 0 3.50158 0.170833 3.8593 0.5125C4.21702 0.854167 4.39587 1.26667 4.39587 1.75V10.25C4.39587 10.7333 4.21702 11.1458 3.8593 11.4875C3.50158 11.8292 3.0697 12 2.56366 12Z" fill="#47ACFF"/>
                </svg>
              </div>
              <div className="payment-note-text">
                継続（1ヶ月1回）のご支援は、会員登録とログインをした場合にのみご利用可能です。
              </div>
            </div>}*/}

            <div ref={paymentTypeRef} className="block-title-lg font-family-rounded mt-5">
              お支払い方法 <span className="color-ED808C">*</span>
            </div>
            <Payment
              className="mt-3"
              paymentType={paymentType}
              disabledNonSubscription={!isSubscriptionDisabled && isSubscription}
              setPaymentType={setPaymentType}
              paymentTypeError={paymentTypeError}
              loading={loadingPayment}
              setLoading={setLoadingPayment}
            />

            {!isSubscriptionDisabled && isSubscription && <div className="payment-note mt-3">
              <div className="payment-note-icon">
                <svg width="5" height="18" viewBox="0 0 5 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.56366 18C2.05762 18 1.62574 17.8292 1.26802 17.4875C0.910304 17.1458 0.731445 16.7333 0.731445 16.25C0.731445 15.7667 0.910304 15.3542 1.26802 15.0125C1.62574 14.6708 2.05762 14.5 2.56366 14.5C3.0697 14.5 3.50158 14.6708 3.8593 15.0125C4.21702 15.3542 4.39587 15.7667 4.39587 16.25C4.39587 16.7333 4.21702 17.1458 3.8593 17.4875C3.50158 17.8292 3.0697 18 2.56366 18ZM2.56366 12C2.05762 12 1.62574 11.8292 1.26802 11.4875C0.910304 11.1458 0.731445 10.7333 0.731445 10.25V1.75C0.731445 1.26667 0.910304 0.854167 1.26802 0.5125C1.62574 0.170833 2.05762 0 2.56366 0C3.0697 0 3.50158 0.170833 3.8593 0.5125C4.21702 0.854167 4.39587 1.26667 4.39587 1.75V10.25C4.39587 10.7333 4.21702 11.1458 3.8593 11.4875C3.50158 11.8292 3.0697 12 2.56366 12Z" fill="#47ACFF"/>
                </svg>
              </div>
              <div className="payment-note-text">
                PayPay決済やAlipay決済、atone 翌月払い（コンビニ/口座振替）は、支援回数の入力で”一度だけ”を選択された方のみご利用可能です。
              </div>
            </div>}

            {!userInfo?.userId && <>
              <div ref={userInfoRef} className="block-title-lg font-family-rounded mt-5">
                支援者情報 <span className="color-ED808C">*</span>
              </div>
              <div className="fs-14px fw-700 color-2F1A1C mt-3">メールアドレス</div>
              <div className="mt-1">
                <input
                  className="form-control"
                  placeholder="例）kodomo.gochimeshi@gigi.tokyo"
                  type="email"
                  value={userEmail || ''}
                  onChange={(event) => setUserEmail(event.target.value)}
                />
              </div>
              {userEmailError && <ErrorAlert className="mt-2">{userEmailError}</ErrorAlert>}
              <div className="payment-warning mt-2">
                <div className="payment-warning-icon">
                  <svg width="25" height="24" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.68036 21.1482H20.4479C22.0602 21.1482 23.0653 19.4782 22.2591 18.1482L14.3754 5.13825C13.5692 3.80825 11.559 3.80825 10.7528 5.13825L2.86908 18.1482C2.06291 19.4782 3.06801 21.1482 4.68036 21.1482ZM12.5641 14.1482C11.9883 14.1482 11.5171 13.6982 11.5171 13.1482V11.1482C11.5171 10.5982 11.9883 10.1482 12.5641 10.1482C13.14 10.1482 13.6111 10.5982 13.6111 11.1482V13.1482C13.6111 13.6982 13.14 14.1482 12.5641 14.1482ZM13.6111 18.1482H11.5171V16.1482H13.6111V18.1482Z" fill="#F9AC39"/>
                  </svg>
                </div>
                <div className="payment-warning-text">
                  ご登録メールアドレス宛に活動報告書を送信いたします。なお、当社からのメールを受信できるよう、「@gochimeshi.com」のドメインを受信許可に設定していただけますようお願いいたします。
                  <br />
                  また、@softbank.ne.jp、＠docomo.ne.jp 、@ezweb.ne.jp、@au.com などのキャリアメールの場合、活動報告書(PDF)のご送付が難しいため、お使いのパソコンのPCメールでのご登録をお勧めします。
                </div>
              </div>
              <div className="fs-14px fw-700 color-2F1A1C mt-4">氏名</div>
              <div className="mt-1">
                <input
                  className="form-control"
                  placeholder="支援者様の氏名をご入力ください"
                  type="text"
                  value={userName || ''}
                  onChange={(event) => setUserName(event.target.value)}
                />
              </div>
              {userNameError && <ErrorAlert className="mt-2">{userNameError}</ErrorAlert>}
            </>}

            <div className="block-title-lg block-title-optional font-family-rounded mt-5">
              こども達へのメッセージ<span className="color-35A99E"><br />（任意）</span>
            </div>
            <div className="mt-3">
              <textarea
                className="donate-message"
                placeholder="200文字以内で支援先への応援メッセージをご入力ください。"
                maxLength={200}
                value={donateMessage}
                onChange={onChangeDonateMessage}
              />
            </div>
            {donateMessageError && <ErrorAlert className="mt-2">{donateMessageError}</ErrorAlert>}
            <div className="payment-note mt-3">
              <div className="payment-note-icon">
                <svg width="5" height="18" viewBox="0 0 5 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.56366 18C2.05762 18 1.62574 17.8292 1.26802 17.4875C0.910304 17.1458 0.731445 16.7333 0.731445 16.25C0.731445 15.7667 0.910304 15.3542 1.26802 15.0125C1.62574 14.6708 2.05762 14.5 2.56366 14.5C3.0697 14.5 3.50158 14.6708 3.8593 15.0125C4.21702 15.3542 4.39587 15.7667 4.39587 16.25C4.39587 16.7333 4.21702 17.1458 3.8593 17.4875C3.50158 17.8292 3.0697 18 2.56366 18ZM2.56366 12C2.05762 12 1.62574 11.8292 1.26802 11.4875C0.910304 11.1458 0.731445 10.7333 0.731445 10.25V1.75C0.731445 1.26667 0.910304 0.854167 1.26802 0.5125C1.62574 0.170833 2.05762 0 2.56366 0C3.0697 0 3.50158 0.170833 3.8593 0.5125C4.21702 0.854167 4.39587 1.26667 4.39587 1.75V10.25C4.39587 10.7333 4.21702 11.1458 3.8593 11.4875C3.50158 11.8292 3.0697 12 2.56366 12Z" fill="#47ACFF"/>
                </svg>
              </div>
              <div className="payment-note-text">
                いただいたメッセージは、匿名で当団体が発信する「寄付通信」やSNSに使用させていただくことがあります。
              </div>
            </div>
            <div className="mt-5">
              <button className="btn-primary btn-size-md w-100" onClick={gotoConfirm}>
                次へ進む
                <i className="icon-next-destination" />
              </button>
            </div>
            {/* <div className="mt-4 text-center">
              <button className="btn-link fw-400 border-0 p-0" onClick={() => history.replace(destinationChoicePath + '?skipPolicyCheck=true')}>
                前の画面に戻る
              </button>
            </div> */}
          </div>
        </div>

        <div className="footer-campaign-donate">© Kids Future Passport.</div>
      </main>
    </>
  );
}

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  paymentDefaultCard: !state.paymentMethod || state.paymentMethod.length === 0 ? null : state.paymentMethod,
  oldInput: state.gochiKid.donate
});

const mapDispatchToProps = dispatch => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content, buttonName, gotoUrl) => dispatch(showError(showing, title, content, buttonName, gotoUrl)),
  confirmDonate: (paymentData) => dispatch(confirmDonate(paymentData))
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDonateInput);
