import React, { Component, Suspense } from 'react';
import queryString from 'query-string';
import {withTranslation} from "react-i18next";

import routes from './../../routes/routes';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {connect} from 'react-redux';
import {log} from '../../utils';

import PopupError from "../common/PopupError";
import {saveUserInfo} from "../../actions/action_users";
import {saveShopInfo, saveFeeRateMenuPrice, saveTaxRate} from "../../actions/action_shop";
import {saveGroupInfo} from "../../actions/action_group";
import {saveMenuInfo, saveFeeRate, saveMenuNCount} from "../../actions/action_menu";
import {showLoading} from "../../actions/action_loading";
import {showError} from "../../actions/action_error_alert";
import MenuService from "../../api/services/MenuService";
import ShopService from "../../api/services/ShopService";
import LayoutSwitch from './LayoutSwitch';
import { Redirect } from 'react-router-dom';

class App extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      menuId: null,
      mypage: false,
      login: false,
      register: false,
      tome: false,
      tomeDetail: false,
      qrCode: false,
      gochiraeru: false,
      menuCombo: false,
      shopId: null
    };
  };

  UNSAFE_componentWillMount() {
    this.updateAuthState();
  }

  updateAuthState() {
    var userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      this.props.saveUserInfo(true, userInfo.isGuest, userInfo);
    }
  }

  async componentDidMount() {
    if (process.env.REACT_APP_IS_SAKIMESHI) {
      document.title = "さきめし"
    } else {
      document.title = "Gochimeshi"
    }

    window.onstorage = () => {
      this.updateAuthState();
    }
    
    this.props.showLoading(true);
    let url = window.location.search; 
		let params = queryString.parse(url);
    var menuId = params.id;
    var shopId = params.shopId;
    var pathname = window.location.pathname;
    this.setState({
      shopId: shopId
    });
    this.setState({
      menuId: menuId
    });
    // add param bookingCampaignFinish in finish screen for check validate booking
    var bookingCampaignFinish = sessionStorage.getItem("emailBookingCampaign") && sessionStorage.getItem("secretCode") && sessionStorage.getItem("monthBookingCampaign") && sessionStorage.getItem("campaignId") && (pathname === '/invite-finish' || sessionStorage.getItem("bookingCampaignFinish")) ? '&bookingCampaignFinish=1' : '';
    var bookingParam = (sessionStorage.getItem("emailBookingCampaign") ? 'emailBookingCampaign=' + encodeURIComponent(sessionStorage.getItem("emailBookingCampaign")) : '') + (sessionStorage.getItem("secretCode") ? '&secretCode=' + encodeURIComponent(sessionStorage.getItem("secretCode")) : '') + (sessionStorage.getItem("monthBookingCampaign") ? '&monthBookingCampaign=' + encodeURIComponent(sessionStorage.getItem("monthBookingCampaign")) : '') + (sessionStorage.getItem("campaignId") ? '&campaignId=' + encodeURIComponent(sessionStorage.getItem("campaignId")) + bookingCampaignFinish : '');

    if (!menuId) { 
      // check SNS code in url params
      if (!params.code) {
        this.props.showLoading(false);
      }
    } else {
      let params = queryString.parse(url);
      var requestCode = params.id; 
      if (requestCode && requestCode.length > 20){
        var menuNCount = await MenuService.getMenuIdNCount(requestCode);
        menuId = menuNCount.data.menuId ? menuNCount.data.menuId : params.id;
        this.props.saveMenuNCount(menuNCount.data);
      } else {
        menuId = params.id;
      }
      if(Object.entries(this.props.shopInfo).length === 0) {
        var feeRate = await MenuService.getFeeRate();

        if (feeRate.status === 200) {
          this.props.saveFeeRate(feeRate.data);
        }

        if (window.Promise) {
          var promise = new Promise(function(resolve, reject) {
            const menu = MenuService.getDetailMenu(menuId, null, null, null, bookingParam);

            return resolve(menu);
          });
          var thisView = this;
          promise.then(function(menu){
            thisView.props.saveMenuInfo(menu.data);
            if (menu.status === 200) { 
              if (menu.data.shopId) { 
                new Promise(function(resolve, reject) {
                  const shop = ShopService.getDetailShop(menu.data.shopId);
                  return resolve(shop);
                }).then(function(shop){
                  if (shop.status === 200) {
                    thisView.props.saveShopInfo(shop.data);
                  }else{
                    thisView.props.showError(true, shop.message, "");
                  }
                }).then(function(){
                  thisView.props.showLoading(false);
                })
              } else {
                if (menu.data.groupId) {
                  new Promise(function(resolve, reject) {
                    const group = ShopService.getDetailGroup(menu.data.groupId);
                    return resolve(group);
                  }).then(function(group){
                    if (group.status === 200) {
                      thisView.props.saveGroupInfo(group.data);
                    }else{
                      thisView.props.showError(true, group.message, "");
                    }
                  }).then(function(){
                    thisView.props.showLoading(false);
                  })
                }
                thisView.props.showLoading(false);
              }
            } else if (menu.status === 400 && menu.data.code === 'exception.errors.gochi.campaign.not.found') {
              let messageErr;
              messageErr = this.props.t('pages.bookingCamaign.campaign_not_found');
              this.props.showError(true, messageErr, "");
              thisView.props.showLoading(false);
            } else if (menu.status === 400 && menu.data.code === 'exception.errors.gochi.campaign.total.limited') {
              let messageErr;
              messageErr = this.props.t('pages.bookingCamaign.campaign_total_limited');
              this.props.showError(true, messageErr, "");
              thisView.props.showLoading(false);
            } else if (menu.status === 400 && menu.data.code === 'exception.errors.gochi.campaign.booking.duplicate') {
              let messageErr;
              messageErr = this.props.t('pages.bookingCamaign.campaign_booking_duplicate');
              this.props.showError(true, messageErr, "");
              thisView.props.showLoading(false);
            } else if (menu.status === 400 && menu.data.code === 'exception.errors.gochi.campaign.params.invalid') {
              let messageErr;
              messageErr = thisView.props.t('pages.bookingCamaign.campaign_params_invalid');
              thisView.props.showError(true, messageErr, "");
              thisView.props.showLoading(false);
            } else if (menu.status === 400 && menu.data.code === 'exception.errors.gochi.campaign.activecode.invalid') {
              let messageErr;
              messageErr = thisView.props.t('pages.bookingCamaign.campaign_activecode_invalid');
              thisView.props.showError(true, messageErr, "");
              thisView.props.showLoading(false);
            } else if (menu.status === 400 && menu.data.code === 'exception.errors.gochi.campaign.booking.not.found') {
              let messageErr;
              messageErr = thisView.props.t('pages.bookingCamaign.campaign_booking_not_found');
              thisView.props.showError(true, messageErr, "");
              thisView.props.showLoading(false);
            }else{
              log(menu);
              thisView.props.showError(true, thisView.props.t('other.Unexpected error'), "");
              thisView.props.showLoading(false);
            }
          })
        }
      }
    }

    if(!menuId && shopId) {
      var thisView2 = this;
      thisView2.props.showLoading(true);
      
      new Promise(function(resolve, reject) {
        const shop = ShopService.getDetailShop(shopId);
        return resolve(shop);
      }).then(function(shop){
        if(shop.status === 200) {
          thisView2.props.saveShopInfo(shop.data);
          if(shop.data.allowMenuPrice !== 1 && pathname.includes('/invite')) {
            thisView2.props.showError(true, thisView2.props.t('pages.gochiuse.You cannot buy Gochi Price in this shop'), "");
          }
        } else {
          log(shop);
          thisView2.props.showError(true, shop.message, "");
        }
      }).then(function(){
        thisView2.props.showLoading(false);
      });

      var feeRate2 = await MenuService.getFeeRate();
      if (feeRate2.status === 200) {
        this.props.saveFeeRateMenuPrice(feeRate2.data);
      }

      var taxRate = await MenuService.getTaxRate();
      if (taxRate.status === 200) {
        this.props.saveTaxRate(taxRate.data);
      }
    }
    
    let user = 'guest';
    // get user id from local storage
    if (localStorage.getItem('userInfo')) {
      user =
        'user_id_' + localStorage.getItem('userInfo').userId;
    }

    var _paq = window._paq = window._paq || [];
    // / tracker methods like "setCustomDimension" should be called before "trackPageView" /
    _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
    _paq.push(["setCookieDomain", "*." + document.domain]);
    _paq.push(["setDomains", ["*." + document.domain]]);
    if (user !== 'guest') {
      _paq.push(['setUserId', user]);
    }
    if (user === 'guest') {
      // User has just logged out, we reset the User ID
      _paq.push(['resetUserId']);
    }

    // you can set up to 5 custom variables for each visitor
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);

    // (function() {
    //     var u="//matomo.1steam.com/";
    //     _paq.push(['setTrackerUrl', u+'matomo.php']);
    //     _paq.push(['setSiteId', process.env.REACT_APP_MATOMO_ID]);
    //     var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    //     g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    // })();
  }

  render() {     
    if (!localStorage.getItem('lang')) {
      let browserLang = navigator.language || navigator.userLanguage;

      if (browserLang.includes('en')) {
        browserLang = 'en';
      } else if (browserLang.includes('zh')) {
        browserLang = 'zh';
      } else {
        browserLang = 'ja';
      } 

    localStorage.setItem('lang', browserLang);
    }

    return (
      <Router>
        <PopupError/>
          <Suspense fallback={(<div>Loading</div>)}>
            {/* {this.props.loading &&
            <div className="loading-overlay">
              <FontAwesomeIcon icon={faSpinner} className="" />
            </div>
            } */}
            <Switch>
              {this.showContentPage(routes)}
              <Redirect from='*' to='/404' />
            </Switch>
          </Suspense>
      </Router>
    );
  }

  showContentPage = (routes) => {
    var result = null;
    if (routes.length > 0) {
      result = routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={() => (
              <LayoutSwitch component={route.main} isGiftSite={route.isGiftSite} isMogugori={route.isMogugori} isGochiKid={route.isGochiKid} isNoMultiLang={route.isNoMultiLang} path={route.path} isPrivateRoute={route.isPrivateRoute} hideGochiKid={route.hideGochiKid}/>
            )}
          />
        );
      });
    }
    return result;
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  saveUserInfo: (isLogined, isGuest, userInfo) => dispatch(saveUserInfo(isLogined, isGuest, userInfo)),
  saveShopInfo: (shopInfo) => dispatch(saveShopInfo(shopInfo)),
  saveGroupInfo: (groupInfo) => dispatch(saveGroupInfo(groupInfo)),
  saveMenuInfo: (menuInfo) => dispatch(saveMenuInfo(menuInfo)),
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content) =>dispatch(showError(showing, title, content)),
  saveFeeRate: (feeRate) => dispatch(saveFeeRate(feeRate)),
  saveFeeRateMenuPrice: (feeRate) => dispatch(saveFeeRateMenuPrice(feeRate)),
  saveTaxRate: (taxRate) => dispatch(saveTaxRate(taxRate)),
  saveMenuNCount: (menuNCount) => dispatch(saveMenuNCount(menuNCount))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
