import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import queryString from "query-string";
import { withTranslation } from "react-i18next";
import { log } from "../../../utils";
import { showLoading } from "../../../actions/action_loading";
import { showError } from "../../../actions/action_error_alert";
import GochiService from "../../../api/services/GochiService";
import CouponService from "../../../api/services/CouponService";
import Header from "../../layout/header/Header";
import { Modal } from "react-bootstrap";
import MenuService from "../../../api/services/MenuService";
import PopupConfirm from "../../common/PopupConfirm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import Barcode from "../../common/Barcode";

class GochiUseFinish extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gochiDetail: {},
      isSakimeshi: true,
      redirectMypage: false,
      gochiUsedNo: 0,
      messageText: null,
      messageTextError: null,
      shopId: null,
      gochiBillId: null,
      gochiUserId: null,
      menuId: null,
      redirectTicketDetail: false,
      disabledBtnSendMessage: true,
      showConfirmMessagePopup: false,
      couponId: null,
      gochiTipUrl: null,
      sentMessage: null,
      sentMessageToShop: false,
      waitingComplete: true,
      paymentCompleted: false,
      userInfo: queryString.parse(window.location.search).accessToken ? {isGuest: false, isLogined: true, userId: JSON.parse(atob(queryString.parse(window.location.search).accessToken.split(".")[1])).uid} : (this.props?.userInfo ? this.props.userInfo : JSON.parse(localStorage.getItem('userInfo'))),
      isLoggedIn: this.props?.userInfo?.userId ? true : false,
      accessToken: queryString.parse(window.location.search).accessToken,
      closeTab: queryString.parse(window.location.search).accessToken ? true : false,
      barCodeYoshinoya: queryString.parse(window.location.search).barCodeYoshinoya,
    };
    this.getGochi = this.getGochi.bind(this);
    this.redirectMypage = this.redirectMypage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleGoToTicketDetail = this.handleGoToTicketDetail.bind(this);
    this.handleSendReview = this.handleSendReview.bind(this);
    this.onCloseConfirmMessagePopup =
    this.onCloseConfirmMessagePopup.bind(this);
    this.handleOpenConfirmPopup = this.handleOpenConfirmPopup.bind(this);
    this.getCouponShopMessage = this.getCouponShopMessage.bind(this);
    this.openGiftSite = this.openGiftSite.bind(this);
    this.onClosePaperTicketAlert = this.onClosePaperTicketAlert.bind(this);
    this.checkAccessScreen = this.checkAccessScreen.bind(this);
  }

  getCouponShopMessage = async (couponId) => {
    try {
      const { data } = await CouponService.getCouponShopMessage(couponId);
      return data;
    } catch (e) {
      log(e);
      this.props.showError(true, this.props.t("There is something wrong"), "");
    }
  };

  getGochi = async (gochiBillId) => {
    this.props.showLoading(true);
    try {
      let accessToken = queryString.parse(window.location.search).accessToken;
      let checkTokenExpired = accessToken ? 1 : null;
      const { data } = await GochiService.getGochi({ gochiBillId, accessToken, checkTokenExpired });
      this.props.showLoading(false);
      return data;
    } catch (error) {
      log("getGochiList error");
      this.props.showLoading(false);
      this.props.showError(true, this.props.t("There is something wrong"), "");
    }
  };

  redirectMypage() {
    this.setState({
      redirectMypage: true,
    });
  }

  openGiftSite() {
    window.open("https://gochi.online/", "_blank");
  }

  handleChange = (e) => {
    let mess = e.target.value;
    console.log("review message ===", mess);
    if (mess.trim() !== "") {
      this.setState({
        disabledBtnSendMessage: false,
      });
    } else {
      this.setState({
        disabledBtnSendMessage: true,
      });
    }
    this.setState({
      messageText: mess,
    });
  };

  handleOpenConfirmPopup() {
    const { t } = this.props;

    if (
      this.state.messageText.length < 1 ||
      this.state.messageText.length > 300
    ) {
      this.setState({
        showConfirmMessagePopup: false,
        messageTextError: t(
          "pages.invite.Please enter the message within 300 characters"
        ),
      });
    } else {
      this.setState({
        showConfirmMessagePopup: true,
        messageTextError: null,
      });
    }
  }

  async handleSendReview() {
    this.props.showLoading(true);
    try {
      const { data } = await CouponService.sendReviewMessageToShop(
        this.state.messageText,
        this.state.couponId
      );
      console.log("data sent ===", data);
      this.props.showLoading(false);
      this.setState({
        showConfirmMessagePopup: false,
        sentMessageToShop: true,
        sentMessage: this.state.messageText,
      });
    } catch (error) {
      console.log(error);
      this.props.showLoading(false);
      this.props.showError(true, this.props.t("There is something wrong"), "");
    }
  }

  handleGoToTicketDetail() {
    this.setState({
      redirectTicketDetail: true,
    });
  }

  onCloseConfirmMessagePopup() {
    this.setState({
      showConfirmMessagePopup: false,
    });
  }

  onClosePaperTicketAlert() {
    this.setState({
      showPaperTicketAlert: false,
    });
  }

  handlePopupConfirm = () => {
    let url = window.location.search;
    let params = queryString.parse(url);
    var merchantPaymentId = params.merchantPaymentId;
    this.setState({
      loadUrl: true,
      popupShowing: false,
    });
    this.init();
  };

  handlePopupClose = () => {
    this.setState((state) => ({
      popupShowing: false,
    }));
  };

  async completePayment(merchantPaymentId) {
    this.setState({ waitingComplete: true });
    try {
      // If payment gateway is Onepay, re-call API complete until response status is not 404 or timeout (5 * 10 = 50 seconds)
      let result;
      for (let i = 0; i < 5; i++) {
        result = await MenuService.completeCheckoutAddedAmount(
          merchantPaymentId
        );
        if (result.status !== 404) break;
        await new Promise((r) => setTimeout(r, 10000));
      }
      console.log("Stop retrying");
      this.setState({ waitingComplete: false });
      if (result.status === 200) {
        this.setState({
          useAmount: result.data.amount,
          paymentCompleted: true,
        });
        return result.data;
        // sessionStorage.removeItem("selectPayment");
      } else if (result.status === 402) {
        let messageErr;
        if (result.data) {
          if (result.data.stripe_code === "expired_card") {
            messageErr = this.props.t(
              "pages.invite.error create gochi using stripe incase expired card"
            );
          } else if (result.data.stripe_code === "amount_too_small") {
            messageErr = this.props.t(
              "pages.invite.error create gochi using stripe incase amount to small"
            );
          } else if (result.data.stripe_code === "insufficient_funds") {
            messageErr = this.props.t(
              "pages.invite.error create gochi using stripe incase insufficient funds"
            );
          } else if (result.data.stripe_code === "card_velocity_exceeded") {
            messageErr = this.props.t(
              "pages.invite.error create gochi using stripe incase card velocity exceeded"
            );
          } else {
            messageErr =
              this.props.menuInfo.subscription === 1
                ? this.props.t("pages.invite.error subsription failed")
                : this.props.t("pages.invite.error create gochi user");
          }
        }
        this.props.showError(true, messageErr, "");
      } else if (
        this.state.paymentGateway === "onepay" ||
        result.status === 404
      ) {
        this.setState((state) => ({
          loadUrl: false,
          popupShowing: true,
        }));
      } else {
        if (
          JSON.parse(sessionStorage.getItem("fullInviteContent")) &&
          !JSON.parse(sessionStorage.getItem("fullInviteContent")).inviteUrl
        ) {
          this.props.showError(
            true,
            this.props.t("pages.invite.error create gochi user"),
            ""
          );
          this.setState({
            loadUrl: false,
          });
        } else {
          this.setState({
            inviteUrl:
              JSON.parse(sessionStorage.getItem("fullInviteContent")) &&
              JSON.parse(sessionStorage.getItem("fullInviteContent")).inviteUrl
                ? JSON.parse(sessionStorage.getItem("fullInviteContent"))
                    .inviteUrl
                : null,
          });
        }
        if (
          result.status === 404 ||
          result.status === 400 ||
          result.status === 500
        ) {
          this.setState({
            inviteUrl: null,
          });
          this.props.showError(
            true,
            this.props.t("pages.invite.error create gochi user"),
            ""
          );
        }
        if (result.status === 504) {
          this.setState({
            inviteUrl: null,
          });
          this.props.showError(
            true,
            this.props.t("pages.invite.error create gochi with guest"),
            ""
          );
        }
      }
    } catch (error) {
      this.setState({ waitingComplete: false });
      return {};
    }
    return {};
  }

  checkAccessScreen = async () => {
      let url = window.location.search;
      let params = queryString.parse(url);
      let accessToken = params.accessToken;
      if (!accessToken) {
          var link = "/login";
          if (!this.state.isLoggedIn){
              sessionStorage.setItem('loginCallbackUrl', window.location.pathname);
              return window.location.href = link;
          } else {
              if (this.state.userInfo) { 
                  if (this.state.userInfo.isLogined === false || this.state.userInfo.isGuest) { 
                      window.history.pushState(null, null, '/login');
                      return <Redirect to={link} />
                  }
              }else{
                  window.history.pushState(null, null, '/login');
                  return <Redirect to={link} />
              }
          }
      } else {
          if (this.state.isLoggedIn){
              sessionStorage.clear();
              localStorage.clear();
          }
      }
  }

  getFooterSystem = (gochiInfo) => {
    let url = window.location.search;
    let params = queryString.parse(url);

    if (params.siteType === "suita") {
        return (
          <div>
            {gochiInfo.gochiBillNo && <div className="clr-000000 fs-18 font-weight-bold">紙クーポンNo： {gochiInfo.gochiBillNo}</div>}
            <div className="fs-12 clr-000000">吹田市子供の習い事費用助成事業 運営事務局</div>
            <div><span className="fs-12 clr-000000">TEL：</span><a href="tel:0120-065-067" className="fs-12 clr-F44A4A text-decoration-underline">0120-065-067</a></div>
            <div className="fs-12 clr-000000">営業時間：平日 11:00〜20:00</div>
            <div className="fs-12 clr-000000">（土日祝休み）</div>
          </div>
        );
    } else if (params.siteType === "nishiwaki") {
      return (
        <div>
          {gochiInfo.gochiBillNo && <div className="clr-000000 fs-18 font-weight-bold">紙クーポンNo： {gochiInfo.gochiBillNo}</div>}
          <div className="fs-12 clr-000000">お問い合わせ先：西脇市こども未来応援事業 運営事務局</div>
          <div className="fs-12 clr-000000">営業時間：9:30~17:30 (土・日・祝休業)</div>
          <div><span className="fs-12 clr-000000">TEL：</span><a href="tel:078-371-8781" className="fs-12 clr-F44A4A text-decoration-underline">078-371-8781</a></div>
        </div>
      );
    } else {
        return (
          <div>
            {<div className="clr-000000 fs-18 font-weight-bold">紙クーポンNo： {this.state.gochiDetail.gochiBillNo}</div>}
            <div className="fs-12 clr-000000">お問い合わせ先：福岡子ども習い事応援事業 運営事務局</div>
            <div className="fs-12 clr-000000">営業時間：年末年始（12/29~1/3）除く</div>
            <div className="fs-12 clr-000000">9:00~18:00</div>
            <div><span className="fs-12 clr-000000">電話番号：</span><a href="tel:092-406-3108" className="fs-12 clr-F44A4A text-decoration-underline">092-406-3108</a></div>
          </div>
        );
    }
  }
    
	UNSAFE_componentWillMount(){ 
      this.checkAccessScreen();
  }

  async componentDidMount() {
    this.init();
  }

  async init() {
    let url = window.location.search;
    let params = queryString.parse(url);
    let gochiBillId = params.gochiBillId;
    let gochiUsedNo = params.gochiUsedNo;
    const dataGochi = sessionStorage.getItem("usingFlowGochiInfo")
      ? JSON.parse(sessionStorage.getItem("usingFlowGochiInfo"))
      : {};
    const shopId = params.shopId;
    const gochiUserId = this.state.userInfo?.userId;
    const menuId = dataGochi.menuId;
    const shopName = dataGochi.shopName;
    this.setState({shopName});
    const couponId = params.couponId;
    const gochiTipUrl = params.gochiTipUrl;
    const merchantPaymentId = params.merchantPaymentId;
    console.log("merchantPaymentId", merchantPaymentId);

    if (merchantPaymentId) {
      const completeResponse = await this.completePayment(merchantPaymentId);
      gochiBillId = completeResponse.gochiBillId;
      gochiUsedNo = completeResponse.couponCount;
      this.setState({ completeResponse });
    } else {
      this.setState({ paymentCompleted: true, waitingComplete: false });
    }

    if (shopId) {
      this.setState({
        shopId: shopId,
      });
    }

    if (gochiUserId) {
      this.setState({
        gochiUserId: gochiUserId,
      });
    }

    if (menuId) {
      this.setState({
        menuId: menuId,
      });
    }

    if (couponId) {
      this.setState({
        couponId: couponId,
      });
    }

    if (gochiTipUrl) {
      this.setState({
        gochiTipUrl: gochiTipUrl,
      });
    }

    if (gochiBillId) {
      this.setState({ gettingGochi: true });
      const response = await this.getGochi(gochiBillId);
      this.setState({ gettingGochi: false });

      if (response && couponId) {
        const list = await this.getCouponShopMessage(couponId);
        if (list.couponId === parseInt(couponId)) {
          this.setState({
            sentMessageToShop: true,
            sentMessage: list.shopMessage,
          });
        }
      }
      if (response.menu?.isETicket === 0) {
        this.setState({
          showPaperTicketAlert: true
        });
      }
      console.log('response: ', response);
      this.setState({
        gochiBillId: gochiBillId,
        gochiDetail: response,
        gochiUsedNo: gochiUsedNo,
      });
    }

    this.setState({
        gochiCount: dataGochi.gochiCount
    });
  }

  render() {
    const { t } = this.props;
    const shopId = this.state.shopId;
    const gochiBillId = this.state.gochiBillId;
    const redirectTicketDetail = this.state.redirectTicketDetail;
    const menuId = this.state.menuId;
    const gochiUserId = this.state.gochiUserId;
    const menuLimitTotal = this.state.gochiDetail.menuLimitTotal === 1;
    const remainAmount = this.state.gochiDetail?.remainAmount;
    let url = window.location.search;
    let params = queryString.parse(url);
    const merchantPaymentId = params.merchantPaymentId;
    const accessToken = params.accessToken;
    const urlAccessToken = accessToken ? "&accessToken=" + accessToken : "";
    const useAmount = merchantPaymentId
      ? this.state.useAmount
      : this.props.location?.state?.useAmount;

    const redirectMypage = this.state.redirectMypage;
    if (redirectMypage === true) {
      var link = "/mypage";
      window.history.pushState(null, null, "/mypage");
      return <Redirect to={link} />;
    }

    const menuName =
      this.state.gochiDetail.gochiType === "PRICE"
        ? t("pages.menu.Menu Price")
        : this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuName;
    let menuImage = require("../../../assets/img/icons/gochi_noimg-ver4.jpg");
    if (this.state.gochiDetail.gochiType === "PRICE") {
      menuImage = require("../../../assets/img/icons/gochi_price.jpg");
    } else {
      menuImage =
        this.state.gochiDetail.menu &&
        this.state.gochiDetail.menu.menuImage.length > 0
          ? this.state.gochiDetail.menu.menuImage[0].image
          : require("../../../assets/img/icons/gochi_noimg-ver4.jpg");
    }
    const includedTaxPrice =
      this.state.gochiDetail.gochiType === "PRICE"
        ? this.state.gochiDetail.amount / this.state.gochiDetail.couponCount +
          this.state.gochiDetail.taxAmount / this.state.gochiDetail.couponCount
        : this.state.gochiDetail.menu &&
          this.state.gochiDetail.menu.includedTaxPrice;

    const displayAmount =
      this.state.gochiDetail.menu && this.state.gochiDetail.menu.displayAmount;
      const menuQuantity = this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuComboDetail && this.state.gochiDetail.menu.menuComboDetail.quantity;

      const includedTaxPriceCombo = this.state.gochiDetail.menu && this.state.gochiDetail.menu.menuComboDetail && this.state.gochiDetail.menu.menuComboDetail.includedTaxPriceCombo;
      const displayAmountOneTicket = displayAmount ? Math.round(displayAmount / menuQuantity) : includedTaxPriceCombo;
      console.log('DISPLAY AMOUNT ONE: ', this.state.gochiDetail, displayAmountOneTicket, displayAmount, includedTaxPriceCombo);

    const shopName =
      (this.state.gochiDetail.shop && this.state.gochiDetail.shop.shopName) || this.state.shopName || this.props.shopInfo.shopName;
    const groupName =
      this.state.gochiDetail.groupInfo &&
      this.state.gochiDetail.groupInfo.groupName;

    const isMenuCombo =
      this.state.gochiDetail.menu &&
      this.state.gochiDetail.menu.menuCombo === 1;
    const quantityFree =
      this.state.gochiDetail.menu &&
      this.state.gochiDetail.menu.menuComboDetail &&
      this.state.gochiDetail.menu.menuComboDetail.quantityFree
        ? this.state.gochiDetail.menu.menuComboDetail.quantityFree
        : 0;
    const menuComboDetail =
      this.state.gochiDetail.menu &&
      this.state.gochiDetail.menu.menuComboDetail;
    const gochiCount = this.state.gochiDetail.gochiCount
      ? this.state.gochiDetail.gochiCount
      : 0;

    let totalUseAmount = 0;
    if (menuLimitTotal) {
      totalUseAmount = useAmount;
    } else {
      if (isMenuCombo) {
        totalUseAmount = displayAmountOneTicket ? displayAmountOneTicket : 0;
      } else {
        if (merchantPaymentId) {
          totalUseAmount = this.state.completeResponse?.couponPrice || 0;
        } else {
          totalUseAmount = this.state.gochiUsedNo * (displayAmount || includedTaxPrice);
        }
      }
    }

    if (redirectTicketDetail === true) {
      var urlRedirect = isMenuCombo
        ? "/menuCombo/detail?gochiBillId=" +
          gochiBillId +
          "&gochiUserId=" +
          gochiUserId +
          "&menuId=" +
          menuId
        : "/tome/detail?gochiBillId=" + gochiBillId + urlAccessToken;
      if (menuLimitTotal) {
        urlRedirect =
          "/gochiBillDetail?shopId=" +
          params.shopId +
          "&gochiUserId=" +
          gochiUserId +
          "&menuId=" +
          menuId + urlAccessToken;
      }
      window.history.pushState(null, null, urlRedirect);
      return <Redirect to={urlRedirect} />;
    }

    const closeTab = () => {
      // window.opener = null;
      // window.open("", "_self");
      window.close();
    };

    const hidePopup = () => {
      this.setState ({
        closeTab: false
      })
    }

    const useCouponId = parseInt(this.props.location?.state?.useCouponId ? this.props.location?.state?.useCouponId : this.state.couponId);

    // Get Sushiro QR image
    let sushiroQrImage = null;
    if (this.state.couponId && this.state.gochiDetail.couponMessage && this.state.gochiDetail.couponMessage.length > 0) {
      for (const couponMessage of this.state.gochiDetail.couponMessage) {
        if (couponMessage.couponId === useCouponId && couponMessage.sushiroQrIssue?.qrImageBase64) {
          sushiroQrImage = 'data:image/png;base64,' + couponMessage.sushiroQrIssue?.qrImageBase64;
          break;
        }
      }
    }

    const isShowPrice = this.state.gochiDetail?.menu?.showPrice !== 0;
    const isEducateSystem = params.siteType === "fukuoka" || params.siteType === "suita" || params.siteType === "nishiwaki";

    return (
      <>
        {this.state.paymentCompleted && (
          <audio
            autoPlay
            src={require("../../../assets/audios/gochi_jingle_master_final.mp3")}
          />
        )}
        <div className="content font-gothic clr-000000">
          <Header
            title={isEducateSystem ? "" : t("steps.step_complete")}
            isLoggedIn={this.state.isLoggedIn}
            t={t}
            usingFlow={true}
            siteType={params.siteType}
          />

          <div className="fs-22 font-weight-bold my-5 text-center">
            {isEducateSystem ? "利用完了" : "お支払い完了"}
          </div>
          <div className="used-price-box mt-3">
            <div className="top">
              {shopName}
            </div>
            <div className="mid mt-3 mb-2">
              {isShowPrice ? (
                <>
                  <span 
                    className={
                      totalUseAmount < 1000000
                        ? 'fs-72 line-height-72'
                        : 'fs-48 line-height-48'
                    }
                  >
                    {totalUseAmount.toLocaleString()}
                  </span>
                  <span className="fs-20 line-height-20">円</span>
                </>
              ) : (
                <div className="fs-20 fw-700">
                  {menuName}
                </div>
              )}
            </div>
            <Moment
              className="bot"
              interval={1000}
              format="YYYY.MM.DD HH:mm:ss"
              local
            />
          </div>

          <div className="pt-2">
            {this.state.barCodeYoshinoya && (
              <div>
                <Barcode value={this.state.barCodeYoshinoya} options={{ format: 'codabar' }} className="barcode" />
              </div>
            )}
          </div>

          {isShowPrice ? (
            <div className="px-2">
              <div className="mt-2 fs-18 font-weight-bold text-break">
                {menuName}
              </div>

              {!menuLimitTotal && !isEducateSystem && <div className="fs-18 font-weight-medium">
                <span>単価: </span>
                <span className="font-weight-bold">
                  {(isMenuCombo
                    ? displayAmountOneTicket || 0
                    : displayAmount || includedTaxPrice || 0
                  ).toLocaleString()}
                </span>
                <span className="fs-14">円</span>
                <span className="ml-4">個数: </span>
                <span className="font-weight-bold">{this.state.gochiUsedNo}</span>
              </div>}
            </div>
          ) : (
            !menuLimitTotal && !isEducateSystem && this.state.gochiUsedNo > 1 && (
              <div className="mt-2 px-2">
                <div className="fs-18 font-weight-medium">
                  <span>個数: </span>
                  <span className="font-weight-bold">{this.state.gochiUsedNo}</span>
                </div>
              </div>
          ))}

          {sushiroQrImage && <div>
            <img className="sushiro-qr-image mt-2 mb-2" alt="Sushiro QR Code" src={sushiroQrImage} />
            <div className="text-center clr-F44A4A font-weight-bold">
              スシロークーポンQRコード
              <br />
              を店頭にておみせ下さい。
            </div>
          </div>}

          {!isEducateSystem && (
            <div className="content-mypage">
              <div className="mt-4">
                {this.state.sentMessageToShop ? (
                  <>
                    <div className="fs-13 text-gray font-weight-bold">
                      {t(
                        "pages.gochiuse.I sent a support message review to the shop"
                      )}
                    </div>
                    <div className="message-thank-you-to-giver space-pre-line mt-1 mb-4 break-word">
                      <span className="fs-35 font-weight-bold">
                        MESSAGE THANK YOU
                      </span>
                      <br />
                      {this.state.sentMessage}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="fs-13 text-gray font-weight-bold">
                      {t(
                        "pages.gochiuse.Send a support message review to the store"
                      )}
                    </div>
                    <textarea
                      className={
                        this.state.messageTextError
                          ? "form-control mt-1 textarea-message ex-form-required h-120 fs-14"
                          : "form-control mt-1 textarea-message h-120 fs-14"
                      }
                      placeholder={t("pages.invite.messageShopPlaceholder")}
                      value={this.state.messageText ? this.state.messageText : ""}
                      onChange={this.handleChange}
                      name="messageToShop"
                    />
                    {this.state.messageTextError && (
                      <div className="text-danger fs-14 mb-2 fs-12">
                        {this.state.messageTextError}
                      </div>
                    )}

                    <button
                      type="button"
                      className="btn btn-green-38 mt-2"
                      onClick={this.handleOpenConfirmPopup}
                      disabled={this.state.disabledBtnSendMessage}
                    >
                      {t("pages.gochiuse.Send a message review")}
                    </button>
                  </>
                )}

                <div className="line-horizontal break-ln mt-3 mb-3"></div>

                {((gochiCount > 0 && !shopId) ||
                  (menuLimitTotal && remainAmount > 0) ||
                  (this.state.gochiDetail?.menu?.menuChildType === 5)) && (
                  <button
                    type="button"
                    className="btn btn-green-38"
                    onClick={this.handleGoToTicketDetail}
                  >
                    {isMenuCombo
                      ? t("pages.gochiuse.Display coupon tickets")
                      : t("pages.gochiuse.View ticket")}
                  </button>
                )}

                <div className="fs-13 font-weight-bold text-gray mt-3">
                  {t("pages.gochiuse.Gochi use note")}
                </div>

                {/* {this.state.gochiTipUrl && (
                  <div className="gochi-tip mt-3">
                    <div className="d-flex flex-direction-row-reverse">
                      <div className="mr-2">
                        <img
                          src="/assets/img/icon/external-link-black-icon.svg"
                          loading="lazy"
                          alt=""
                        />
                        <a
                          className="fs-11 font-weight-bold clr-000000 link ml-1"
                          href="https://gochi-tip.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("pages.gochiuse.What is gochi-tip")}
                        </a>
                      </div>
                    </div>
                    <a
                      href={this.state.gochiTipUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="border-bt new-btn btn-white-orange align-center mt-1"
                    >
                      <div className="btn-gochi-tip">
                        <img src="/assets/img/icon/gochi-tip-heart.svg" alt="" />
                        <div className="fs-14 clr-000000 ml-2">
                          {t("pages.gochiuse.Give a tip to shop with gochi-tip")}
                        </div>
                      </div>
                    </a>
                  </div>
                )} */}

                <button
                  type="button"
                  className="btn btn-outline-green-38 mt-3 mb-2"
                  onClick={this.openGiftSite}
                >
                  {t("pages.gochiuse.Go to the feast website")}
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="gochi-stamp-combo mt-4">
          <div className="gochi-stamp-combo-info border-radius-0">
            {isEducateSystem ? (
              this.getFooterSystem(this.state.gochiDetail)
            ) : (
              <div>
                <div className="clr-000000 fs-18 font-weight-bold">領収書番号 : {this.state.gochiDetail.gochiBillNo}</div>
                <div><span className="fs-12 clr-000000">チケット利用問合先 : </span><a href="tel:050-5536-3317" className="fs-12 clr-F44A4A text-decoration-underline">050-5536-3317</a></div>
                <div className="fs-12 text-red">※サポートセンターへの問い合わせ電話です。ご予約は、直接店舗様にお願いいたします。</div>
                <div className="fs-12 clr-000000">平日（月～金） 10:00～18:00／土曜日 10:00～15:00</div>
                <div className="fs-12 clr-000000">※お休み：日曜日・祝日 ・長期休暇・お盆休み・年末年始</div>
              </div>
            )}
          </div>
        </div>

        <Modal
          size="sm"
          show={this.state.showConfirmMessagePopup}
          onHide={this.onCloseConfirmMessagePopup}
          animation={false}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
        >
          <Modal.Header className="header-close" closeButton />
          <Modal.Body className="gochi-content">
            <div className="dish-infor">
              <div className="gochi-infor-shop mb-2 mt-1">
                {t("pages.gochiuse.Message confirmation")}
              </div>
              <div className="fs-13 align-center">
                {t("pages.gochiuse.The following message will be sent")}
              </div>
              <div className="line-horizontal break-ln mt-1 mb-2"></div>
              <div className="space-pre-line break-word">
                {this.state.messageText}
              </div>
              <div className="line-horizontal break-ln mt-2 mb-3"></div>
              <button
                type="button"
                className="btn btn-green-38 mt-2"
                onClick={this.handleSendReview}
              >
                {t("pages.gochiuse.Send a message review")}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          size="sm"
          show={this.state.showPaperTicketAlert}
          onHide={this.onClosePaperTicketAlert}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static">
          <Modal.Header className="header-close" closeButton />
          <Modal.Body className="gochi-content">
          <div className="dish-infor">
              <div className="gochi-infor-shop mb-2 mt-1">
                {t("pages.gochiuse.paper ticket message")}
              </div>
              <div className="gochi-infor-shop mb-2 mt-1">
                {`ごちコード ${this.state.gochiDetail.gochiBillNo}`}
              </div>
              <div className="line-horizontal break-ln mt-2 mb-3"></div>
              <button
                type="button"
                className="btn btn-green-38 mt-2"
                onClick={this.onClosePaperTicketAlert}
              >
                確認
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <PopupConfirm
          showing={this.state.popupShowing}
          title=""
          content={this.props.t(
            "pages.invite.please try reloading with the retry button"
          )}
          handleConfirm={this.handlePopupConfirm}
          handleClose={this.handlePopupClose}
          confirmation={this.props.t("pages.invite.retry")}
          cancelation={this.props.t("pages.invite.cancel")}
        />

        <Modal
          size="sm"
          show={this.state.closeTab}
          onHide={this.onClosePaperTicketAlert}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered>
          <Modal.Header className="header-close" closeButton  onHide={hidePopup}/>
          <Modal.Body className="gochi-content">
            {isEducateSystem && (
              <div className="gochi-infor-shop fukuoka-title-popup-finish-use">
                クーポンの利用を完了しました。
              </div>
            )}
            <div className="dish-infor">
              <div className="gochi-infor-shop mb-2 mt-1">
                {isEducateSystem ? "ボタンを押すと、管理システムの画面に戻ります。" : t("pages.gochiuse.close tab message")}
              </div>
              <div className="line-horizontal break-ln mt-2 mb-3"></div>
              <button
                type="button"
                className="btn btn-green-38 mt-2"
                onClick={closeTab}
              >
                {isEducateSystem ? "管理画面に戻る" : t("pages.gochiuse.button close tab")}
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {(this.state.waitingComplete || this.state.gettingGochi) &&
          !this.props.loading && (
            <div className="loading-overlay">
              <FontAwesomeIcon icon={faSpinner} className="" />
            </div>
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  showLoading: (showing) => dispatch(showLoading(showing)),
  showError: (showing, title, content) =>
    dispatch(showError(showing, title, content)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(GochiUseFinish))
);
