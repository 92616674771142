import React, { useState } from "react";
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom";
import PaymentService from "../../../../api/services/PaymentService";
import moment from 'moment';
// import { setMetaDescriptionAndKeywords } from "../../../../utils";
// import { useScript, useStyleSheet } from "../../../../utils";

var stripe = require('stripe-client')(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export function PaymentInformations(props) {

  var today = new Date();
  const startOfCurrentMonth = moment().startOf('month');
  

  let curMonth = today.getMonth() + 1;
  let curYear = today.getFullYear(); 

  let months = [];
  for(var m = 1; m < 13; m ++) { 
    months.push(<option key={m} value={m} >{("0" + m).slice(-2)}</option>);
  }

  let years = [];
  for(var y = curYear; y < curYear + 10; y ++) { 
    years.push(<option key={y} value={y} >{y}</option>);
  }

  const [openAddCardForm, setOpenAddCardForm] = useState(false);
  const [isEditForm, setIsEditForm] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [cardHolder, setCardHolder] = useState("");
  const [cvc, setCvc] = useState("");
  const [expiredMonth, setExpiredMonth] = useState(curMonth);
  const [expiredYear, setExpiredYear] = useState(curYear);
  const [defaultCard, setDefaultCard] = useState(true);
  const [editValue, setEditValue] = useState(null);
  const [paymentError, setPaymentError] = useState("");

  function handleOpenAddForm() {
    setOpenAddCardForm(true);
    setIsEditForm(false);
    setDefaultCard(true);
  }

  function handleOpenEditForm(value) {
    console.log("input edit == ", value);
    setOpenAddCardForm(true)
    setIsEditForm(true);
    setEditValue(value);
    setPaymentError("");
    setDefaultCard(value.defaultFlag);
  }

  function handleChangeDefaultCard(e) {
    if (e.target.checked) {
      setDefaultCard(true);
    } else {
      setDefaultCard(false);
    }
  }

  async function handleSubmitChangeDefaultCard() {
    PaymentService.setDefaultCard(props.userId, editValue.token).then((response) => {
      if (response.status === 200) {
        window.location.href = "/mypage/payment_informations";
      } else {
        setPaymentError("時間をおいてお試しください");
      }
    });
  }

  function handleCancel() {
    setOpenAddCardForm(false);
    setIsEditForm(false);
  }

  async function handleDeleteCard() {
    PaymentService.deleteCard(props.userId, editValue.token).then((response) => {
      if (response.status === 200) {
        window.location.href = "/mypage/payment_informations";
      } else if (response.data?.veritrans_error_code === 'XH20000000000000') {
        setPaymentError('継続課金で利用されているカードであるため、削除できません。');
      } else {
        setPaymentError("時間をおいてお試しください");
      }
    });
  }

  async function handleAddCard() {
    let messageErr = "登録に失敗しました。\nカード情報をご確認ください。または、別のカードでお試しください。";
    try {
      var token = null;
      if (process.env.REACT_APP_VERITRANS_ENABLE === 'true') {
        // Get token from Veritrans
        const response = await fetch('https://api.veritrans.co.jp/4gtoken', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify({
            token_api_key: process.env.REACT_APP_VERITRANS_TOKEN_API_KEY,
            card_number: cardNumber,
            card_expire: String(expiredMonth).padStart(2, '0') + '/' + String(expiredYear).substring(2),
            security_code: cvc,
            cardholder_name: cardHolder,
            lang: 'en'
          })
        });

        if (response.status !== 200) {
          return setPaymentError(messageErr);
        }

        const data = await response.json();

        token = data.token;
      } else {
        // Get token from Stripe
        var cardElement = {
          card: {
            name: cardHolder,
            number: cardNumber,
            exp_month: expiredMonth,
            exp_year: expiredYear,
            cvc: cvc
          }
        };
        const response = await stripe.createToken(cardElement).catch(async () => {
          return setPaymentError(messageErr);
        });

        var data = await response.text();
        data = JSON.parse(data);
        
        if (data.error) {
          return setPaymentError(messageErr);
        }

        if (data.id) {
          token = data.id;
        }
      }

      var userId = null;

      if (props.userId) {
        userId = props.userId;
      }
      if (token && userId) {
        const result = await PaymentService.registerCardtoken(
          userId,
          token
        );

        console.log(result);
        if (result.status === 200) {
          setPaymentError("");
          window.location.href = '/mypage/payment_informations';
        } else {
          setPaymentError('カード情報の登録に失敗しました。');
        }
      } else {
        setPaymentError("ユーザー情報が取得できませんでした。");
      }

    } catch (error) {
      console.log(error);
      setPaymentError('カード情報の登録に失敗しました。');
    }
  }

  return (
    <>
      <MetaTags>
        <title>支払い情報｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス</title>
        <meta name="keywords" content="ごちめし,ギフト,プレゼント,オンラインギフト,ソーシャルギフト,eギフト,デジタルギフト" />
        <meta name="description" content="支払い情報ページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:card swiper-slide" content="summary_large_image" />
        <meta name="twitter:title" content="支払い情報｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta name="twitter:description" content="支払い情報ページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
        <meta name="twitter:image" content="/assets/img/common/ogp.png" />
        <meta property="og:site_name" content="支払い情報｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:title" content="支払い情報｜マイページ｜「ごちめし」飲食店のメニューをお食事券でプレゼントできるサービス" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content="/assets/img/common/ogp.png" />
        <meta property="og:description" content="支払い情報ページです 。「ごちめし」は飲食店のメニューをお食事券でプレゼント・ギフトできるサービスです。誕生日や記念日など感謝や労いのタイミングに、メール・LINE・携帯電話のメッセージ機能ですぐにプレゼントを贈ることができます。" />
      </MetaTags>

      <hr className="mt-30" />
      <section className={`section ${props.loadingPaymentInfo ? 'loading': ''}`}>
        <div className="section-header">
          <div className="heading-md">
            <he className="title">登録済みのクレジットカード</he>
          </div>
        </div>
        <div className="section-body">
          {props.paymentList.length > 0 && props.paymentList.map((item, key) => {
            let index = `card-${key}`;
            var cardExpireDate = moment(`${item.expireMonth}-${item.expireYear}`, "MM-YYYY").endOf('month');
            var isNotExpired = cardExpireDate.isAfter(startOfCurrentMonth) ? true : false;
            return (
              <section className="profile-creditcard" key={index}>
                <div className="profile-creditcard-aside">
                  {item.defaultFlag && (
                    <h3 className="badge">メインカード</h3>
                  )}
                  <span
                    className="link btn-open-modal-green"
                    onClick={() => {
                      handleOpenEditForm(item);
                    }}
                  >
                    編集する
                  </span>
                </div>
                <div className="profile-creditcard-body">
                  <div className="fieldset fieldset-confirm">
                    <div className="fieldset-header">
                      <div className="title">カード番号</div>
                    </div>
                    <div className="fieldset-body">
                      <div className="field">
                        <span className="text-black">
                          {item.brand} <br className="under-tab" /> xxxx
                          xxxx xxxx {item.last4}
                        </span>
                      </div>
                      <div className="field text-black">
                        <span className="text-black" />
                      </div>
                    </div>
                  </div>
                  <div className="fieldset fieldset-confirm">
                    <div className="fieldset-header">
                      <div className="title">名義</div>
                    </div>
                    <div className="fieldset-body">
                      <div className="field">
                        <span className="text-black">
                          {item.holder}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="fieldset fieldset-confirm">
                    <div className="fieldset-header">
                      <div className="title">有効期限</div>
                    </div>
                    <div className="fieldset-body">
                      <div className="field">
                        <span className="text-black">
                          {item.expireMonth < 10 ? `0${item.expireMonth}` : item.expireMonth}/{item.expireYear}
                        </span>
                      </div>
                      {isNotExpired === false && (
                        <div className="invalid-feedback">
                          有効期限が切れています
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            );
          })}
          
          {openAddCardForm && (
            <div id="creditInput" className="credit-input-group">
              <section className="box box-lg">
                <div className="box-header">
                  <div className="heading-md">
                    <div className="title">
                      クレジットカード情報
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="fieldset">
                    <div className="fieldset-header">
                      <div className="title">カード番号</div>
                    </div>
                    <div className="fieldset-body">
                      <div className="field field-lg">
                        <input
                          type="text"
                          placeholder="xxxx xxxx xxxx 1234"
                          disabled={isEditForm}
                          value={isEditForm ? `xxxx xxxx xxxx ${editValue.last4}` : cardNumber}
                          onChange={(e) => {
                            setCardNumber(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.fieldset */}
                  <div className="fieldset">
                    <div className="fieldset-header">
                      <div className="title">カード名義</div>
                    </div>
                    <div className="fieldset-body">
                      <div className="field field-lg">
                        <input
                          type="text"
                          placeholder="GOCHI MESHIKO"
                          disabled={isEditForm}
                          value={isEditForm ? editValue.holder : cardHolder}
                          onChange={(e) => {
                            setCardHolder(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.fieldset */}
                  <div className="fieldset">
                    <div className="fieldset-header">
                      <div className="title">有効期限</div>
                    </div>
                    <div className="fieldset-body">
                      <div className="field field-date">
                        <div className="field field-month">
                          <select
                            disabled={isEditForm}
                            value={isEditForm ? editValue.expireMonth : expiredMonth}
                            onChange={(e) => {
                              setExpiredMonth(e.target.value);
                            }}
                          >
                            {months}
                          </select>
                        </div>
                        <div className="unit">/</div>
                        <div className="field field-date">
                          <select
                            disabled={isEditForm}
                            value={isEditForm ? editValue.expireYear : expiredYear}
                            onChange={(e) => {
                              setExpiredYear(e.target.value);
                            }}
                          >
                            {years}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isEditForm === false && (
                    <div className="fieldset">
                      <div className="fieldset-header">
                        <div className="title">
                          セキュリティコード
                        </div>
                      </div>
                      <div className="fieldset-body">
                        <div className="field field-xs">
                          <input
                            type="text"
                            maxLength={4}
                            placeholder={123}
                            disabled={isEditForm}
                            value={cvc}
                            onChange={(e) => {
                              setCvc(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {paymentError !== '' && (
                    <div className="invalid-feedback">
                      {paymentError}
                    </div>
                  )}
                  {/* <div className="invalid-feedback">
                    カード番号が有効ではありません
                  </div>
                  <div className="invalid-feedback">
                    名義は半角英数で入力してください
                  </div>
                  <div className="invalid-feedback">
                    有効期限に誤りがあります
                  </div>
                  <div className="invalid-feedback">
                    時間をおいてお試しください
                  </div>
                  <div className="invalid-feedback">
                    すでに登録されている情報です
                  </div>
                  <div className="invalid-feedback">
                    カード登録上限数は3つです
                  </div> */}
                  <hr />
                  {isEditForm && (
                    <div className="fieldset">
                      <div className="fieldset-body">
                        <div className="field field-checkbox">
                          <label>
                            <input
                              className="before-icon"
                              name="bbbb"
                              type="checkbox"
                              checked={defaultCard}
                              onChange={(e) => {
                                if (editValue.defaultFlag === false) {
                                  handleChangeDefaultCard(e);
                                }
                              }}
                            />
                            <i className="icon" />
                            このカードをメインカードに設定する
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="box-footer">
                  <div className="btn-group btn-group-form">
                    <button
                      className="btn btn-rect btn-form-cancel"
                      type="button"
                      onClick={() => {
                        handleCancel();
                      }}
                    >
                      キャンセル
                    </button>
                    {isEditForm && (
                      <button
                        className="btn btn-rect btn-form-delete"
                        type="button"
                        onClick={() => {
                          handleDeleteCard();
                        }}
                      >
                        削除する
                      </button>
                    )}
                    <button
                      className="btn btn-rect btn-form-submit"
                      type="button"
                      disabled={!defaultCard || (isEditForm && editValue.defaultFlag)}
                      onClick={() => {
                        if (isEditForm) {
                          handleSubmitChangeDefaultCard();
                        } else {
                          handleAddCard();
                        }
                      }}
                    >
                      保存する
                    </button>
                  </div>
                </div>
              </section>
            </div>
          )}
          
          {props.paymentList.length < 3 && (
            <button
              className="btn btn-box"
              onClick={() => {
                handleOpenAddForm();
              }}
            >
              <div className="icon-area">
                <svg className="icon">
                  <use xlinkHref="/assets/img/common/icon.svg#plus" />
                </svg>
              </div>
              <div className="btn-body">新しい支払い方法を追加</div>
            </button>
          )}
          
        </div>
      </section>
    </>
  );
}
