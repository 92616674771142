/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getFooterBannersSuccess } from "../../../actions/action_footer_banners";
import GiftService from "../../../api/services/GiftService";
import { Swiper, SwiperSlide } from "swiper/react";
import { swiperConfigs } from "../common/SwiperConfigs";
import { SliderPagination } from "../common/SliderPagination";
import { useWindowSize } from "../../../utils";

export function Footer(props) {
  const listFooterBanners = useSelector((state) => state.footerBanners);
  const dispatch = useDispatch();
  const history = useHistory();

  const urlPath = history.location.pathname;
  const isMypage = (urlPath.match(new RegExp('mypage', 'g')) && urlPath.match(new RegExp('mypage', 'g'))[0] === 'mypage') || (urlPath.match(new RegExp('mypage/*', 'g')) && urlPath.match(new RegExp('mypage/*', 'g'))[0] === 'mypage/');

  useEffect(() => {
    if (listFooterBanners.length === 0) {
      GiftService.getFooterBanners().then((response) => {
        dispatch(getFooterBannersSuccess(response.data.footer_banners));
      });
    }
  }, []);

  const [width] = useWindowSize();

  const showSwiper = width <= 480;

  return (
    <footer className={isMypage ? "footer-mypage global-footer" : "global-footer"}>
      <section className="global-footer-section global-footer-share-area">
        <div className="container">
          <div className="card-group card-group-sns">
            <a
              href="https://twitter.com/gochi_meshi"
              className="card card-sns bg-twitter"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="card-thumbnail">
                <img
                  src="/assets/img/common/icon-twitter.svg"
                  className="img twitter"
                  loading="lazy"
                  alt=""
                />
              </div>
              <div className="card-header">
                <h3 className="subtitle">公式アカウント</h3>
                <div className="title">Twitter</div>
              </div>
            </a>
            <a
              href="https://www.facebook.com/gochimeshi.official/"
              className="card card-sns bg-facebook"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="card-thumbnail">
                <img
                  src="/assets/img/common/icon-facebook.png"
                  className="img facebook"
                  loading="lazy"
                  alt=""
                />
              </div>
              <div className="card-header">
                <h3 className="subtitle">公式アカウント</h3>
                <div className="title">Facebook</div>
              </div>
            </a>
            <a
              href="https://www.instagram.com/gochimeshi_staff/?hl=ja"
              className="card card-sns bg-instagram"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="card-thumbnail">
                <img
                  src="/assets/img/common/icon-instagram.png"
                  className="img instaglam"
                  loading="lazy"
                  alt=""
                />
              </div>
              <div className="card-header">
                <h3 className="subtitle">公式アカウント</h3>
                <div className="title">Instagram</div>
              </div>
            </a>
          </div>
          <div className="footer-sakimeshi-area">
            {showSwiper ? (
              <Swiper className="slider-wide" {...swiperConfigs.footer}>
                <div className="swiper-wrapper">
                  {listFooterBanners.map((item, index) => (
                    <SwiperSlide
                      tag="a"
                      key={index}
                      href={item.link_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="swiper-slide card-image"
                    >
                      <img
                        src={item.image_url}
                        className="img"
                        loading="lazy"
                        alt=""
                      />
                    </SwiperSlide>
                  ))}
                </div>
                <SliderPagination className="footer-pagination" />
              </Swiper>
            ) : (
              <div className="swiper-container slider-wide" id="footerSlider">
                <div className="swiper-wrapper">
                  {listFooterBanners.map((item, index) => (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      key={index}
                      href={item.link_url}
                      className="swiper-slide card-image"
                    >
                      <img
                        src={item.image_url}
                        className="img"
                        loading="lazy"
                        alt=""
                      />
                    </a>
                  ))}
                </div>
                {/* <div className="slider-pagination-area under-tab">
                <div className="slider-btn-group">
                  <button className="slider-btn slider-btn-prev">
                    <svg className="icon">
                      <use xlinkHref="/assets/img/common/icon.svg#angle-left" />
                    </svg>
                  </button>
                  <button className="slider-btn slider-btn-next">
                    <svg className="icon">
                      <use xlinkHref="/assets/img/common/icon.svg#angle-right" />
                    </svg>
                  </button>
                </div>
                <div className="swiper-pagination" />
              </div> */}
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="global-footer-nav-area">
        <div className="container">
          <div className="section-header">
            <div className="heading-xs">
              {!isMypage ? (
              <>
                <div className="subtitle">
                  気持ちを「ごちそう」にして贈れるアプリ
                </div>
                <div className="title">
                  <h1>
                    <img
                      src="/assets/img/common/icon-footer-app-logo.svg"
                      alt="ごちめし"
                      loading="lazy"
                    />
                  </h1>
                </div>
              </>
              ) : (
                <div className="title">
                  <h1>
                    <img
                      src="/assets/img/common/logo_gochi_footer.svg"
                      alt="ごちめし"
                      loading="lazy"
                    />
                  </h1>
                </div>
              )}
            </div>
          </div>
          <div className="section-body footer-nav">
            <ul className="footer-link-area">
              <li>
                <a href="https://gochimeshi.com/policy/">各種規約</a>
              </li>
              <li>
                <a href="https://gochimeshi.com/policy/privacy/">
                  プライバシーポリシー
                </a>
              </li>
              <li>
                <a href="https://gochimeshi.com/policy/legal/">
                  特定商取引法に基づく表記
                </a>
              </li>
              <li>
                <a href="https://user-guide.gochimeshi.com/">よくある質問</a>
              </li>
              <li>
                <a href="/sitemap">サイトマップ</a>
              </li>
              <li>
                <a href="https://gochimeshi.com/info/">お問い合わせ</a>
              </li>
              <li>
                <a href="https://www.gigi.tokyo/">運営会社（Gigi株式会社）</a>
              </li>
            </ul>
            {!isMypage && (
            <ul className="footer-share-area">
              <li>
                <a href="https://twitter.com/gochi_meshi">
                  <img
                    src="/assets/img/common/icon-twitter-white.svg"
                    loading="lazy"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/gochimeshi.official">
                  <img
                    src="/assets/img/common/icon-facebook-white.svg"
                    loading="lazy"
                    alt=""
                  />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/gochimeshi_staff/?hl=ja">
                  <img
                    src="/assets/img/common/icon-instagram-white.svg"
                    loading="lazy"
                    alt=""
                  />
                </a>
              </li>
            </ul>
            )}
          </div>
        </div>
      </section>
      <div className="copyright">
        <small>
          ごちめし®︎ さきめし®︎ びずめし®︎ GOCHI®︎は、Gigi株式会社の登録商標です 
          <br />
          © Gigi Inc.
        </small>
      </div>
    </footer>
  );
}
